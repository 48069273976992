import React, { Component, createElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const renderComponent = ({ render, component, validateLoginResult }) => props => {
  const {
    result,
    error
  } = validateLoginResult || {};
  if (error) return <Redirect to="/login" />
  if (result) return render ? render(props) : createElement(component, props);
  return <div></div>;
}

class SecureRoute extends Component {
  render() {
    const { component, render, validateLoginResult, permissions, isAdminRoute, user, ...rest } = this.props;
    const {
      roleId
    } = user || {};
    const isAdmin = roleId === 'administrator';

    if(isAdminRoute && !isAdmin){
      return <Redirect to="/" />
    } 
    return <Route
      {...rest}
      render={renderComponent({ render, component, validateLoginResult })}
    />
  }
}

const mapState = state => {
  const user = state.login.toJS().result;
  const validateLoginResult = state.validateLogin.toJS()
  return { 
    user, 
    validateLoginResult 
  };
}

export default connect(mapState)(SecureRoute);