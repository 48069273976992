import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../../ui/Layout';

import Icon from '../../../ui/Icon';
import FormNewBuilder from './FormNewBuilder';

export const BuildersNew = ({ postBuilder, history }) => {
  const handleSubmit = values => postBuilder(values, 'new-builder', null, data => {
    const { insertedId } = data || {};
    history.push(`/admin/builders/${insertedId}`);
  })
  return (
    <Layout route="builders-new">

      <div className="layout-header">
        <div>
          <h1>Create New Builder</h1>
          <Link to="/admin/builders">
            <Icon name="angle-left" /> Back
          </Link>
        </div>
      </div>

      <Row>
        <Col md={6} xl={5}>
          <FormNewBuilder onSubmit={handleSubmit} />
        </Col>
      </Row>

    </Layout>
  );
};

const mapState = state => {
  const {
    result,
    error
  } = state.postBuilder.toJS();
  return {
    result,
    error
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postBuilder: fetchDux.postBuilder.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(BuildersNew);
