import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import { Route } from 'react-router';

import SideNav from './SideNav';
import TopNav from './TopNav';

const Layout = ({ children, route, user }) => {
  return (
    <div className={classNames(`route-${route}`)}>
      <Route component={TopNav} />
      <Container fluid className="layout">
        <Row>
          <Col md={3} xl={2} className="side-nav">
            <Route>
              <SideNav />
            </Route>
            {/* <Route component={SideNav} showCreateUserButton /> */}
          </Col>
          <Col sm={12} md={9} xl={10}>
            <main>
              {children}
            </main>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapState = state => ({ user: state.login.toJS().result });
export default connect(mapState)(Layout);
