import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import UsersSearch from './UsersSearch.js';
import UsersNew from './UsersNew.js';
import UsersDetail from './UsersDetail.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={UsersSearch} />
    <Route exact path={`${match.url}/new`} component={UsersNew} />
    <Route exact path={`${match.url}/:id`} component={UsersDetail} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
