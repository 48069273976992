import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const FormEditMaterialPickup = props => {
  const {
    handleSubmit,
    submitting,
    lookups
  } = props;
  const {
    materialPickupTechnicians
  } = lookups || {};

  const materialPickupTechnicianOptions = (materialPickupTechnicians || [])
    .map(({ _id, firstName, lastName }) => ({
      value: _id,
      label: `${firstName} ${lastName}`
    }));

  return (
    <div className="box box-form">
      <form onSubmit={handleSubmit}>

        <Row>
          <Col md={12} lg={6}>
            <Field
              name="projectAddress"
              type="text"
              component={FormField.Input}
              label="Pickup Address"
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="technicianId"
              type="text"
              component={FormField.SelectReact}
              label="Material Pickup Technician"
              validate={[required]}
              options={materialPickupTechnicianOptions || []}
            />

          </Col>
        </Row>

        <Row>
          <Col md={6} lg={3}>

            <Field
              name="scheduledDate"
              type="text"
              component={FormField.Datepicker}
              label="Pickup Date"
              validate={[required]}
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="mustPickupDate"
              type="text"
              component={FormField.Datepicker}
              label="Must Pickup By"
              validate={[required]}
            />

          </Col>
          <Col md={6} lg={3}>

            <div className="form-group-spacing">
              <Field
                name="allowEarlyPickup"
                type="checkbox"
                component={FormField.InputToggle}
                label="Allow early pickup"
              />
            </div>

          </Col>
        </Row>

        <Row>
          <Col md={6} lg={3}>

            <Field
              name="roughDate"
              type="text"
              component={FormField.Datepicker}
              label="Rough Completed Date"
              validate={[required]}
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="roughCompletedBy"
              type="text"
              component={FormField.Input}
              label="Rough Completed By"
              validate={[required]}
            />

          </Col>
        </Row>

        <Row>
          <Col md={6} lg={3}>

            <Field
              name="projectName"
              type="text"
              component={FormField.Input}
              label="Project"
              validate={[required]}
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="builderName"
              type="text"
              component={FormField.Input}
              label="Builder"
              validate={[required]}
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="fieldSupervisorName"
              type="text"
              component={FormField.Input}
              label="Field Supervisor"
              validate={[required]}
              disabled
            />

          </Col>
        </Row>

        <Field
          name="comments"
          type="text"
          component={FormField.Textarea}
          label="Comments"
          rows={5}
        />

        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Save Changes
          </Button>
        </div>

      </form>
    </div>
  );
};

export default reduxForm({
  form: 'edit-material-pickup'
})(FormEditMaterialPickup);
