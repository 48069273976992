import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AddressesSearch from './AddressesSearch.js';
import AddressNew from './AddressNew.js';
import AddressesImport from './AddressesImport.js';
import AddressesImportDetail from './AddressesImportDetail';
import AddressDetail from './AddressDetail.js';
import AddressEdit from './AddressEdit.js';
import AddressLogs from './AddressLogs';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={AddressesSearch} />
    <Route exact path={`${match.url}/new`} component={AddressNew} />
    <Route exact path={`${match.url}/import`} component={AddressesImport} />
    <Route
      exact
      path={`${match.url}/import/:id`}
      component={AddressesImportDetail}
    />
    <Route exact path={`${match.url}/:id`} component={AddressDetail} />
    <Route exact path={`${match.url}/:id/edit`} component={AddressEdit} />
    <Route exact path={`${match.url}/:id/Logs`} component={AddressLogs} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
