import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const required = value => value ? undefined : 'Required';

const FormEditUser = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>

      <Field
        name="name"
        type="text"
        component={FormField.Input}
        label="User Group Name"
        validate={[required]}
      />

    </form>
  );
};

export default reduxForm({
  form: 'edit-user-group'
})(FormEditUser);
