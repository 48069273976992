import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Modal, Button } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const PAUSE_REASONS_OPTIONS = [
  {
    label: 'Stolen Parts',
    value: 'stolenParts'
  },

  {
    label: 'Builder',
    value: 'builder'
  },
  {
    label: 'Field Supervisor',
    value: 'fieldSupervisor'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

const FormPauseInspection = props => {
  const {
    handleSubmit,
    address,
    city,
    state,
    zip,
    closeModal,
    submitting
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <p>Are you sure you wish to pause inspection at the address below?</p>
        <label className="form-label">
          Address
        </label>
        <p>
          {address || ''},<br />
          {city || ''} {zip || ''}, {state || ''}
        </p>

        <Field
          name="pauseReason"
          type="text"
          component={FormField.SelectReact}
          label="Reason"
          validate={[required]}
          options={PAUSE_REASONS_OPTIONS}
        />

        <Field
          name="comments"
          type="text"
          component={FormField.Textarea}
          label="Comments"
          rows={5}
        />

        <Modal.Footer>
          <Button variant="danger" type="submit" disabled={submitting}>
            Pause Inspection
          </Button>
          <Button
            variant="outline-secondary"
            onClick={closeModal}
            disabled={submitting}
          >
            Cancel
          </Button>
        </Modal.Footer>

      </Modal.Body>
    </form>
  );
};

export default reduxForm({
  form: 'pause-inspection'
})(FormPauseInspection);
