import React, { Component } from 'react';
import ReactTable from 'react-table';
import {
  Form,
  DropdownButton,
  Dropdown,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import Icon from './Icon';
import TablePagination from './TablePagination';
import 'react-table/react-table.css';

const filterData = ({ columns, search, data }) => {
  const s = (search || '').toLowerCase().replace(/\s/g, '');
  const keys = columns.map(({ accessor }) => accessor);
  return (data || []).filter(d => {
    const str = keys
      .reduce((prev, key) => `${prev}${d[key] || ''}`, '')
      .toLowerCase()
      .replace(/\s/g, '');
    return str.indexOf(s) !== -1;
  });
};

const ColumnButton = (
  {
    formattedColumns,
    toggleColumn
  }
) => (
  <DropdownButton
    variant="outline-secondary"
    size="sm"
    id="vendor-columns"
    title="Show/Hide Columns"
    alignRight
    className="checkbox-dropdown"
  >
    {formattedColumns.filter(({ show, Header }) => show && Header).map(({
      Header,
      id,
      accessor
    }, index) => (
      <div
        key={index}
        className="dropdown-item"
        onClick={() =>
          toggleColumn({
            show: false,
            columns: [id || accessor]
          })}
      >
        <Icon fixedWidth name="toggle-on" />
        {Header}
      </div>
    ))}
    {formattedColumns.filter(({ show, Header }) => !show && Header).length >
      0 && <Dropdown.Divider />}
    {formattedColumns.filter(({ show, Header }) => !show && Header).map(({
      Header,
      id,
      accessor
    }, index) => (
      <div
        key={index}
        className="dropdown-item"
        onClick={() =>
          toggleColumn({
            show: true,
            columns: [id || accessor]
          })}
      >
        <Icon fixedWidth name="toggle-off" />
        {Header}
      </div>
    ))}
  </DropdownButton>
);

const ExportButton = (
  {
    onExport,
    onExportAll,
    onExportInProgress
  }
) => (
  <Button
    type="button"
    variant="outline-secondary"
    size="sm"
    onClick={() => onExportAll ? onExportAll() : null}
    disabled={onExportInProgress}
  >
    Export
  </Button>
);

/*
  <DropdownButton
    variant="outline-secondary"
    size="sm"
    id="export-columns"
    title="Export"
    alignRight
    className="checkbox-dropdown"
  >
    <div className="dropdown-item" onClick={() => onExport ? onExport() : null}>
      Export
    </div>
    <div
      className="dropdown-item"
      onClick={() => onExportAll ? onExportAll() : null}
    >
      Export All
    </div>
  </DropdownButton>
*/

class Table extends Component {
  constructor(props) {
    super(props);
    const { columns } = props;
    const activeColumns = (columns || [])
      .filter(({ show }) => show !== false)
      .map(({ id, accessor }) => id || accessor);
    this.state = { activeColumns, searchState: '' };
    this.toggleColumn = this.toggleColumn.bind(this);
  }
  componentWillUnmount() {
    const { onUnmount, columns } = this.props;
    if (!onUnmount) return;

    const { activeColumns } = this.state;

    onUnmount({ columns, activeColumns });
  }
  toggleColumn(
    {
      show,
      columns
    }
  ) {
    const { activeColumns } = this.state;
    if (show === true) {
      const next = columns.filter(c => !activeColumns.some(a => a === c));
      return this.setState({ activeColumns: [...activeColumns, ...next] });
    }

    const next = activeColumns.filter(a => !columns.some(c => c === a));
    this.setState({ activeColumns: next });
  }
  render() {
    const {
      data,
      columns,
      onSearchChange,
      showSearch,
      search,
      showColumnsSelector,
      showExport,
      onExport,
      onExportAll,
      sortBy,
      sortDirection,
      handleServerSearchChange,
      isServerPaging,
      qSearch,
      ...props
    } = this.props;
    const { activeColumns, searchState } = this.state;

    const filteredData = filterData({
      columns,
      search: onSearchChange ? search : searchState,
      data
    });

    const formattedColumns = columns.map(column => {
      const show = activeColumns.some(
        a => a === (column.id || column.accessor)
      );
      return Object.assign({}, column, { show });
    });

    const handleSearchChange = e => {
      const { value } = e.target || {};
      if (onSearchChange) return onSearchChange(value);
      this.setState({ searchState: value });
    };

    const useDate = isServerPaging ? data : filteredData;

    return (
      <div>
        {(showSearch || showColumnsSelector) &&
          <Row className="table-toolbar">
            {showSearch &&
              <Col>
                <div>
                  <Form.Control
                    type="text"
                    size="sm"
                    placeholder="Search..."
                    className="table-search"
                    onChange={
                      isServerPaging
                        ? handleServerSearchChange
                        : handleSearchChange
                    }
                    value={
                      isServerPaging
                        ? qSearch
                        : onSearchChange ? search : searchState
                    }
                  />
                </div>
              </Col>}
            {(showColumnsSelector || showExport) &&
              <Col>
                <div className="text-right">
                  {showColumnsSelector &&
                    <ColumnButton
                      formattedColumns={formattedColumns}
                      toggleColumn={this.toggleColumn}
                    />}
                  {showExport &&
                    (onExport || onExportAll) &&
                    <ExportButton
                      onExport={onExport}
                      onExportAll={onExportAll}
                    />}
                </div>
              </Col>}
          </Row>}
        <ReactTable
          data={useDate || []}
          columns={formattedColumns || []}
          {...props}
          PaginationComponent={TablePagination}
          defaultSorted={
            isServerPaging
              ? [{ id: sortBy, desc: sortDirection === 'desc' }]
              : []
          }
        />
      </div>
    );
  }
}

export default Table;
