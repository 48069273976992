import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Loading from '../../ui/Loading';

import Icon from '../../ui/Icon';
import FormImportAddresses from './FormImportAddresses';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../state/fetch-dux';
import { connect } from 'react-redux';
import Table from '../../ui/Table';
import InspectionStatus from '../quality-assurance/InspectionStatus';
import moment from 'moment';
import Notification from '../../ui/Notification';

const columns = [
  {
    Header: 'Type',
    accessor: 'type'
  },
  {
    Header: 'Work Order No.',
    accessor: 'workOrderNo'
  },
  {
    Header: 'Address',
    accessor: 'address'
  },
  {
    Header: 'City',
    accessor: 'city'
  },
  {
    Header: 'ZIP',
    accessor: 'zip'
  },
  {
    Header: 'State',
    accessor: 'state'
  },
  {
    Header: 'Project Name',
    accessor: 'name',
    Cell: ({ value, original: row }) => (
      <Link to={`/projects/${row._id}`}>{value}</Link>
    )
  },
  {
    Header: 'Builder',
    accessor: 'builder.name'
  },
  {
    Header: 'Field Supervisor',
    accessor: 'fieldSupervisor.lastName',
    Cell: ({ original: row }) => {
      const { fieldSupervisor } = row || {};
      if (!fieldSupervisor) return '';
      const {
        firstName,
        lastName
      } = fieldSupervisor || {};
      return `${firstName} ${lastName}`;
    }
  },
  {
    Header: 'Days Materials on Site',
    accessor: 'MPDays'
  },
  {
    Header: 'Material Pickup',
    accessor: 'materialPickupStatus',
    Cell: ({ value }) => <InspectionStatus status={value} />
  },
  {
    Header: 'Rough Inpsection',
    accessor: 'roughInspectionStatus',
    Cell: ({ value }) => <InspectionStatus status={value} />
  },
  {
    Header: 'Final Inspection',
    accessor: 'finalInspectionStatus',
    Cell: ({ value }) => <InspectionStatus status={value} />
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
  }
];

class AddressesImport extends Component {
  componentDidMount() {
    this.props.clearPostImportProjects();
  }
  render() {
    const {
      postImportProjects,
      history,
      error,
      inProgress
    } = this.props;
    const handleSubmit = payload =>
      postImportProjects({ payload }, 'import-addresses', null, data => {
        const { insertedId } = data || {};
        history.push(`/projects/import/${insertedId}`);
      });
    return (
      <Layout route="addresses-import">
        <div className="layout-header">
          <div>
            <h1>Import New Addresses</h1>
            <Link to="/addresses">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>
        {!inProgress &&
          <Row>
            <Col md={6} xl={5}>
              <FormImportAddresses onSubmit={handleSubmit} />
            </Col>
          </Row>}

        {inProgress &&
          <Loading fullpage message={'Importing Addresses. Please Wait...'} />}
        {error &&
          <Notification
            key="import-projects-error"
            duration={5}
            closable={true}
            type="danger"
          >
            Error importing addresses.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    inProgress,
    result,
    error
  } = state.postImportProjects.toJS();
  return {
    inProgress,
    result,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postImportProjects: fetchDux.postImportProjects.createAction,
      clearPostImportProjects: fetchDux.postImportProjects.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(AddressesImport);
