import React, { Component, useState } from 'react';
import Calendar from './Calendar';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../state/fetch-dux';
import moment from 'moment';
import classNames from 'classnames';
import Icon from './Icon';

import { useHistory } from 'react-router-dom';

const CalendarCard = ({ event, style }) => {
  const {
    project,
    type,
    _id,
    technician
  } = event || {};
  const { firstName, lastName } = technician || {};
  const { name, address, city, state, zip } = project || {};
  const [hover, setHover] = useState(false);
  const history = useHistory();
  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onDoubleClick={() => {
        history.push(`/quality-assurance/inspection/${_id}`);
      }}
      className={classNames(
        'react-calendar-card',
        type === 'roughInspection' ? '' : 'black'
      )}
      style={style || {}}
    >
      <div className="react-calendar-card-body">

        <div style={{ width: '100%', pointerEvents: 'all' }}>
          <span
            style={{
              display: 'flex',
              flex: '1',
              width: '100%',
              whiteSpace: 'nowrap'
            }}
          >
            <div style={{ display: 'flex', width: '100%', overflow: 'hidden' }}>
              <div style={{ flexGrow: '1' }}>
                <span style={{ display: 'flex' }}>
                  <span>
                    <b style={{ display: 'block' }}>
                      {name}
                    </b>
                    <small
                      style={{ display: 'block', opacity: 0.8, lineHeight: 1 }}
                    >
                      {address}
                    </small>
                    <small style={{ display: 'block', opacity: 0.8 }}>
                      {city} {state} {zip}
                    </small>

                    {firstName &&
                      lastName &&
                      <div className="technician">
                        <Icon name="user" prefix="fa" /> {firstName} {lastName}
                      </div>}
                  </span>
                </span>
                {/*
                  <Icon
                    prefix="fal"
                    name={type === 'roughInspection' ? 'hammer' : 'th'}
                  />
                */}
              </div>
            </div>
          </span>
        </div>
      </div>

    </div>
  );
};

const renderEvent = ({ event, style }) => {
  return <CalendarCard event={event} style={style} />;
};

class InspectionsCalendar extends Component {
  render() {
    const {
      result,
      getInspectionEvents,
      hideNav,
      isQuality
    } = this.props;
    const {
      inspections
    } = result || {};

    const handleLoadEvents = values => {
      const { week, year } = values || {};
      const d = moment().year(year).week(week);
      const startDate = d.clone().startOf('week').startOf('day').toDate();
      const endDate = d.clone().endOf('week').endOf('day').toDate();
      getInspectionEvents(
        `startDate=${startDate}&endDate=${endDate}&pageSize=1000&status=pending,draft,denied,failed,criticalFailed,reschedule`
      );
    };
    return (
      <div>
        {!hideNav &&
          <div className="box-nav">
            <ul className="nav nav-pills">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to={isQuality ? '/quality-assurance' : '/material-pickups'}
                >
                  Calendar
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={
                    isQuality
                      ? '/quality-assurance/grid'
                      : '/material-pickups/grid'
                  }
                >
                  Grid
                </Link>
              </li>
            </ul>
          </div>}
        <Calendar
          renderEvent={renderEvent}
          view="week"
          loadEvents={handleLoadEvents}
          events={inspections || []}
          calendarProps={{
            startDateField: 'scheduledDate'
          }}
        />
      </div>
    );
  }
}

const mapState = state => {
  const {
    result
  } = state.getInspectionEvents.toJS();
  return {
    result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getInspectionEvents: fetchDux.getInspectionEvents.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(InspectionsCalendar);
