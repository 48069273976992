import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import { formValueSelector } from 'redux-form';

import FormPauseInspection from './FormPauseInspection';

const ModalPauseInspection = (
  {
    openModal,
    closeModal,
    name,
    handlePause,
    address,
    city,
    state,
    zip
  }
) => {
  return (
    <Modal show={openModal === name}>
      <Modal.Header closeButton>
        <Modal.Title>Pause Inspection</Modal.Title>
      </Modal.Header>
      <FormPauseInspection
        {...{
          address,
          city,
          state,
          zip,
          closeModal
        }}
        onSubmit={values => {
          const {
            pauseReason,
            comments
          } = values;
          handlePause(pauseReason, comments);
        }}
      />
    </Modal>
  );
};

const selector = formValueSelector('pause-inspection');

const mapState = state => {
  const pauseReason = selector(state, 'reason');
  const comments = selector(state, 'comments');
  return {
    openModal: state.modals,
    pauseReason,
    comments
  };
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalPauseInspection);
