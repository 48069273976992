import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const FormNewUserGroup = props => {
  const { handleSubmit, submitting } = props;
  return (
    <div className="box box-form">
      <form onSubmit={handleSubmit}>

        <Field
          name="name"
          type="text"
          component={FormField.Input}
          label="User Group Name"
          validate={[required]}
        />

        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Submit
          </Button>
        </div>

      </form>
    </div>
  );
};

export default reduxForm({
  form: 'new-user-group'
})(FormNewUserGroup);
