import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const FormEditAddress = props => {
  const {
    handleSubmit,
    submitting,
    lookups
  } = props;

  const {
    builders,
    fieldTechnicians
  } = lookups || {};

  const builderOptions = (builders || []).map(({ name, _id }) => ({
    value: _id,
    label: name
  }));

  const fieldTechnicianOptions = (fieldTechnicians || []).map(({
    firstName,
    lastName,
    _id
  }) => ({
    value: _id,
    label: `${firstName} ${lastName}`
  }));

  return (
    <div className="box box-form">
      <form onSubmit={handleSubmit}>

        <Field
          name="workOrderNo"
          type="text"
          component={FormField.Input}
          label="Work Order No."
          validate={[required]}
        />

        <Field
          name="name"
          type="text"
          component={FormField.Input}
          label="Project Name"
          validate={[required]}
        />
        <Field
          name="homePlanNo"
          type="text"
          component={FormField.Input}
          label="Home Plan Number"
          validate={[required]}
        />
        <br />

        <Field
          name="builderId"
          type="text"
          component={FormField.SelectReact}
          label="Builder"
          validate={[required]}
          options={builderOptions}
        />

        <Field
          name="fieldSupervisorId"
          type="text"
          component={FormField.SelectReact}
          label="Field Supervisor"
          validate={[required]}
          options={fieldTechnicianOptions}
        />

        <br />

        <Field
          name="address"
          type="text"
          component={FormField.Input}
          label="Address"
          validate={[required]}
        />

        <Row>
          <Col md={8}>
            <Field
              name="city"
              type="text"
              component={FormField.Input}
              label="City"
              validate={[required]}
            />
          </Col>
          <Col md={4}>
            <Field
              name="zip"
              type="text"
              component={FormField.Input}
              label="ZIP"
              validate={[required]}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={8}>
            <Field
              name="state"
              type="text"
              component={FormField.Input}
              label="State"
              validate={[required]}
            />
          </Col>
        </Row>

        <br />

        <Field
          name="roughInspectionDays"
          type="text"
          component={FormField.Input}
          label="Days from Material Pickup to Rough Inspection"
          validate={[required]}
        />

        <Field
          name="dateMaterialsScheduled"
          type="text"
          component={FormField.Datepicker}
          label="Date Materials Scheduled"
          validate={[required]}
        />

        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Submit
          </Button>
        </div>

      </form>
    </div>
  );
};

export default reduxForm({
  form: 'edit-address'
})(FormEditAddress);
