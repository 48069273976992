import React from 'react';
import { Modal } from 'react-bootstrap';
import FormPickUpCompletionAck from './FormPickUpCompletionAck';

const ModalPickUpCompletion = props => {
    const {
        openModal,
        closeModal,
        handleSubmitWithAck,
        postMaterialPickUpLoading
    } = props;

    return (
        <Modal
            show={openModal === 'ModalPickUpCompletion'}
            onHide={() => {
                if (!postMaterialPickUpLoading) closeModal();
            }}
        >
            <Modal.Header>
                <Modal.Title>Material Pickup Confirmation</Modal.Title>
            </Modal.Header>

            <FormPickUpCompletionAck
                handleSubmitWithAck={handleSubmitWithAck}
                postMaterialPickUpLoading={postMaterialPickUpLoading}
            />
        </Modal>
    );
};

export default ModalPickUpCompletion;
