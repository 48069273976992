import React from 'react';
import Icon from './Icon';

const MaterialPickupStatus = ({ status, hideIcon }) => {
  return (
    <div>
      {status === 'pending' &&
        <div className="status orange">
          {!hideIcon && <Icon name="clock" />} Pending
        </div>}
      {status === 'late' &&
        <div className="status red">
          {!hideIcon && <Icon name="exclamation-triangle" />} Late
        </div>}
      {status === 'completed' &&
        <div className="status green">
          {!hideIcon && <Icon name="check" />} Completed
        </div>}
      {status === 'denied' &&
        <div className="status red">
          {!hideIcon && <Icon name="exclamation-circle" />} Unable To Pickup
        </div>}
    </div>
  );
};

export default MaterialPickupStatus;
