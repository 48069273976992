import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import FormEditUserPassword from './FormEditUserPassword';

const ModalEditUserPassword = ({ openModal, closeModal, name, handleSubmit, submitting }) => (
  <Modal show={openModal === name} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Edit User</Modal.Title>
    </Modal.Header>
    <FormEditUserPassword
      onSubmit={handleSubmit}
      closeModal={closeModal}
      submitting={submitting} 
    />
  </Modal>
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalEditUserPassword);
