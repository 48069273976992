import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Loading from '../../ui/Loading';

import Icon from '../../ui/Icon';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../state/fetch-dux';
import { connect } from 'react-redux';
import Table from '../../ui/Table';
import InspectionStatus from '../quality-assurance/InspectionStatus';
import moment from 'moment';

const columns = [
  {
    Header: 'Action',
    accessor: 'result.type',
    Cell: ({ value }) => {
      if (value === 'create')
        return (
          <div className="status green">
            Create
          </div>
        );
      return (
        <div className="status orange">
          Update
        </div>
      );
    }
  },
  {
    Header: 'Work Order No.',
    accessor: 'project.workOrderNo'
  },
  {
    Header: 'Address',
    accessor: 'project.address'
  },
  {
    Header: 'City',
    accessor: 'project.city'
  },
  {
    Header: 'ZIP',
    accessor: 'project.zip'
  },
  {
    Header: 'State',
    accessor: 'project.state'
  },
  {
    Header: 'Project Name',
    accessor: 'project.name',
    Cell: ({ value, original: row }) => (
      <Link to={`/projects/${row.project._id}`}>{value}</Link>
    )
  },
  {
    Header: 'Builder',
    accessor: 'builderName'
  },
  {
    Header: 'Field Supervisor',
    accessor: 'fieldSupervisorName'
  },
  {
    Header: 'Date Created',
    accessor: 'project.dateCreated',
    Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
  }
];

class AddressesImportDetail extends Component {
  componentDidMount() {
    const {
      getProjectImport,
      match: { params: { id } }
    } = this.props;
    getProjectImport(id);
  }
  render() {
    const {
      postImportProjects,
      inProgress,
      result,
      history
    } = this.props;
    return (
      <Layout route="addresses-import">

        <div className="layout-header">
          <div>
            <h1>Import New Addresses</h1>
            <Link to="/addresses">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <div className="box box-smart-table">
              <Table
                showColumnsSelector
                showSearch
                showExport
                data={result || []}
                columns={columns}
              />
            </div>
          </Col>
        </Row>

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    inProgress,
    result
  } = state.getProjectImport.toJS();
  return {
    inProgress,
    result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProjectImport: fetchDux.getProjectImport.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(AddressesImportDetail);
