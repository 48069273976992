import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';
import { getProjects } from '../../../api/api';
import Camera from '../../ui/Camera';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const handleLoadOptions = async (value, next) => {
  try {
    if (value.length < 3) {
      next([]);
      return;
    }
    const data = await getProjects(`search=${value}`);
    const {
      projects
    } = data || {};
    const options = (projects || []).map(({
      address,
      city,
      state,
      zip,
      _id,
      name,
      builderName,
      fieldSupervisorName
    }) => ({
      value: _id,
      label: `${address} ${city} ${state} ${zip}`,
      name,
      builderName,
      fieldSupervisorName
    }));
    next(options);
  } catch (err) {
    next([]);
  }
};

const FormNewMaterialPickup = props => {
  const {
    handleSubmit,
    submitting,
    change,
    lookups,
    files
  } = props;
  const {
    materialPickupTechnicians
  } = lookups || {};

  const materialPickupTechnicianOptions = (materialPickupTechnicians || [])
    .map(({ _id, firstName, lastName }) => ({
      value: _id,
      label: `${firstName} ${lastName}`
    }));

  return (
    <div className="box box-form">
      <form onSubmit={handleSubmit}>

        <Row>
          <Col md={12} lg={6}>
            <Field
              name="project"
              type="text"
              component={FormField.SelectReactAsync}
              label="Pickup Address"
              validate={[required]}
              loadOptions={handleLoadOptions}
              errorMessage="Required"
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="technicianId"
              type="text"
              component={FormField.SelectReact}
              label="Material Pickup Technician"
              validate={[required]}
              options={materialPickupTechnicianOptions || []}
            />

          </Col>
        </Row>

        <Row>
          <Col md={6} lg={3}>

            <Field
              name="scheduledDate"
              type="text"
              component={FormField.Datepicker}
              label="Pickup Date"
              validate={[required]}
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="mustPickupDate"
              type="text"
              component={FormField.Datepicker}
              label="Must Pickup By"
              validate={[required]}
            />

          </Col>
          <Col md={6} lg={3}>

            <div className="form-group-spacing">
              <Field
                name="allowEarlyPickup"
                type="checkbox"
                component={FormField.InputToggle}
                label="Allow early pickup"
              />
            </div>

          </Col>
        </Row>

        <Row>
          <Col md={6} lg={3}>

            <Field
              name="roughDate"
              type="text"
              component={FormField.Datepicker}
              label="Rough Completed Date"
              maxDate={new Date()}
              validate={[required]}
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="roughCompletedBy"
              type="text"
              component={FormField.Input}
              label="Rough Completed By"
              validate={[required]}
            />

          </Col>
        </Row>

        <Row>
          <Col md={6} lg={3}>

            <Field
              name="project.name"
              type="text"
              component={FormField.Input}
              label="Project"
              validate={[required]}
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="project.builderName"
              type="text"
              component={FormField.Input}
              label="Builder"
              validate={[required]}
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="project.fieldSupervisorName"
              type="text"
              component={FormField.Input}
              label="Field Supervisor"
              validate={[required]}
              disabled
            />

          </Col>
        </Row>

        <Field
          name="comments"
          type="text"
          component={FormField.Textarea}
          label="Comments"
          rows={5}
        />

        <Camera
          files={files}
          help="Please upload at least 1 photo of roof jacks and 1 photo of the mechanical room."
        />
        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Request Pickup
          </Button>
        </div>

      </form>
    </div>
  );
};

const form = reduxForm({
  form: 'new-material-pickup'
})(FormNewMaterialPickup);

const selector = formValueSelector('new-material-pickup');

const mapState = state => {
  const files = selector(state, 'files');
  return {
    files
  };
};

export default connect(mapState)(form);
