import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Modal, Button } from 'react-bootstrap';
import FormField from '../../../forms/FormField';
import { ROLE_OPTIONS } from '../../../../constants';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';
const notRequired = value => undefined;

const FormEditUser = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    roleId
  } = props;

  const emailRequired = roleId !== 'materialPickupTechnician';
  return (
    <Modal.Body>

      <form onSubmit={handleSubmit}>

        <Field
          name="firstName"
          type="text"
          component={FormField.Input}
          label="First Name"
          validate={[required]}
        />

        <Field
          name="lastName"
          type="text"
          component={FormField.Input}
          label="Last Name"
          validate={[required]}
        />

        <Field
          name="username"
          type="text"
          component={FormField.Input}
          label="Username"
          validate={[required]}
          autoCapitalize="none"
        />

        <Field
          name="email"
          type="text"
          component={FormField.Input}
          label="Email"
          validate={[emailRequired ? required : notRequired]}
        />

        <Field
          name="phone"
          type="text"
          component={FormField.Input}
          label="Phone"
          validate={[required]}
        />

        <Field
          name="roleId"
          type="text"
          component={FormField.SelectReact}
          label="Role"
          validate={[required]}
          options={ROLE_OPTIONS}
        />

        <Modal.Footer>
          <Button variant="success" disabled={submitting} type="submit">
            Update
          </Button>
          <Button
            variant="outline-secondary"
            onClick={closeModal}
            disabled={submitting}
          >
            Cancel
          </Button>
        </Modal.Footer>

      </form>
    </Modal.Body>
  );
};

const formName = 'edit-user';

const form = reduxForm({
  form: formName
})(FormEditUser);

const selector = formValueSelector(formName);

const mapState = state => {
  const roleId = selector(state, 'roleId');
  return {
    roleId
  };
};

export default connect(mapState)(form);
