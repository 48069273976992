import React, { Component, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import FormField from '../forms/FormField';
import { actionCreators } from '../../state/modals-dux';
import { authenticatedHref } from '../../api/fetch';
import fetchDux from '../../state/fetch-dux';

import CameraModal from './CameraModal';
const required = value => {
  return value && (value || []).length > 0 ? undefined : 'Required';
};
class Photo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
      imgUrl: ''
    };
  }
  componentDidMount() {
    const {
      saved,
      file
    } = this.props;

    const setImage = value => this.setState({ imgUrl: value });

    if (saved) {
      const { _id } = file || {};
      const href = authenticatedHref(`files/${_id}`);
      setImage(href);
      return;
    }
    var fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = function(e) {
      setImage(this.result);
    };
  }
  render() {
    const {
      handleRemove,
      handlePreview,
      hideActions,
      hideView
    } = this.props;

    const { showActions, imgUrl } = this.state;
    const toggleActions = () => {
      if (hideActions) return;
      this.setState({ showActions: !showActions });
    };
    return (
      <div className="photo" onClick={() => toggleActions()}>
        <div className="image" style={{ backgroundImage: `url(${imgUrl})` }}>
          {showActions &&
            !hideActions &&
            <div className="photo-actions">
              {!hideView &&
                <Button variant="light" onClick={() => handlePreview(imgUrl)}>
                  View
                </Button>}
              <Button variant="danger" onClick={handleRemove}>
                Remove
              </Button>
            </div>}
        </div>
      </div>
    );
  }
}

const RenderPhotos = (
  {
    fields,
    files,
    images,
    showModal,
    deleteFileById,
    refreshImages,
    hideActions,
    hideView
  }
) => {
  const [previewImage, setPreviewImage] = useState(true);
  const handlePreview = imgUrl => {
    setPreviewImage(imgUrl);
    showModal('CameraModal');
  };
  return (
    <div className="photos">
      {(images || []).map((image, i) => {
        const { _id } = image || {};
        const handleRemove = () =>
          deleteFileById(_id, null, null, () => {
            if (!refreshImages) return;
            refreshImages();
          });
        return (
          <Photo
            key={i}
            file={image}
            handleRemove={handleRemove}
            handlePreview={handlePreview}
            hideActions={hideActions}
            hideView={hideView}
            saved
          />
        );
      })}
      {(files || []).map((file, i) => {
        const handleRemove = () => fields.remove(i);
        return (
          <Photo
            key={i}
            file={file}
            handleRemove={handleRemove}
            handlePreview={handlePreview}
            hideActions={hideActions}
            hideView={hideView}
          />
        );
      })}
      <CameraModal imgUrl={previewImage} />
    </div>
  );
};

const CameraFormGroup = (
  {
    files,
    showModal,
    images,
    deleteFileById,
    refreshImages,
    hideActions,
    hideView,
    help,
    required: isRequiredField
  }
) => {
  return (
    <div className="form-group form-group-camera">
      <Form.Label>Photos</Form.Label>
      {help && <div className="text-muted">{help}</div>}
      <FieldArray
        name="files"
        component={RenderPhotos}
        files={files}
        images={images}
        showModal={showModal}
        deleteFileById={deleteFileById}
        refreshImages={refreshImages}
        hideActions={hideActions}
        hideView={hideView}
      />

      <Field
        name="files"
        component={FormField.InputFileUpload}
        accept="image/*"
        validate={isRequiredField ? [required] : []}
        multiple
      />

    </div>
  );
};

const mapState = state => ({});
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      deleteFileById: fetchDux.deleteFileById.createAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(CameraFormGroup);
