import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const FormNewBuilder = props => {
  const { handleSubmit, submitting } = props;
  return (
    <div className="box box-form">
      <form onSubmit={handleSubmit}>

        <Field
          name="name"
          type="text"
          component={FormField.Input}
          label="Builder Name"
          validate={[required]}
        />
        <Field
          name="phone"
          type="text"
          component={FormField.Input}
          label="Builder Phone"
        />

        <br />

        <Field
          name="address"
          type="text"
          component={FormField.Input}
          label="Address"
        />

        <Row>
          <Col md={8}>
            <Field
              name="city"
              type="text"
              component={FormField.Input}
              label="City"
            />
          </Col>
          <Col md={4}>
            <Field
              name="zip"
              type="text"
              component={FormField.Input}
              label="ZIP"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={8}>
            <Field
              name="state"
              type="text"
              component={FormField.Input}
              label="State"
            />
          </Col>
        </Row>

        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Submit
          </Button>
        </div>

      </form>
    </div>
  );
};

export default reduxForm({
  form: 'new-builder'
})(FormNewBuilder);
