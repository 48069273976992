import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Notification from '../../ui/Notification';

import Icon from '../../ui/Icon';
import FormEditAddress from './FormEditAddress';

class AddressEdit extends Component {
  componentDidMount() {
    const {
      getLookups,
      getProjectById,
      match: { params: { id } }
    } = this.props;
    getLookups();
    getProjectById(id);
  }
  componentWillUnmount() {
    const {
      clearPutProject,
      clearGetLookups,
      clearGetProjectById
    } = this.props;

    clearPutProject();
    clearGetLookups();
    clearGetProjectById();
  }
  render() {
    const {
      putProject,
      lookups,
      history,
      result,
      clearPutProject,
      clearGetLookups,
      clearGetProjectById,
      errorPutProject,
      errorLookups,
      errorGetProjectById
    } = this.props;
    const {
      project
    } = result || {};
    const {
      _id
    } = project || {};
    const handleSubmit = values =>
      putProject({ id: _id, payload: values }, 'edit-address', null, data => {
        history.push(`/addresses/${_id}`);
      });
    return (
      <Layout route="address-edit">

        <div className="layout-header">
          <div>
            <h1>Edit Address</h1>
            <Link to={`/addresses/${_id}`}>
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        <Row>
          <Col md={6} xl={6}>
            <FormEditAddress
              onSubmit={handleSubmit}
              lookups={lookups}
              initialValues={project}
            />
          </Col>
        </Row>

        {errorPutProject &&
          <Notification
            key="get-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPutProject}
          >
            Error loading address details.
          </Notification>}

        {errorLookups &&
          <Notification
            key="get-projects-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetLookups}
          >
            Error loading project details.
          </Notification>}

        {errorGetProjectById &&
          <Notification
            key="get-project-detail-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetProjectById}
          >
            Error loading address details.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    error: errorPutProject
  } = state.putProject.toJS();
  const {
    result: lookups,
    error: errorLookups
  } = state.getLookups.toJS();
  const {
    result,
    inProgress,
    error: errorGetProjectById
  } = state.getProjectById.toJS();
  return {
    result,
    inProgress,
    lookups,
    errorPutProject,
    errorLookups,
    errorGetProjectById
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      putProject: fetchDux.putProject.createAction,
      clearPutProject: fetchDux.putProject.clearAction,
      getLookups: fetchDux.getLookups.createAction,
      clearGetLookups: fetchDux.getLookups.clearAction,
      getProjectById: fetchDux.getProjectById.createAction,
      clearGetProjectById: fetchDux.getProjectById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(AddressEdit);
