import React, { Component, useState } from 'react';
import Calendar from './Calendar';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Icon from './Icon';
import fetchDux from '../../state/fetch-dux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const CalendarCard = ({ event, style }) => {
  const {
    project,
    _id,
    technician
  } = event || {};
  const { firstName, lastName } = technician || {};
  const { name, address, city, state, zip } = project || {};
  const [hover, setHover] = useState(false);
  const history = useHistory();
  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onDoubleClick={() => {
        history.push(`/material-pickups/${_id}`);
      }}
      className="react-calendar-card"
      style={style || {}}
    >
      <div className="react-calendar-card-body">

        <div style={{ width: '100%', pointerEvents: 'all' }}>
          <span
            style={{
              display: 'flex',
              flex: '1',
              width: '100%',
              whiteSpace: 'nowrap'
            }}
          >
            <div style={{ display: 'flex', width: '100%', overflow: 'hidden' }}>
              <div style={{ flexGrow: '1' }}>
                <span style={{ display: 'flex' }}>
                  <span>
                    <b style={{ display: 'block' }}>
                      {name}
                    </b>
                    <small
                      style={{ display: 'block', opacity: 0.8, lineHeight: 1 }}
                    >
                      {address}
                    </small>
                    <small style={{ display: 'block', opacity: 0.8 }}>
                      {city} {state} {zip}
                    </small>
                    {firstName &&
                      lastName &&
                      <div className="technician">
                        <Icon name="user" prefix="fa" /> {firstName} {lastName}
                      </div>}
                  </span>
                </span>
                {/* <Icon prefix="fal" name="truck" /> */}
              </div>
            </div>
          </span>
        </div>
      </div>

    </div>
  );
};

const renderEvent = ({ event, style }) => {
  return <CalendarCard event={event} style={style} />;
};

class MaterialPickupsCalendar extends Component {
  render() {
    const {
      result,
      resultDefault,
      getMaterialPickups,
      hideNav,
      handleLoadEvents
    } = this.props;
    const handleLoadEventsDefault = values => {
      const { week, year } = values || {};
      const d = moment().year(year).week(week);
      const startDate = d.clone().startOf('week').startOf('day').toDate();
      const endDate = d.clone().endOf('week').endOf('day').toDate();
      getMaterialPickups(
        `startDate=${startDate}&endDate=${endDate}&pageSize=1000`
      );
    };

    return (
      <div>
        {!hideNav &&
          <div className="box-nav">
            <ul className="nav nav-pills">
              <li className="nav-item">
                <Link className="nav-link active" to="/material-pickups">
                  Calendar
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/material-pickups/grid">
                  Grid
                </Link>
              </li>
            </ul>
          </div>}
        <Calendar
          view="week"
          loadEvents={handleLoadEvents || handleLoadEventsDefault}
          events={(handleLoadEvents ? result : resultDefault) || []}
          renderEvent={renderEvent}
          calendarProps={{
            startDateField: 'scheduledDate'
          }}
        />
      </div>
    );
  }
}

const mapState = state => {
  const {
    result
  } = state.getMaterialPickups.toJS();
  const { inspections: resultDefault } = result || {};
  return {
    resultDefault
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getMaterialPickups: fetchDux.getMaterialPickups.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MaterialPickupsCalendar);
