import React, { Component } from 'react';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import InspectionAssignCard from '../../ui/InspectionAssignCard';
import InspectionScheduleCard from '../../ui/InspectionScheduleCard';
import InspectionStatus from './InspectionStatus';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import classNames from 'classnames';
import Table from '../../ui/Table';

const COLUMNS = [
  {
    Header: '',
    accessor: '',
    className: 'rt-td-dropdown',
    width: 55,
    Cell: ({ value, original: row }) => {
      const { projectId, status } = row || {};
      return (
        <Dropdown menualign="right">
          <Dropdown.Toggle variant="success" size="sm" block>
            <Icon name="bars" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link className="dropdown-item" to={`/addresses/${projectId}`}>
              View Address
            </Link>
            <Link
              className="dropdown-item"
              to={`/quality-assurance/inspection/${row._id}`}
            >
              View Inspection
            </Link>
            {(status === 'reschedule' || status === 'denied') &&
              <Link
                className="dropdown-item"
                to={`/quality-assurance/inspection/${row._id}/reschedule`}
              >
                Reschedule Inspection
              </Link>}
            {(status === 'failed' || status === 'criticalFailed') &&
              <Link
                className="dropdown-item"
                to={`/quality-assurance/inspection/${row._id}/resolve`}
              >
                Resolve Inspection Failures
              </Link>}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  },
  {
    Header: 'Address',
    accessor: 'project.address',
    width: 150,
    Cell: ({ value, original: row }) => (
      <Link to={`/quality-assurance/inspection/${row._id}`}>{value}</Link>
    )
  },
  {
    Header: 'City',
    accessor: 'project.city',
    width: 80,
    show: false
  },
  {
    Header: 'ZIP',
    accessor: 'project.zip',
    width: 60,
    show: false
  },
  {
    Header: 'State',
    accessor: 'project.state',
    width: 50,
    show: false
  },
  {
    Header: 'Project',
    accessor: 'project',
    width: 150,
    show: false,
    Cell: ({ value }) => {
      const { name } = value || {};
      return (
        <span>
          {name || '-'}
        </span>
      );
    }
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 130,
    Cell: ({ value }) => {
      return (
        <span>
          {value === 'roughInspection' && <span>Rough Inspection</span>}
          {value === 'finalInspection' && <span>Final Inspection</span>}
        </span>
      );
    }
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value, original: row }) => {
      const { isPausedInspection } = row || {};
      if (isPausedInspection) {
        return <div className="status red">Paused</div>;
      }
      return <InspectionStatus status={value} hideIcon />;
    }
  },
  {
    Header: 'Scheduled Date',
    accessor: 'scheduledDate',
    width: 110,
    Cell: ({ value }) => (
      <span>
        {value && moment(value).isValid()
          ? moment(value).format('MM/DD/YYYY')
          : ''}
      </span>
    )
  },
  {
    Header: 'Builder',
    accessor: 'project.builder.name'
  },
  {
    Header: 'Field Supervisor',
    accessor: 'project.fieldSupervisor',
    Cell: ({ value }) => {
      const {
        _id,
        firstName,
        lastName
      } = value || {};
      if (!value) return '';
      return <Link to={`/admin/users/${_id}`}>{firstName} {lastName}</Link>;
    }
  },
  {
    Header: 'Pickup Technician',
    accessor: 'technician',
    Cell: ({ value }) => {
      const {
        _id,
        firstName,
        lastName
      } = value || {};
      if (!value) return '';
      return <Link to={`/admin/users/${_id}`}>{firstName} {lastName}</Link>;
    }
  }
];

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const getActiveColumns = () => {
  try {
    const str = window.sessionStorage.getItem('qual-columns');
    if (!str) return COLUMNS;
    const activeColumns = JSON.parse(str);
    if (!activeColumns) return COLUMNS;
    return COLUMNS.map(c => {
      if (activeColumns.some(a => a === c.accessor))
        return { ...c, show: true };
      return { ...c, show: false };
    });
  } catch (err) {
    return COLUMNS;
  }
};

const compare = (obj1, obj2) =>
  obj1.page === obj2.page &&
  obj1.sortBy === obj2.sortBy &&
  obj1.desc === obj2.desc &&
  obj1.search === obj2.search;

const getInspectionsFilter = filter => {
  if (filter === 'failures') return 'failed'; // only failures
  if (filter === 'critical-failures') return 'criticalFailed'; // only critical failures
  return ''; // all inspections
};

class QualityAssuranceInspections extends Component {
  constructor(props) {
    super(props);
    const columns = getActiveColumns();

    this.state = {
      columns
    };
    const { history, location: { pathname, search }, match } = this.props;
    const { params } = match || {};
    const { filter } = params || {};

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search) || {};

    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || 'scheduledDate',
      desc: getDesc(desc),
      search: qSearch || '',
      status: getInspectionsFilter(filter)
    });

    history.push({ pathname, search: qs });
  }
  componentDidMount() {
    const {
      getInspections,
      getInspectionEvents,
      location: { search },
      match
    } = this.props;

    const { params } = match || {};
    const { filter } = params || {};
    const {
      page,
      pageSize,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search);

    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || 'scheduledDate',
      desc: getDesc(),
      search: qSearch || '',
      status: getInspectionsFilter(filter)
    });

    getInspections(qs);
    getInspectionEvents('pageSize=1000');
  }
  componentWillReceiveProps(nextProps) {
    const { location: { search }, match } = nextProps;
    const { location: { search: search2 } } = this.props;

    const obj1 = queryString.parse(search) || {};
    const obj2 = queryString.parse(search2) || {};

    if (compare(obj1, obj2)) return;

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = obj1 || {};
    const { params } = match || {};
    const { filter } = params || {};
    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || 'scheduledDate',
      desc: getDesc(desc),
      search: qSearch || '',
      status: getInspectionsFilter(filter)
    });

    nextProps.getInspections(qs);
  }
  componentWillUnmount() {
    const {
      clearGetInspections,
      clearGetInspectionEvents
    } = this.props;

    clearGetInspections();
    clearGetInspectionEvents();
  }
  render() {
    const {
      result,
      getInspections,
      getInspectionsInProgress,
      location: { search },
      resultInspectionsEvents,
      getInspectionEvents,
      match,
      user
    } = this.props;

    const { params } = match || {};
    const { filter } = params || {};

    const {
      columns
    } = this.state;

    const {
      numPages,
      inspections
    } = result || {};

    const {
      inspections: inspectionEvents
    } = resultInspectionsEvents || {};

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search);

    const handleGetInspections = props => {
      const { history, location: { pathname }, match } = this.props;
      const { params } = match || {};
      const { filter } = params || {};
      const {
        page,
        sortBy,
        desc,
        search: qSearch
      } = props || {};

      const search = queryString.stringify({
        page: page || '0',
        sortBy: sortBy || 'scheduledDate',
        desc: getDesc(desc),
        search: qSearch || '',
        status: getInspectionsFilter(filter)
      });

      history.push({ pathname, search });
    };

    const handlePaginationChange = state => {
      const {
        location: { search }
      } = this.props;

      const {
        search: qSearch
      } = queryString.parse(search);

      const {
        page,
        sorted
      } = state || {};

      const [sort] = sorted || [];

      const {
        id: sortBy,
        desc
      } = sort || {};

      handleGetInspections({
        page: page || '',
        sortBy,
        search: qSearch,
        desc
      });
    };

    const handleSearchChange = e => {
      const { value } = e.target || {};
      const {
        location: { search }
      } = this.props;

      const {
        sortBy,
        desc
      } = queryString.parse(search);

      handleGetInspections({
        page: '0',
        sortBy,
        desc,
        search: value
      });
    };

    const handleUnmount = ({ columns, activeColumns }) => {
      window.sessionStorage.setItem(
        'qual-columns',
        JSON.stringify(activeColumns)
      );
    };

    // Only admin sees all inspections
    let filteredInspections = inspections;
    if (user && user.roleId !== 'administrator') {
      filteredInspections = (inspections || [])
        .filter(i => i.project.fieldSupervisor._id === user._id);
    }

    return (
      <Layout route="quality-assurance-inspections">
        <div className="layout-header">
          <div>
            <h1>
              {filter === 'critical-failures'
                ? 'Critical Failures'
                : 'Inspections'}
            </h1>
          </div>
          <div>
            <Link
              to="/quality-assurance/new"
              className="btn btn-success btn-icon"
            >
              Request Inspection <Icon name="plus" />
            </Link>
          </div>
        </div>

        <div className="box box-smart-table">
          <Table
            showColumnsSelector
            showSearch
            showExport
            data={filteredInspections || []}
            columns={columns}
            noDataText={
              getInspectionsInProgress
                ? 'Loading Inspections...'
                : 'No Inspections Found'
            }
            manual
            pages={numPages}
            page={parseInt(page)}
            sortBy={sortBy}
            sortDirection={desc === 'true' ? 'desc' : 'asc'}
            onFetchData={handlePaginationChange}
            handleServerSearchChange={handleSearchChange}
            qSearch={qSearch}
            onUnmount={handleUnmount}
            isServerPaging
          />
        </div>

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getInspectionsInProgress
  } = state.getInspections.toJS();

  const {
    result: resultInspectionsEvents,
    inProgress: getInspectionsEventsInProgress
  } = state.getInspectionEvents.toJS();

  const {
    result: user
  } = state.login.toJS();

  return {
    user,
    result,
    resultInspectionsEvents,
    getInspectionsInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getInspections: fetchDux.getInspections.createAction,
      clearGetInspections: fetchDux.getInspections.clearAction,
      getInspectionEvents: fetchDux.getInspectionEvents.createAction,
      clearGetInspectionEvents: fetchDux.getInspectionEvents.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(QualityAssuranceInspections);
