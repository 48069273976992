import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import moment from 'moment';
import queryString from 'query-string';

import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Table from '../../ui/Table';
import Icon from '../../ui/Icon';
import Notification from '../../ui/Notification';
import InspectionStatus from '../quality-assurance/InspectionStatus';

const columns = [
  {
    Header: 'Work Order No.',
    accessor: 'workOrderNo',
    show: false
  },
  {
    Header: 'Address',
    accessor: 'address',
    Cell: ({ value, original: row }) => (
      <Link to={`/addresses/${row._id}`}>{value}</Link>
    )
  },
  {
    Header: 'City',
    accessor: 'city',
    show: false
  },
  {
    Header: 'ZIP',
    accessor: 'zip',
    show: false
  },
  {
    Header: 'State',
    accessor: 'state',
    show: false
  },
  {
    Header: 'Project Name',
    accessor: 'name',
    show: false
  },
  {
    Header: 'Home Plan Number',
    accessor: 'homePlanNo',
    show: false
  },
  {
    Header: 'Builder',
    accessor: 'builder.name'
  },
  {
    Header: 'Field Supervisor',
    accessor: 'fieldSupervisor.lastName',
    Cell: ({ original: row }) => {
      const { fieldSupervisor } = row || {};
      if (!fieldSupervisor) return '';
      const {
        firstName,
        lastName
      } = fieldSupervisor || {};
      return `${firstName} ${lastName}`;
    }
  },
  {
    Header: 'Days Materials on Site',
    accessor: 'MPDays',
    show: false
  },
  {
    Header: 'Material Pickup',
    accessor: 'materialPickupStatus',
    Cell: ({ value }) => <InspectionStatus status={value} />
  },
  {
    Header: 'Rough Inpsection',
    accessor: 'roughInspectionStatus',
    Cell: ({ value }) => <InspectionStatus status={value} />
  },
  {
    Header: 'Final Inspection',
    accessor: 'finalInspectionStatus',
    Cell: ({ value }) => <InspectionStatus status={value} />
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
    show: false,
    Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
  }
];

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const compare = (obj1, obj2) =>
  obj1.page === obj2.page &&
  obj1.sortBy === obj2.sortBy &&
  obj1.desc === obj2.desc &&
  obj1.search === obj2.search;

class AddressesSearch extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search) || {};

    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || '_id',
      desc: getDesc(desc),
      search: qSearch || ''
    });

    history.push({ pathname, search: qs });
  }
  componentDidMount() {
    const {
      getProjects
    } = this.props;

    const { location: { search } } = this.props;
    const {
      page,
      pageSize,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search);

    const qs = queryString.stringify({
      page: page || '0',
      pageSize: pageSize || '20',
      sortBy: sortBy || '_id',
      desc: getDesc(desc),
      search: qSearch || ''
    });

    getProjects(qs);
  }
  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps;
    const { location: { search: search2 } } = this.props;

    const obj1 = queryString.parse(search) || {};
    const obj2 = queryString.parse(search2) || {};

    if (compare(obj1, obj2)) return;

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = obj1 || {};
    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || '_id',
      desc: getDesc(desc),
      search: qSearch || ''
    });

    nextProps.getProjects(qs);
  }
  componentWillUnmount() {
    const {
      clearGetProjects
    } = this.props;

    clearGetProjects();
  }
  render() {
    const {
      projectResults,
      error,
      clearGetProjects,
      location: { search },
      inProgress,
      downloadAddressesCSV,
      downloadAddressesCSVInProgress
    } = this.props;

    const {
      numPages,
      count,
      projects
    } = projectResults || {};

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search);

    const handleGetProjects = props => {
      const { history, location: { pathname } } = this.props;

      const {
        page,
        sortBy,
        desc,
        search: qSearch
      } = props || {};

      const search = queryString.stringify({
        page: page || '0',
        sortBy: sortBy || '_id',
        desc: getDesc(desc),
        search: qSearch || ''
      });

      history.push({ pathname, search });
    };

    const handlePaginationChange = state => {
      const {
        location: { search }
      } = this.props;

      const {
        search: qSearch
      } = queryString.parse(search);

      const {
        page,
        sorted
      } = state || {};

      const [sort] = sorted || [];

      const {
        id: sortBy,
        desc
      } = sort || {};

      handleGetProjects({
        page: page || '',
        sortBy,
        search: qSearch,
        desc
      });
    };

    const handleSearchChange = e => {
      const { value } = e.target || {};
      const {
        location: { search }
      } = this.props;

      const {
        sortBy,
        desc,
        search: qSearch
      } = queryString.parse(search);

      handleGetProjects({
        page: '0',
        sortBy,
        desc,
        search: value
      });
    };

    const exportAll = () => {
      const { location: { search } } = this.props;
      const payload = queryString.parse(search);
      downloadAddressesCSV({
        payload,
        Filename: 'addresses.csv'
      });
    };

    return (
      <Layout route="addresses-search">

        <div className="layout-header">
          <h1>Addresses</h1>
          <div>
            <Link to="/addresses/import" className="btn btn-success btn-icon">
              Import Addresses <Icon name="upload" />
            </Link>
            <Link to="/addresses/new" className="btn btn-success btn-icon">
              Create Address <Icon name="plus" />
            </Link>
          </div>
        </div>

        <div className="box box-smart-table">
          <Table
            showColumnsSelector
            showSearch
            showExport
            data={projects || []}
            columns={columns}
            noDataText={
              inProgress ? 'Loading Addresses...' : 'No Addresses Found'
            }
            manual
            pages={numPages}
            page={parseInt(page)}
            sortBy={sortBy}
            sortDirection={desc === 'true' ? 'desc' : 'asc'}
            onFetchData={handlePaginationChange}
            handleServerSearchChange={handleSearchChange}
            qSearch={qSearch}
            isServerPaging
            onExportAll={exportAll}
            onExportInProgress={downloadAddressesCSVInProgress}
          />
        </div>

        {error &&
          <Notification
            key="put-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetProjects}
          >
            Error loading addresses.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    inProgress,
    result: projectResults,
    error
  } = state.getProjects.toJS();
  const {
    inProgress: downloadAddressesCSVInProgress,
    error: downloadAddressesCSVError
  } = state.downloadAddressesCSV.toJS();
  return {
    projectResults,
    error,
    inProgress,
    downloadAddressesCSVInProgress,
    downloadAddressesCSVError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProjects: fetchDux.getProjects.createAction,
      clearGetProjects: fetchDux.getProjects.clearAction,
      downloadAddressesCSV: fetchDux.downloadAddressesCSV.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(AddressesSearch);
