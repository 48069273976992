import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';

import Icon from '../../ui/Icon';
import FormNewInspection from './FormNewInspection';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';

class QualityAssuranceNew extends Component {
  componentDidMount() {
    const { getLookups } = this.props;
    getLookups();
  }
  render() {
    const {
      lookups,
      postInspection,
      history
    } = this.props;

    const { qualityAssuranceTechnicians } = lookups || {};

    const assigneeOptions = (qualityAssuranceTechnicians || []).map(({
      _id,
      firstName,
      lastName
    }) => ({
      value: _id,
      label: `${firstName} ${lastName}`
    }));

    const handleSubmit = payload => {
      postInspection({ payload }, 'new-rough-inspection', null, data => {
        const { insertedId } = data || {};
        history.push(`/quality-assurance/inspection/${insertedId}`);
      });
    };

    return (
      <Layout route="quality-assurance-new">

        <div className="layout-header">
          <div>
            <h1>Request Inspection</h1>
            <Link to="/quality-assurance">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        <FormNewInspection
          onSubmit={handleSubmit}
          assigneeOptions={assigneeOptions}
        />

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: lookups
  } = state.getLookups.toJS();
  return {
    lookups
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getLookups: fetchDux.getLookups.createAction,
      postInspection: fetchDux.postInspection.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(QualityAssuranceNew);
