import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import QualityAssuranceCalendar from './QualityAssuranceCalendar';
import QualityAssuranceGrid from './QualityAssuranceGrid';
import QualityAssuranceInspections from './QualityAssuranceInspections';
import QualityAssuranceNew from './QualityAssuranceNew';
import QualityAssuranceEdit from './QualityAssuranceEdit';
import InspectionDetail from './InspectionDetail';
import InspectionDetailSupervisor from './InspectionDetailSupervisor';
import InspectionReschedule from './InspectionReschedule';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={QualityAssuranceCalendar} />
    <Route exact path={`${match.url}/grid`} component={QualityAssuranceGrid} />
    <Route exact path={`${match.url}/new`} component={QualityAssuranceNew} />
    <Route
      exact
      path={`${match.url}/inspections`}
      component={QualityAssuranceInspections}
    />
    <Route
      exact
      path={`${match.url}/inspections/:filter`}
      component={QualityAssuranceInspections}
    />
    <Route
      exact
      path={`${match.url}/inspection/:id/resolve`}
      component={InspectionDetailSupervisor}
    />
    <Route
      exact
      path={`${match.url}/inspection/:id/reschedule`}
      component={InspectionReschedule}
    />
    <Route
      exact
      path={`${match.url}/inspection/:id`}
      component={InspectionDetail}
    />
    <Route
      exact
      path={`${match.url}/inspection/:id/edit`}
      component={QualityAssuranceEdit}
    />
    <Redirect to={`${match.url}`} />
  </Switch>
);
