import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AdminBuilders from './builders/AdminBuilders.js';
import AdminUsers from './users/AdminUsers.js';
import AdminUserGroups from './user-groups/AdminUserGroups.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={AdminUsers} />
    <Route path={`${match.url}/builders`} component={AdminBuilders} />
    <Route path={`${match.url}/users`} component={AdminUsers} />
    <Route path={`${match.url}/user-groups`} component={AdminUserGroups} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
