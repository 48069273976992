import React from 'react';
import { Component } from 'react';
import { Alert } from 'react-bootstrap';
import RcNotification from 'rc-notification';

const getNotification = props =>
  new Promise((resolve, reject) => {
    RcNotification.newInstance(props, resolve);
  });

export default class Notification extends Component {
  constructor(props) {
    super(props);
  }
  async componentWillMount() {

    const { prefixCls } = this.props;
    this.notification = await getNotification({
      prefixCls,
      style: { right: 0, top: 'auto', bottom: 0 }
    });

    const {
      key,
      closable,
      onClose,
      duration,
      type,
      children
    } = this.props;
    const content = <Alert variant={type || 'info'}>{children}</Alert>;
    const style = { right: '20px' };
    this.notification.notice({
      key,
      closable,
      onClose,
      duration,
      content,
      style
    });
  }
  componentWillUnmount() {
    this.notification.destroy(this.props.key);
  }
  render() {
    return null;
  }
}
