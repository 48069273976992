import React, { Component } from 'react';

import classNames from 'classnames';
import Layout from '../../ui/Layout';
import Table from '../../ui/Table';
import Icon from '../../ui/Icon';
// import Map from '../../ui/Map';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import queryString from 'query-string';
import MaterialPickupStatus from '../../ui/MaterialPickupStatus';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';

const COLUMNS = [
  {
    Header: '',
    accessor: '',
    className: 'rt-td-dropdown',
    width: 55,
    Cell: ({ value, original: row }) => {
      const { _id } = value || {};
      const { projectId, status } = row || {};
      return (
        <Dropdown menualign="right">
          <Dropdown.Toggle variant="success" size="sm" block>
            <Icon name="bars" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link className="dropdown-item" to={`/addresses/${projectId}`}>
              View Address
            </Link>
            {status !== 'completed' &&
              <Link
                className="dropdown-item"
                to={`/material-pickups/${_id}/complete-pickup`}
              >
                Complete Pickup
              </Link>}
            {status !== 'completed' &&
              <Link
                className="dropdown-item"
                to={`/material-pickups/${_id}/unable-to-pickup`}
              >
                Unable to Pickup
              </Link>}
            {status !== 'completed' &&
              <Link
                className="dropdown-item"
                to={`/material-pickups/${_id}/reschedule-pickup`}
              >
                Reschedule Pickup
              </Link>}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  },
  {
    Header: 'Address',
    accessor: 'project.address',
    width: 150,
    Cell: ({ value, original: row }) => (
      <Link to={`/addresses/${row.projectId}`}>{value}</Link>
    )
  },
  {
    Header: 'City',
    accessor: 'project.city',
    width: 80,
    show: false
  },
  {
    Header: 'ZIP',
    accessor: 'project.zip',
    width: 60,
    show: false
  },
  {
    Header: 'State',
    accessor: 'project.state',
    width: 50,
    show: false
  },
  {
    Header: 'Project',
    accessor: 'project',
    width: 150,
    show: false,
    Cell: ({ value }) => {
      const { name } = value || {};
      return <span>{name || '-'}</span>;
    }
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <MaterialPickupStatus status={value} hideIcon />
  },
  {
    Header: 'Pickup Date',
    accessor: 'scheduledDate',
    Cell: ({ value }) => (
      <span>
        {value && moment(value).isValid()
          ? moment(value).format('MM/DD/YYYY')
          : ''}
      </span>
    )
  },
  {
    Header: 'Builder',
    accessor: 'project.builder.name'
  },
  {
    Header: 'Field Supervisor',
    accessor: 'project.fieldSupervisor',
    Cell: ({ value }) => {
      const {
        _id,
        firstName,
        lastName
      } = value || {};
      if (!value) return '';
      return <Link to={`/admin/users/${_id}`}>{firstName} {lastName}</Link>;
    }
  },
  {
    Header: 'Pickup Technician',
    accessor: 'technician',
    Cell: ({ value }) => {
      const {
        _id,
        firstName,
        lastName
      } = value || {};
      if (!value) return '';
      return <Link to={`/admin/users/${_id}`}>{firstName} {lastName}</Link>;
    }
  }
];

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const getActiveColumns = () => {
  try {
    const str = window.sessionStorage.getItem('material-columns');
    if (!str) return COLUMNS;
    const activeColumns = JSON.parse(str);
    if (!activeColumns) return COLUMNS;
    return COLUMNS.map(c => {
      if (activeColumns.some(a => a === c.accessor))
        return { ...c, show: true };
      return { ...c, show: false };
    });
  } catch (err) {
    return COLUMNS;
  }
};

const compare = (obj1, obj2) =>
  obj1.page === obj2.page &&
  obj1.sortBy === obj2.sortBy &&
  obj1.desc === obj2.desc &&
  obj1.search === obj2.search;

class MaterialPickupsGrid extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const columns = getActiveColumns();

    this.state = { columns };

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search) || {};

    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || 'scheduledDate',
      desc: getDesc('true'),
      search: qSearch || ''
    });

    history.push({ pathname, search: qs });
  }
  componentDidMount() {
    const {
      getMaterialPickups,
      getMapMaterialPickups
    } = this.props || {};

    const { location: { search } } = this.props;
    const {
      page,
      pageSize,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search);

    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || 'scheduledDate',
      desc: getDesc(),
      search: qSearch || ''
    });

    getMaterialPickups(qs);
    getMapMaterialPickups('');
  }
  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps;
    const { location: { search: search2 } } = this.props;

    const obj1 = queryString.parse(search) || {};
    const obj2 = queryString.parse(search2) || {};

    if (compare(obj1, obj2)) return;

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = obj1 || {};
    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || 'scheduledDate',
      desc: getDesc(desc),
      search: qSearch || ''
    });

    nextProps.getMaterialPickups(qs);
  }
  componentWillUnmount() {
    const {
      clearGetMaterialPickups,
      clearGetMapMaterialPickups
    } = this.props;

    clearGetMaterialPickups();
    clearGetMapMaterialPickups();
  }
  render() {
    const {
      result,
      inProgress,
      location: { search },
      resultMapMaterialPickups
    } = this.props;

    const {
      numPages,
      inspections
    } = result || {};

    const { columns } = this.state;

    const {
      inspections: mapInspections
    } = resultMapMaterialPickups || {};

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search);

    const handleGetMaterialPickups = props => {
      const { history, location: { pathname } } = this.props;

      const {
        page,
        sortBy,
        desc,
        search: qSearch
      } = props || {};

      const search = queryString.stringify({
        page: page || '0',
        sortBy: sortBy || 'scheduledDate',
        desc: getDesc(desc),
        search: qSearch || ''
      });

      history.push({ pathname, search });
    };

    const handlePaginationChange = state => {
      const {
        location: { search }
      } = this.props;

      const {
        search: qSearch
      } = queryString.parse(search);

      const {
        page,
        sorted
      } = state || {};

      const [sort] = sorted || [];

      const {
        id: sortBy,
        desc
      } = sort || {};

      handleGetMaterialPickups({
        page: page || '',
        sortBy,
        search: qSearch,
        desc
      });
    };

    const handleSearchChange = e => {
      const { value } = e.target || {};
      const {
        location: { search }
      } = this.props;

      const {
        sortBy,
        desc
      } = queryString.parse(search);

      handleGetMaterialPickups({
        page: '0',
        sortBy,
        desc,
        search: value
      });
    };

    const locations = (mapInspections || [])
      .map(pickup => {
        const {
          project,
          allowEarlyPickup
        } = pickup || {};
        const {
          lat,
          lng
        } = project || {};
        const iconStyle = allowEarlyPickup ? 'green' : 'blue';
        return lat && lng ? { lat, lng, iconStyle } : {};
      })
      .filter(({ lat, lng }) => lat && lng);

    const handleUnmount = ({ columns, activeColumns }) => {
      window.sessionStorage.setItem(
        'material-columns',
        JSON.stringify(activeColumns)
      );
    };

    return (
      <Layout route="material-pickups-grid">

        <div className="layout-header">
          <div>
            <h1>Material Pickups</h1>
          </div>
          <div>
            <Link
              to="/material-pickups/new"
              className="btn btn-success btn-icon"
            >
              Request Material Pickup <Icon name="plus" />
            </Link>
          </div>
        </div>

        {/*
          <Map geocodes={locations} />
        */}

        <div className="box-nav">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <Link className="nav-link" to="/material-pickups">Calendar</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/material-pickups/grid">
                Grid
              </Link>
            </li>
          </ul>
        </div>

        <div className="box box-smart-table">
          <Table
            showColumnsSelector
            showSearch
            showExport
            data={inspections || []}
            columns={columns}
            noDataText={
              inProgress
                ? 'Loading Material Pickups...'
                : 'No Material Pickups Found'
            }
            manual
            pages={numPages}
            page={parseInt(page)}
            sortBy={sortBy}
            sortDirection={desc === 'true' ? 'desc' : 'asc'}
            onFetchData={handlePaginationChange}
            handleServerSearchChange={handleSearchChange}
            qSearch={qSearch}
            isServerPaging
            onUnmount={handleUnmount}
          />
        </div>

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getMaterialPickups.toJS();

  const {
    result: resultMapMaterialPickups,
    inProgress: getMapMaterialPickupsInProgress
  } = state.getMapMaterialPickups.toJS();

  return {
    result,
    inProgress,
    resultMapMaterialPickups
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getMaterialPickups: fetchDux.getMaterialPickups.createAction,
      clearGetMaterialPickups: fetchDux.getMaterialPickups.clearAction,
      getMapMaterialPickups: fetchDux.getMapMaterialPickups.createAction,
      clearGetMapMaterialPickups: fetchDux.getMapMaterialPickups.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MaterialPickupsGrid);
