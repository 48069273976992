import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button } from 'react-bootstrap';
import { checkUsername } from '../../../../api/api';
import { ROLE_OPTIONS } from '../../../../constants';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';
const notRequired = value => undefined;

const passwordMatch = (...props) => {
  const { password, confirmPassword } = props[1] || {};

  return password !== confirmPassword ? 'Passwords do not match' : undefined;
};

const passwordLength = (...props) => {
  const { password } = props[1] || {};
  return (password || []).length < 8
    ? 'Password must be at least 8 characters long'
    : undefined;
};

const asyncValidateUserName = async ({ username }) => {
  const { exists } = await checkUsername(username);
  if (exists) throw { username: 'Username already exists' };
};

const FormNewUser = props => {
  const { handleSubmit, submitting, roleId } = props;
  const emailRequired = roleId !== 'materialPickupTechnician';
  return (
    <div className="box box-form">
      <form onSubmit={handleSubmit}>

        <Field
          name="firstName"
          type="text"
          component={FormField.Input}
          label="First Name"
          validate={[required]}
        />

        <Field
          name="lastName"
          type="text"
          component={FormField.Input}
          label="Last Name"
          validate={[required]}
        />

        <Field
          name="email"
          type="text"
          component={FormField.Input}
          label="Email"
          validate={[emailRequired ? required : notRequired]}
        />

        <Field
          name="phone"
          type="text"
          component={FormField.Input}
          label="Phone"
          validate={[required]}
        />

        <Field
          name="username"
          type="text"
          component={FormField.Input}
          label="Username"
          validate={[required]}
          autoCapitalize="none"
        />

        <Field
          name="password"
          type="password"
          component={FormField.Input}
          label="Password"
          validate={[required, passwordLength]}
        />

        <Field
          name="confirmPassword"
          type="password"
          component={FormField.Input}
          label="Confirm Password"
          validate={[required, passwordMatch, passwordLength]}
        />

        <Field
          name="roleId"
          type="text"
          component={FormField.SelectReact}
          label="Role"
          validate={[required]}
          options={ROLE_OPTIONS}
        />

        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Submit
          </Button>
        </div>

      </form>
    </div>
  );
};

const formName = 'new-user';

const selector = formValueSelector(formName);

const form = reduxForm({
  form: formName,
  asyncValidate: asyncValidateUserName,
  asyncBlurFields: ['username']
})(FormNewUser);

const mapState = state => {
  const roleId = selector(state, 'roleId');

  return {
    roleId
  };
};

export default connect(mapState)(form);
