import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import { formValueSelector } from 'redux-form';

import FormResumeInspection from './FormResumeInspection';

const ModalResumeInspection = (
  {
    openModal,
    closeModal,
    name,
    handleResume,
    address,
    city,
    state,
    zip
  }
) => {
  return (
    <Modal show={openModal === name}>
      <Modal.Header closeButton>
        <Modal.Title>Resume Inspection</Modal.Title>
      </Modal.Header>
      <FormResumeInspection
        {...{
          address,
          city,
          state,
          zip,
          closeModal
        }}
        onSubmit={values => {
          const {
            scheduledDate,
            comments
          } = values;
          handleResume(scheduledDate, comments);
        }}
      />
    </Modal>
  );
};

const selector = formValueSelector('resume-inspection');

const mapState = state => {
  const comments = selector(state, 'comments');
  const scheduledDate = selector(state, 'scheduledDate');
  return {
    openModal: state.modals,
    comments,
    scheduledDate
  };
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalResumeInspection);
