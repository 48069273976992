import React, { Component } from 'react';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
import Loading from '../../ui/Loading';
import { Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import InspectionStatus from '../quality-assurance/InspectionStatus';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

const SIZE = '66%';

const HomepageChart = ({ options }) => {
  const totalValues = (options.series[0].data || [])
    .reduce((a, b) => a + b.y, 0);
  return (
    <div className="box-body">
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div className="legend">
        {(options.series[0].data || []).map(({ color, name, y }, i) => {
          return (
            <div key={i}>
              <span className="values">
                {y}
                {' '}
                <span className="text-muted">
                  ({numeral(y / totalValues).format('0.0%')})
                </span>
              </span>
              <span className="color" style={{ backgroundColor: color }} />
              <b>{name}</b>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getColor = id => {
  switch (id) {
    case 'pending':
      return '#ccc';
    case 'failed':
      return '#fd7e14';
    case 'criticalFailed':
      return '#dc3545';
    case 'reschedule':
      return '#ffc107';
    case 'denied':
      return 'orange';
    case 'late':
      return '#343a40';
    default:
      return '#ccc';
  }
};
const getName = id => {
  switch (id) {
    case 'pending':
      return 'Pending';
    case 'failed':
      return 'Failed';
    case 'criticalFailed':
      return 'Critical Failed';
    case 'reschedule':
      return 'Reschedule';
    case 'denied':
      return 'Unable To Complete';
    case 'late':
      return 'Late';
    default:
      return id;
  }
};

const formatData = (data = []) =>
  data.map(({
    _id,
    y
  }) => ({
    _id,
    y,
    color: getColor(_id),
    name: getName(_id)
  }));

class Homepage extends Component {
  componentDidMount() {
    const { getDashboardStats } = this.props;
    getDashboardStats();
  }
  render() {
    const {
      result,
      inProgress
    } = this.props;
    const {
      roughCount,
      finalCount,
      mpCount,
      avgMPDays,
      outstandingMPs,
      outstandingInspections
    } = result || {};

    return (
      <Layout route="homepage">

        <div className="layout-header">
          <div>
            <h1>Dashboard</h1>
          </div>
        </div>

        {inProgress && <Loading />}

        {!inProgress &&
          <div>
            <Row>
              <Col md={4}>

                <div className="box box-pie-chart">
                  <div className="box-body">
                    <HomepageChart
                      options={{
                        title: {
                          text: 'Material Pickups'
                        },
                        tooltip: {
                          pointFormat: '{point.y} ({point.percentage:.1f}%)'
                        },
                        plotOptions: {
                          pie: {
                            size: SIZE,
                            dataLabels: {
                              enabled: false,
                              format: '<b>{point.name}</b><br>{point.y} ({point.percentage:.1f}%)'
                            }
                          }
                        },
                        series: [
                          {
                            type: 'pie',
                            colorByPoint: true,
                            data: formatData(mpCount)
                          }
                        ]
                      }}
                    />
                  </div>
                </div>

              </Col>
              <Col md={4}>

                <div className="box box-pie-chart">
                  <div className="box-body">
                    <HomepageChart
                      options={{
                        title: {
                          text: 'Rough Inspections'
                        },
                        tooltip: {
                          pointFormat: '{point.y} ({point.percentage:.1f}%)'
                        },
                        plotOptions: {
                          pie: {
                            size: SIZE,
                            dataLabels: {
                              enabled: false,
                              format: '<b>{point.name}</b><br>{point.y} ({point.percentage:.1f}%)'
                            }
                          }
                        },
                        series: [
                          {
                            type: 'pie',
                            colorByPoint: true,
                            data: formatData(roughCount)
                          }
                        ]
                      }}
                    />
                  </div>
                </div>

              </Col>
              <Col md={4}>

                <div className="box box-pie-chart">
                  <div className="box-body">
                    <HomepageChart
                      options={{
                        title: {
                          text: 'Final Inspections'
                        },
                        tooltip: {
                          pointFormat: '{point.y} ({point.percentage:.1f}%)'
                        },
                        plotOptions: {
                          pie: {
                            size: SIZE,
                            dataLabels: {
                              enabled: false,
                              format: '<b>{point.name}</b><br>{point.y} ({point.percentage:.1f}%)'
                            }
                          }
                        },
                        series: [
                          {
                            type: 'pie',
                            colorByPoint: true,
                            data: formatData(finalCount)
                          }
                        ]
                      }}
                    />
                  </div>
                </div>

              </Col>
              <Col md={4}>

                <div className="box">
                  <div className="box-body">
                    <strong style={{ float: 'right' }}>
                      {avgMPDays || 0}
                    </strong>
                    Days Materials on Site
                  </div>
                </div>

              </Col>
            </Row>

            <p className="text-muted" style={{ opacity: 0.5 }}>
              <Icon prefix="fal" name="info-circle" />
              {' '}
              Numbers are for entire company
            </p>

            <Row>
              <Col md={6}>

                <h5>
                  {(outstandingMPs || []).length > 0 &&
                    <span>{outstandingMPs.length}</span>}
                  {' '}
                  Outstanding Material Pickup
                  {(outstandingMPs || []).length > 1 && <span>s</span>}
                </h5>

                {(outstandingMPs || []).length < 1 &&
                  <div className="text-muted" style={{ opacity: 0.5 }}>
                    <em>No Outstanding Material Pickups</em>
                  </div>}

                {(outstandingMPs || []).map(({ project, status, _id }, i) => {
                  const { address, city, state, zip } = project || {};
                  return (
                    <div key={i} className="box">
                      <div className="box-body" style={{ padding: '10px' }}>
                        <div style={{ float: 'right', marginLeft: '30px' }}>
                          <InspectionStatus status={status} hideIcon />
                        </div>
                        <Link to={`/material-pickups/${_id}`}>
                          {address} {city} {state} {zip}
                        </Link>
                      </div>
                    </div>
                  );
                })}

              </Col>
              <Col md={6}>

                <h5>
                  {(outstandingInspections || []).length > 0 &&
                    <span>{outstandingInspections.length}</span>}
                  {' '}
                  Outstanding Critical Failures
                  {(outstandingInspections || []).length > 1 && <span>s</span>}
                </h5>

                {(outstandingInspections || []).length < 1 &&
                  <div className="text-muted" style={{ opacity: 0.5 }}>
                    <em>No Outstanding Critical Failures</em>
                  </div>}

                {(outstandingInspections || []).map(({
                  project,
                  status,
                  _id
                }, i) => {
                  const { address, city, state, zip } = project || {};
                  return (
                    <div key={i} className="box">
                      <div className="box-body" style={{ padding: '10px' }}>
                        <div style={{ float: 'right', marginLeft: '30px' }}>
                          <InspectionStatus status={status} hideIcon />
                        </div>
                        <Link to={`/quality-assurance/inspection/${_id}`}>
                          {address} {city} {state} {zip}
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </div>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getDashboardStats.toJS();
  return {
    inProgress,
    result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getDashboardStats: fetchDux.getDashboardStats.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Homepage);
