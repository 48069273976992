import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Modal, Button } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const FormResumeInspection = props => {
  const {
    handleSubmit,
    address,
    city,
    state,
    zip,
    submitting,
    closeModal
  } = props;

  return (
    <form onSubmit={handleSubmit}>

      <Modal.Body>
        <p>Are you sure you wish to resume inspection at the address below?</p>
        <label className="form-label">
          Address
        </label>
        <p>
          {address || ''},<br />
          {city || ''} {zip || ''}, {state || ''}
        </p>

        <Field
          name="scheduledDate"
          type="text"
          component={FormField.Datepicker}
          label="Inspection Date"
          validate={[required]}
        />

        <Field
          name="comments"
          type="text"
          component={FormField.Textarea}
          label="Comments"
          rows={5}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="success" type="submit" disabled={submitting}>
          Resume Inspection
        </Button>
        <Button
          variant="outline-secondary"
          onClick={closeModal}
          disabled={submitting}
        >
          Cancel
        </Button>
      </Modal.Footer>

    </form>
  );
};

export default reduxForm({
  form: 'resume-inspection'
})(FormResumeInspection);
