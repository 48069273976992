import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Nav, Button, Navbar } from 'react-bootstrap';
import { actionCreators } from '../../state/modals-dux';

import NavItemLink from './NavItemLink';
import NavbarBrandLink from './NavbarBrandLink';
import LogoutModal from '../routes/login/LogoutModal';
import { useState } from 'react';
import fetchDux from '../../state/fetch-dux';
import queryString from 'query-string';

const SideNav = (
  { user, showModal, getSidNavInspections, sideNavInspections }
) => {
  const [initialMount, setInitialMount] = useState(true);
  useEffect(
    () => {
      if (initialMount) {
        const qs = queryString.stringify({
          sortBy: '_id',
          status: 'failed,criticalFailed',
          pagination: false
        });
        getSidNavInspections(qs);
        setInitialMount(false);
      }
    },
    [initialMount]
  );
  const { roleId } = user || {};
  const isAdmin = roleId === 'administrator';
  const { inspections } = sideNavInspections || {};
  const critialFailedInspections = (inspections || [])
    .filter(a => a.status === 'criticalFailed');
  const failedInspections = (inspections || [])
    .filter(a => a.status === 'failed');
  return (
    <Navbar className="top-nav" expand="lg" fixed="top">

      <NavbarBrandLink to="/">
        Four Seasons Heating Inc.
      </NavbarBrandLink>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavItemLink to="/">
            Dashboard
          </NavItemLink>
          <NavItemLink to="/addresses">
            Addresses
          </NavItemLink>
          <NavItemLink to="/material-pickups">
            Material Pickups
          </NavItemLink>
          <NavItemLink to="/quality-assurance">
            Quality Assurance
          </NavItemLink>
          <NavItemLink to="/quality-assurance/inspections">
            Inspections
          </NavItemLink>

          {failedInspections &&
            failedInspections.length > 0 &&
            <NavItemLink
              to="/quality-assurance/inspections/failures"
              className="failure"
            >
              Failures
              {' '}
              <span className="badge badge-danger">
                {failedInspections.length}
              </span>
            </NavItemLink>}

          {critialFailedInspections &&
            critialFailedInspections.length > 0 &&
            <NavItemLink
              to="/quality-assurance/inspections/critical-failures"
              className="failure"
            >
              Critial Failures
              {' '}
              <span className="badge badge-danger">
                {critialFailedInspections.length}
              </span>
            </NavItemLink>}

          {isAdmin &&
            <NavItemLink to="/admin/users/new">
              Create New User
            </NavItemLink>}

          {isAdmin &&
            <NavItemLink to="/admin">
              Admin
            </NavItemLink>}

        </Nav>
        {user &&
          <Nav>
            <Navbar.Text className="user">
              {user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName.charAt(0)}.`
                : user.email}
              <Button
                variant="outline-secondary"
                onClick={() => showModal('LogoutModal')}
              >
                Logout
              </Button>
            </Navbar.Text>
          </Nav>}
      </Navbar.Collapse>

      <LogoutModal />

    </Navbar>
  );
};

const mapState = state => {
  const {
    result: sideNavInspections
  } = state.getSidNavInspections.toJS();
  const user = state.login.toJS().result;
  return { user, sideNavInspections };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getSidNavInspections: fetchDux.getSidNavInspections.createAction,
      ...actionCreators
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(SideNav);
