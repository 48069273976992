import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
// import Map from '../../ui/Map';
import Icon from '../../ui/Icon';
import Loading from '../../ui/Loading';
import Notification from '../../ui/Notification';

import { bindActionCreators } from 'redux';
import fetchDux from '../../../state/fetch-dux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';

import ModalDeleteProject from './ModalDeleteProject';

const getLabel = ({ type }) => {
  if (type === 'materialPickup') return 'Material Pickup';
  if (type === 'roughInspection') return 'Rough Inspection';
  if (type === 'finalInspection') return 'Final Inspection';
  return '';
};

const getIconName = ({ type }) => {
  if (type === 'materialPickup') return 'truck';
  if (type === 'roughInspection') return 'hammer';
  if (type === 'finalInspection') return 'oven';
  return '';
};

const getLink = ({ type, _id }) => {
  if (type === 'materialPickup') return `/material-pickups/${_id}`;
  if (type === 'roughInspection' || type === 'finalInspection')
    return `/quality-assurance/inspection/${_id}`;
  return '';
};

const getStatusClass = ({ status }) => {
  if (status === 'pending') return 'info';
  if (status === 'denied') return 'failed';
  if (status === 'completed' || status === 'passed') return 'passed';
  if (status === 'failed' || status === 'rejected') return 'failed';
  return '';
};

const getStatusLabel = ({ status }) => {
  if (status === 'pending') return 'Pending';
  if (status === 'completed') return 'Completed';
  if (status === 'denied') return 'Unable To Inspect';
  if (status === 'failed' || status === 'rejected') return 'Failed';
  if (status === 'passed') return 'Passed';
  return '';
};

class AddressDetail extends Component {
  componentDidMount() {
    const {
      getProjectById,
      match: { params: { id } }
    } = this.props;
    getProjectById(id);
  }
  componentWillUnmount() {
    const {
      clearGetProjectById,
      clearDeleteProjectById
    } = this.props || {};

    clearGetProjectById();
    clearDeleteProjectById();
  }
  render() {
    const {
      result,
      inProgress,
      showModal,
      deleteProjectById,
      history,
      clearGetProjectById,
      clearDeleteProjectById,
      errorDeleteProjectById,
      errorGetProjectById
    } = this.props;
    const {
      project,
      inspections
    } = result || {};

    const {
      _id,
      name,
      address,
      city,
      state,
      zip,
      builder,
      fieldSupervisor,
      workOrderNo,
      lat,
      lng,
      dateMaterialsScheduled,
      partialMatch
    } = project || {};

    const {
      name: builderName
    } = builder || {};

    const {
      firstName: fieldSupervisorFirstName,
      lastName: fieldSupervisorLastName
    } = fieldSupervisor || {};

    const fieldSupervisorName = `${fieldSupervisorFirstName || ''} ${fieldSupervisorLastName || ''}`;

    const handleDelete = deleteReason => {
      deleteProjectById(
        { id: _id, payload: { deleteReason } },
        'delete-project',
        null,
        () => {
          history.push(`/addresses/`);
        }
      );
    };

    return (
      <Layout route="address-detail">

        <div className="layout-header">
          <div>
            <h1>
              Address
              {' '}
              {name && <span>{`${name}, ${address}, ${city}, ${state}`}</span>}
            </h1>
            <Link to="/addresses">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
          <div>
            <Link
              className="btn btn-primary btn-icon"
              to={`/addresses/${_id}/edit`}
            >
              Edit <Icon name="wrench" />
            </Link>
            <Button
              className="btn btn-danger btn-icon"
              onClick={() => showModal('DeleteModal')}
            >
              Delete <Icon name="times" />
            </Button>
          </div>
        </div>

        {inProgress && <Loading fullPage message="Loading, please wait.." />}

        {!inProgress &&
          <div>
            <Row>
              <Col md={12} xl={8}>
                <Row>

                  <Col md={6}>

                    <div className="box">
                      <div className="box-header">
                        <h3>
                          Address
                          {partialMatch &&
                            <span style={{ color: 'red' }}>
                              {' '}(Partial Match)
                            </span>}
                        </h3>
                      </div>
                      <div className="box-body">
                        <table className="table data-table">
                          <tbody>
                            <tr>
                              <th>Address</th>
                              <td>{address}</td>
                            </tr>
                            <tr>
                              <th>City</th>
                              <td>{city}</td>
                            </tr>
                            <tr>
                              <th>State</th>
                              <td>{state}</td>
                            </tr>
                            <tr>
                              <th>Zip</th>
                              <td>{zip}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </Col>
                  <Col md={6}>

                    <div className="box">
                      <div className="box-header">
                        <h3>Project</h3>
                      </div>
                      <div className="box-body">
                        <table className="table data-table">
                          <tbody>
                            <tr>
                              <th>Work Order No.</th>
                              <td>{workOrderNo}</td>
                            </tr>
                            <tr>
                              <th>Project Name</th>
                              <td>{name}</td>
                            </tr>
                            <tr>
                              <th>Builder</th>
                              <td>{builderName}</td>
                            </tr>
                            <tr>
                              <th>Field Supervisor</th>
                              <td>{fieldSupervisorName}</td>
                            </tr>
                            <tr>
                              <th>Material scheduled</th>
                              <td>
                                {dateMaterialsScheduled &&
                                  moment(dateMaterialsScheduled).isValid()
                                  ? moment(dateMaterialsScheduled).format(
                                      'MM/DD/YYYY'
                                    )
                                  : ''}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </Col>
                </Row>

                {(inspections || []).length > 0 &&
                  <div className="box box-reports">

                    <h5>Reports</h5>
                    {(inspections || []).map(inspection => {
                      const {
                        _id,
                        type,
                        status,
                        dateCreated
                      } = inspection || {};

                      const label = getLabel({ type });
                      const iconName = getIconName({ type });
                      const link = getLink({ type, _id });

                      const statusClass = getStatusClass({ status });
                      const statusLabel = getStatusLabel({ status });

                      return (
                        <div className={`report ${statusClass}`}>
                          <div className="symbol">
                            <Icon prefix="fal" name={iconName} fixedWidth />
                          </div>
                          <strong>{label} <span>{statusLabel}</span></strong>
                          <div className="right">
                            <span className="date">
                              {moment(dateCreated).format('MM/DD/YYYY')}
                            </span>
                            <Link
                              to={link}
                              className="btn btn-outline-secondary btn-sm"
                            >
                              View
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>}

              </Col>
              <Col md={6} xl={4}>

                {/* <Map geocodes={lat && lng ? [{ lat, lng }] : []} squareSize /> */}

              </Col>
            </Row>
          </div>}

        <ModalDeleteProject
          name="DeleteModal"
          handleDelete={handleDelete}
          projectName={name}
        />

        {errorGetProjectById &&
          <Notification
            key="get-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetProjectById}
          >
            Error loading project details.
          </Notification>}
        {errorDeleteProjectById &&
          <Notification
            key="get-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearDeleteProjectById}
          >
            Error deleting project.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress,
    error: errorGetProjectById
  } = state.getProjectById.toJS();
  const {
    error: errorDeleteProjectById
  } = state.deleteProjectById.toJS();
  return {
    result,
    inProgress,
    errorDeleteProjectById,
    errorGetProjectById
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getProjectById: fetchDux.getProjectById.createAction,
      clearGetProjectById: fetchDux.getProjectById.clearAction,
      deleteProjectById: fetchDux.deleteProjectById.createAction,
      clearDeleteProjectById: fetchDux.deleteProjectById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(AddressDetail);
