import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import UserGroupsSearch from './UserGroupsSearch.js';
import UserGroupsNew from './UserGroupsNew.js';
import UserGroupsDetail from './UserGroupsDetail.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={UserGroupsSearch} />
    <Route exact path={`${match.url}/new`} component={UserGroupsNew} />
    <Route exact path={`${match.url}/:id`} component={UserGroupsDetail} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
