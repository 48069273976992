import React from 'react';
import classNames from 'classnames';
import Icon from '../../ui/Icon';
import { Button } from 'react-bootstrap';

const checkPassed = (group, values) => {
  const { fields } = group || {};

  const { other, disabled, fields: formFields = {} } = values || {};

  if (disabled)
    return {
      passed: true,
      failed: false,
      criticalFailed: false
    };

  const otherPassed = (other || [])
    .every(({ value }) => value === 'passed' || value === 'na');

  const otherResolved = (other || [])
    .every(
      ({ value, resolved }) => value === 'passed' || value === 'na' || resolved
    );

  const otherFailed = (other || []).some(({ value }) => value === 'failed');

  const passed = (fields || []).every(({ name }) => {
    const { value } = formFields[name] || {};
    return value === 'passed' || value === 'na';
  });

  const resolved = (fields || []).every(({ name }) => {
    const { value, resolved } = formFields[name] || {};
    return value === 'passed' || value === 'na' || resolved;
  });

  const failed = (fields || []).some(({ name }) => {
    const { value } = formFields[name] || {};
    return value === 'failed';
  });
  const criticalFailed = (fields || [])
    .filter(({ criticalFail }) => criticalFail)
    .some(({ name }) => {
      const { value } = formFields[name] || {};
      return value === 'failed';
    });

  return {
    passed: passed && otherPassed,
    resolved: resolved && otherResolved,
    failed: failed || otherFailed,
    criticalFailed
  };
};

export const InspectionReportGroup = (
  { checklist, showResolve, openResolveModal, values }
) => {
  const { label, status, fields } = checklist || {};
  const { disabled, other, fields: fieldValues = {} } = values || {};

  const {
    passed,
    resolved,
    failed,
    criticalFailed
  } = checkPassed(checklist, values);
  return (
    <div
      className={classNames('box box-inspection-report', {
        disabled,
        failed: failed && !resolved && !disabled,
        passed: (passed || resolved) && !disabled
      })}
    >
      <div
        className={classNames('box-header', {
          disabled,
          failed: failed && !resolved && !disabled,
          passed: (passed || resolved) && !disabled
        })}
      >
        <h3>
          {label}
          {disabled && <small>N/A</small>}
          {(passed || resolved) &&
            !disabled &&
            <Icon fixedWidth name="check" className="text-success" />}
          {failed &&
            !disabled &&
            !resolved &&
            <Icon fixedWidth name="times" className="text-danger" />}
        </h3>
      </div>
      <div className="box-body">
        <table className="table data-table">
          <tbody>
            {(fields || []).map((item, ii) => {
              const { name } = item || {};
              const current = fieldValues[name] || {};
              const {
                value,
                resolved,
                comment,
                commentAuthor,
                resolvedComments,
                resolvedCommentsAuthor
              } = current || {};
              return (
                <tr
                  key={ii}
                  className={classNames({
                    failed: !disabled && !resolved && value === 'failed',
                    passed: !disabled && (resolved || value === 'passed'),
                    disabled: disabled || value === 'na'
                  })}
                >
                  <td>
                    <div>
                      <strong>{item.label}</strong>
                      <div>
                        {!disabled &&
                          resolved &&
                          value !== 'passed' &&
                          <Icon
                            name="clock"
                            fixedWidth
                            className="text-success"
                          />}
                        {!disabled &&
                          value === 'passed' &&
                          <Icon
                            name="check"
                            fixedWidth
                            className="text-success"
                          />}
                        {!disabled &&
                          !showResolve &&
                          !resolved &&
                          value === 'failed' &&
                          <Icon
                            name="times"
                            fixedWidth
                            className="text-danger"
                          />}
                        {!disabled &&
                          showResolve &&
                          !resolved &&
                          value === 'failed' &&
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() =>
                              openResolveModal({
                                isOther: false,
                                itemName: name
                              })}
                          >
                            Resolve
                          </Button>}
                        {(disabled || value === 'na') && <small>N/A</small>}
                        {/*!disabled && !value && <small>-</small>*/}
                      </div>
                    </div>
                    {comment &&
                      <div className="comment">
                        {commentAuthor &&
                          <div className="author">{commentAuthor}</div>}
                        {comment}
                      </div>}
                    {resolvedComments &&
                      <div className="comment">
                        {resolvedCommentsAuthor &&
                          <div className="author">
                            {resolvedCommentsAuthor}
                          </div>}
                        {resolvedComments}
                      </div>}
                  </td>
                </tr>
              );
            })}
            {(other || []).map((item, ii) => {
              const { name, value, comment, commentAuthor } = item || {};
              // const current = fieldValues[name] || {};
              // const {
              //   value,
              //   comment
              // } = current || {};
              return (
                <tr
                  key={ii}
                  className={classNames({
                    failed: !disabled && value === 'failed',
                    passed: !disabled && value === 'passed',
                    disabled: disabled || value === 'na'
                  })}
                >
                  <td>
                    <div>
                      <strong>{name}</strong>
                      <div>
                        {!disabled &&
                          value === 'passed' &&
                          <Icon
                            name="check"
                            fixedWidth
                            className="text-success"
                          />}
                        {!disabled &&
                          !showResolve &&
                          value === 'failed' &&
                          <Icon
                            name="times"
                            fixedWidth
                            className="text-danger"
                          />}
                        {!disabled &&
                          showResolve &&
                          value === 'failed' &&
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() =>
                              openResolveModal({ isOther: true, itemName: ii })}
                          >
                            Resolve
                          </Button>}
                        {(disabled || value === 'na') && <small>N/A</small>}
                        {!disabled && !value && <small>-</small>}
                      </div>
                    </div>
                    {comment &&
                      <div className="comment">
                        {commentAuthor &&
                          <div className="author">{commentAuthor}</div>}
                        {comment}
                      </div>}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InspectionReportGroup;
