import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Table from '../../ui/Table';
import moment from 'moment';

import Icon from '../../ui/Icon';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';

const addresses = [];

const columns = [
  {
    Header: 'Date',
    accessor: 'dateCreated',
    Cell: ({ value }) =>
      value && moment(value).isValid()
        ? moment(value).format('MM/DD/YYYY hh:mm a')
        : ''
  },
  {
    Header: 'Message',
    accessor: 'message'
  },
  {
    Header: 'User',
    accessor: 'user',
    Cell: ({ value }) => {
      const {
        firstName,
        lastName
      } = value || {};

      return `${firstName} ${lastName}`;
    }
  }
];

class AddressLogs extends Component {
  componentDidMount() {
    const { getProjectLogsById, match: { params: { id } } } = this.props;
    getProjectLogsById(id);
  }
  render() {
    const {
      result
    } = this.props;
    const {
      _id,
      name: projectName,
      logs
    } = result || {};
    return (
      <Layout route="addresses-detail">

        <div className="layout-header">
          <div>
            <h1>Project <span>{projectName}</span></h1>
            <Link to={`/addresses/${_id}`}>
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <div className="box box-smart-table">
              <div className="box-header">
                <h3>Logs</h3>
              </div>
              <Table
                showExport
                data={logs || []}
                columns={columns}
                noDataText="No Logs Found"
              />
            </div>

          </Col>
        </Row>

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result
  } = state.getProjectLogsById.toJS();
  return {
    result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProjectLogsById: fetchDux.getProjectLogsById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(AddressLogs);
