import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';
import { getProjects } from '../../../api/api';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const handleLoadOptions = async (value, next) => {
  try {
    if (value.length < 3) {
      next([]);
      return;
    }
    const data = await getProjects(`search=${value}`);
    const { projects } = data || {};
    const options = (projects || []).map(({
      address,
      city,
      state,
      zip,
      _id,
      name,
      builderName,
      fieldSupervisorName,
      fieldSupervisorId
    }) => ({
      value: _id,
      label: `${address} ${city} ${state} ${zip}`,
      name,
      builderName,
      fieldSupervisorName,
      fieldSupervisorId
    }));
    next(options);
  } catch (err) {
    next([]);
  }
};

const getGroupedOptions = ({ assigneeOptions, project }) => {
  const {
    fieldSupervisorId,
    fieldSupervisorName
  } = project || {};

  if (!fieldSupervisorId) {
    return [
      {
        label: 'QA Technicians',
        options: assigneeOptions || []
      }
    ];
  }

  return [
    {
      label: 'QA Technicians',
      options: assigneeOptions || []
    },
    {
      label: 'Field Supervisor',
      options: [
        {
          value: fieldSupervisorId,
          label: fieldSupervisorName
        }
      ]
    }
  ];
};

const FormNewInspection = props => {
  const {
    handleSubmit,
    submitting,
    assigneeOptions,
    project
  } = props;
  const qaTechnicianOptions = getGroupedOptions({ assigneeOptions, project });
  const typeOptions = [
    { label: 'Rough Inspection', value: 'roughInspection' },
    { label: 'Final Inspection', value: 'finalInspection' }
  ];

  return (
    <div className="box box-form">
      <form onSubmit={handleSubmit}>

        <Row>
          <Col md={12} lg={6}>
            <Field
              name="project"
              type="text"
              component={FormField.SelectReactAsync}
              label="Pickup Address"
              validate={[required]}
              loadOptions={handleLoadOptions}
              errorMessage="Required"
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="type"
              type="text"
              component={FormField.SelectReact}
              label="Inspection Type"
              validate={[required]}
              options={typeOptions || []}
            />

          </Col>
        </Row>
        <Row>
          <Col md={6} lg={3}>

            <Field
              grouped
              name="technicianId"
              type="text"
              component={FormField.SelectReact}
              label="Quality Assurance Technician"
              options={qaTechnicianOptions || []}
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="scheduledDate"
              type="text"
              component={FormField.Datepicker}
              label="Inspection Date"
            // validate={[required]}
            />

          </Col>
          {/* <Col md={6} lg={3}>

            <Field
              name="mustInspectBy"
              type="text"
              component={FormField.Datepicker}
              label="Must Inspect By"
              validate={[required]}
            />

          </Col> */}
        </Row>

        <Row>
          <Col md={6} lg={3}>

            <Field
              name="project.name"
              type="text"
              component={FormField.Input}
              label="Project"
              validate={[required]}
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="project.builderName"
              type="text"
              component={FormField.Input}
              label="Builder"
              validate={[required]}
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="project.fieldSupervisorName"
              type="text"
              component={FormField.Input}
              label="Field Supervisor"
              validate={[required]}
              disabled
            />

          </Col>
        </Row>

        <Field
          name="comments"
          type="text"
          component={FormField.Textarea}
          label="Comments"
          rows={5}
        />

        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Request Inspection
          </Button>
        </div>

      </form>
    </div>
  );
};

const formName = 'new-rough-inspection';
const form = reduxForm({
  form: formName
})(FormNewInspection);

const selector = formValueSelector(formName);

const mapState = state => {
  const project = selector(state, 'project');
  return {
    project
  };
};

export default connect(mapState)(form);
