import React from 'react';
import { connect } from 'react-redux';
import {
    change,
    Field,
    formValueSelector,
    getFormValues,
    reduxForm
} from 'redux-form';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import FormField from '../../forms/FormField';
import { bindActionCreators } from 'redux';
const required = value => value ? undefined : 'Required';

const FormPickUpCompletionAck = props => {
    const {
        handleSubmitWithAck,
        formValues,
        handleChage,
        fullComplete,
        partialComplete,
        valid,
        ackComments,
        postMaterialPickUpLoading
    } = props;
    const disableButton = (typeof fullComplete === 'undefined' &&
        typeof partialComplete === 'undefined') ||
        (fullComplete === false && partialComplete === false) ||
        !valid ||
        postMaterialPickUpLoading;
    return (
        <form>
            <Modal.Body>
                <Field
                    name="fullComplete"
                    label="I certify that the rough install at this address is complete and ready for inspection"
                    type="checkbox"
                    component={FormField.InputToggle}
                    onChange={() => {
                        handleChage('pickUpCompletionAckForm', 'partialComplete', false);
                    }}
                    value={fullComplete}
                />

                <Field
                    name="partialComplete"
                    label="I certify that the rough install at this address is mostly complete and ready for inspection"
                    type="checkbox"
                    component={FormField.InputToggle}
                    onChange={() =>
                        handleChage('pickUpCompletionAckForm', 'fullComplete', false)}
                    value={partialComplete}
                />

                {partialComplete &&
                    <Field
                        name="ackComments"
                        type="text"
                        component={FormField.Textarea}
                        label="Comments"
                        autoCapitalize="none"
                        rows={5}
                        validate={[required]}
                        required
                    />}

            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={() => {
                        handleSubmitWithAck({
                            ...formValues,
                            fullComplete,
                            partialComplete,
                            ackComments
                        });
                    }}
                    disabled={disableButton}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </form>
    );
};

const form = reduxForm({
    form: 'pickUpCompletionAckForm'
})(FormPickUpCompletionAck);

const selector = formValueSelector('pickUpCompletionAckForm');

const mapState = state => {
    const fullComplete = selector(state, 'fullComplete');
    const partialComplete = selector(state, 'partialComplete');
    const ackComments = selector(state, 'ackComments');
    return {
        formValues: getFormValues('new-material-pickup')(state),
        fullComplete,
        partialComplete,
        ackComments
    };
};

const mapDispatch = dispatch =>
    bindActionCreators(
        {
            handleChage: change
        },
        dispatch
    );

export default connect(mapState, mapDispatch)(form);
