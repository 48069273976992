export const ROLE_OPTIONS = [
  {
    label: 'Administrator',
    value: 'administrator'
  },
  {
    label: 'Field Supervisor',
    value: 'fieldSupervisor'
  },
  {
    label: 'Material Pickup Technician',
    value: 'materialPickupTechnician'
  },
  {
    label: 'Quality Assurance Technician',
    value: 'qualityAssuranceTechnician'
  },
  {
    label: 'Quality Assurance Supervisor',
    value: 'qualityAssuranceSupervisor'
  },
  {
    label: 'Warehouse Technician',
    value: 'warehouseTechnician'
  },
  {
    label: 'Warehouse Supervisor',
    value: 'warehouseSupervisor'
  },
  {
    label: 'Final Technician',
    value: 'finalTechnician'
  }
];
