import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const LoginForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <div className="box">
      <form onSubmit={handleSubmit}>
        <Field
          name="username"
          type="text"
          component={FormField.Input}
          label="Username"
          validate={[required]}
          autoCapitalize="none"
        />
        <Field
          name="password"
          type="password"
          component={FormField.Input}
          label="Password"
          validate={[required]}
        />
        <div>
          <Button variant="primary" type="submit" disabled={submitting}>
            Sign In
          </Button>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'login'
})(LoginForm);
