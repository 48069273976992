import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../../ui/Layout';

import Icon from '../../../ui/Icon';
import FormNewUserGroup from './FormNewUserGroup';

export const UserGroupsNew = ({ postNewUserGroup }) => {
  return (
    <Layout route="user-groups-new">

      <div className="layout-header">
        <div>
          <h1>Create New User Group</h1>
          <Link to="/admin/user-groups">
            <Icon name="angle-left" /> Back
          </Link>
        </div>
      </div>

      <Row>
        <Col md={6} xl={5}>
          <FormNewUserGroup onSubmit={postNewUserGroup} />
        </Col>
      </Row>

    </Layout>
  );
};

const mapState = state => {
  return {
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {},
    dispatch
  );

export default connect(mapState, mapDispatch)(UserGroupsNew);
