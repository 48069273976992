import React, { Component } from 'react';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
// import Map from '../../ui/Map';
import MaterialPickupsCalendar from '../../ui/MaterialPickupsCalendar';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';

class MaterialPickupsCalendarView extends Component {
  render() {
    const {
      result,
      getMaterialPickups
    } = this.props;

    const {
      inspections
    } = result || {};

    const locations = (inspections || [])
      .map(pickup => {
        const {
          project,
          allowEarlyPickup
        } = pickup || {};
        const {
          lat,
          lng
        } = project || {};
        const iconStyle = allowEarlyPickup ? 'green' : 'blue';
        return lat && lng ? { lat, lng, iconStyle } : {};
      })
      .filter(({ lat, lng }) => lat && lng);
    return (
      <Layout route="material-pickups-calendar">

        <div className="layout-header">
          <div>
            <h1>Material Pickups</h1>
          </div>
          <div>
            <Link
              to="/material-pickups/new"
              className="btn btn-success btn-icon"
            >
              Request Material Pickup <Icon name="plus" />
            </Link>
          </div>
        </div>

        {/*
          <Map geocodes={locations} />
        */}

        <MaterialPickupsCalendar />

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result
  } = state.getMaterialPickups.toJS();
  return {
    result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getMaterialPickups: fetchDux.getMaterialPickups.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MaterialPickupsCalendarView);
