import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import { submit } from 'redux-form';

import Form from './FormResolveReportIssue';

const ResolveModal = (
  {
    openModal,
    closeModal,
    submitForm,
    handlePostResolve,
    inProgress
  }
) => {
  return (
    <Modal show={openModal === 'ResolveModal'} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Resolve Issue</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form onSubmit={handlePostResolve} />

      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={submitForm} disabled={inProgress}>
          Submit
        </Button>
        <Button
          variant="outline-secondary"
          onClick={closeModal}
          disabled={inProgress}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => {
  const {
    inProgress
  } = state.postResolveInspectionItem.toJS();
  return {
    inProgress,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      submitForm: () => submit('resolve-inspection-issue')
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ResolveModal);
