import React from 'react';
import moment from 'moment';
import CalendarRow from './CalendarRow';
import MonthHeader from './MonthHeader';

const getWeekRows = ({ week, year }) => {
  const startOfWeek = moment()
    .year(year)
    .week(week)
    .startOf('week')
    .startOf('day');

  const numDaysInWeek = 7;
  const startDay = startOfWeek.day();
  const numRows = 1;

  const weeks = new Array(numRows).fill(null);

  const c = weeks.map((w, idx) => {
    const z = new Array(7).fill(null).map((y, i) => {
      const trueIndex = i + idx * 7 - startDay;
      const disabled = trueIndex < 0 || trueIndex > numDaysInWeek - 1;

      const date = startOfWeek.clone().add(trueIndex, 'days').startOf('day');
      return {
        trueIndex,
        disabled,
        date
      };
    });

    return z;
  });

  return c;
};

const getMonthRows = ({ month, year }) => {
  const startOfMonth = moment()
    .month(month)
    .year(year)
    .startOf('month')
    .startOf('day');
  const numDaysInMonth = startOfMonth.daysInMonth();
  const startDay = startOfMonth.day();
  const numRows = Math.ceil(numDaysInMonth / 7);

  const weeks = new Array(numRows).fill(null);

  const c = weeks.map((w, idx) => {
    const z = new Array(7).fill(null).map((y, i) => {
      const trueIndex = i + idx * 7 - startDay;
      const disabled = trueIndex < 0 || trueIndex > numDaysInMonth - 1;

      const date = startOfMonth.clone().add(trueIndex, 'days').startOf('day');
      return {
        trueIndex,
        disabled,
        date
      };
    });

    return z;
  });

  return c;
};

const getRows = ({ month, year, week, view }) => {
  if (view === 'week') {
    return getWeekRows({ year, week });
  }
  return getMonthRows({ month, year, week });
};

const getWeekEvents = (days, events) => (events || []).filter(({
    startDate,
    endDate
  }) => {
    const firstDay = days[0].date;
    const lastDay = days[6].date;

    if (moment(startDate).isBefore(firstDay)) {
      return moment(endDate).isSameOrAfter(firstDay);
    }

    return moment(startDate).isSameOrBefore(lastDay);
  });

const getEndDate = ({ duration, includeWeekends, endDate, startDate }) => {
  if (endDate && moment(endDate).isValid()) return endDate;
  if (!duration) return null;
  if (includeWeekends) {
    return startDate.clone().add(duration - 1, 'days').toDate();
  }
  const businessDays = addBusinessDays(startDate, duration);
  return startDate.clone().add(businessDays - 1, 'days').toDate();
};

const addBusinessDays = (startDate, days) => {
  return new Array(days).fill(null).reduce(
    (prev, next, idx) => {
      const dayCode = startDate.clone().add(prev, 'days').day();
      if (dayCode === 6) return prev + 3;
      if (dayCode === 0) return prev + 2;
      return prev + 1;
    },
    0
  );
};

const getDates = ({ startDate, endDate }) => {
  const validEndDate = endDate && moment(endDate).isValid();
  const validStartDate = startDate && moment(startDate).isValid();

  if (validEndDate && validStartDate)
    return {
      startDate: moment(startDate).startOf('day').toDate(),
      endDate: moment(endDate).endOf('day').toDate()
    };

  if (validEndDate)
    return {
      startDate: moment(endDate).startOf('day').toDate(),
      endDate: moment(endDate).endOf('day').toDate()
    };
  if (validStartDate)
    return {
      startDate: moment(startDate).startOf('day').toDate(),
      endDate: moment(startDate).endOf('day').toDate()
    };
};

const formatEvents = ({ events, startDateField, endDateField }) => {
  return (events || []).map(ev => {
    const s = ev[startDateField || 'startDate']
    const e = ev[endDateField || 'endDate']
    const { duration, includeWeekends } = ev || {};
    const e2 = getEndDate({
      duration,
      includeWeekends,
      endDate: e,
      startDate: moment(s)
    });
    const {
      endDate,
      startDate
    } = getDates({ startDate: s, endDate: e2 });
    return { ...ev, endDate, startDate };
  });
};

const Calendar = (
  {
    events,
    week,
    month,
    year,
    handleDrop,
    handleCellClick,
    style,
    renderEvent,
    cellHeight,
    eventHeight,
    eventPadding,
    eventMargin,
    eventMarginTop,
    view,
    calendarClassName,
    startDateField,
    endDateField
  }
) => {
  const rows = getRows({ month, year, week, view });

  const formattedEvents = formatEvents({ events, startDateField, endDateField });

  return (
    <div className={calendarClassName} style={style || {}}>
      <MonthHeader />
      {(rows || [])
        .map((days, i) => (
          <CalendarRow
            key={i}
            cellHeight={cellHeight}
            eventHeight={eventHeight}
            eventPadding={eventPadding}
            eventMargin={eventMargin}
            eventMarginTop={eventMarginTop}
            days={days}
            handleDrop={handleDrop}
            events={getWeekEvents(days, formattedEvents)}
            handleCellClick={handleCellClick}
            renderEvent={renderEvent}
          />
        ))}
    </div>
  );
};

export default Calendar;
