import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import MaterialPickupsCalendarView from './MaterialPickupsCalendarView.js';
import MaterialPickupsGrid from './MaterialPickupsGrid.js';
import MaterialPickupsNew from './MaterialPickupsNew.js';
import MaterialPickupsDetail from './MaterialPickupsDetail.js';
import MaterialPickupsEdit from './MaterialPickupsEdit.js';
import MaterialPickupsCompletePickup from './MaterialPickupsCompletePickup.js';
import MaterialPickupsUnableToPickup from './MaterialPickupsUnableToPickup.js';
import MaterialPickupsReschedulePickup
  from './MaterialPickupsReschedulePickup.js';

export default ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}`}
      component={MaterialPickupsCalendarView}
    />
    <Route exact path={`${match.url}/grid`} component={MaterialPickupsGrid} />
    <Route exact path={`${match.url}/new`} component={MaterialPickupsNew} />
    <Route exact path={`${match.url}/:id`} component={MaterialPickupsDetail} />
    <Route
      exact
      path={`${match.url}/:id/complete-pickup`}
      component={MaterialPickupsCompletePickup}
    />
    <Route
      exact
      path={`${match.url}/:id/unable-to-pickup`}
      component={MaterialPickupsUnableToPickup}
    />
    <Route
      exact
      path={`${match.url}/:id/reschedule-pickup`}
      component={MaterialPickupsReschedulePickup}
    />
    <Route
      exact
      path={`${match.url}/:id/edit`}
      component={MaterialPickupsEdit}
    />
    <Redirect to={`${match.url}`} />
  </Switch>
);
