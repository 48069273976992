import React from 'react';

const getDayLabel = ({ dayOfWeek }) => {
  switch (dayOfWeek) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};

const MonthHeader = (
  {
    dayOfWeek
  }
) => {
  return (
    <div className="react-calendar-cell-headers">
      <div>
        Sun
      </div>
      <div>
        Mon
      </div>
      <div>
        Tues
      </div>
      <div>
        Wed
      </div>
      <div>
        Thurs
      </div>
      <div>
        Fri
      </div>
      <div>
        Sat
      </div>
    </div>
  );
};

export default MonthHeader;
