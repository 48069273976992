import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const getGroupedOptions = ({ assigneeOptions, project }) => {
  const {
    fieldSupervisorId,
    fieldSupervisorName
  } = project || {};

  if (!fieldSupervisorId) {
    return [
      {
        label: 'QA Technicians',
        options: assigneeOptions || []
      }
    ];
  }

  return [
    {
      label: 'QA Technicians',
      options: assigneeOptions || []
    },
    {
      label: 'Field Supervisor',
      options: [
        {
          value: fieldSupervisorId,
          label: fieldSupervisorName
        }
      ]
    }
  ];
};

const FormRescheduleInspection = props => {
  const {
    handleSubmit,
    submitting,
    assigneeOptions,
    project
  } = props;

  const qaTechnicianOptions = getGroupedOptions({ assigneeOptions, project });

  return (
    <div className="box box-form">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={12} lg={6}>
            <Field
              name="address"
              type="text"
              component={FormField.Input}
              label="Pickup Address"
              validate={[required]}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={3}>
            <Field
              grouped
              name="technicianId"
              type="text"
              component={FormField.SelectReact}
              label="Quality Assurance Technician"
              options={qaTechnicianOptions || []}
            />
          </Col>
          <Col md={6} lg={3}>
            <Field
              name="scheduledDate"
              type="text"
              component={FormField.Datepicker}
              label="Inspection Date"
              // validate={[required]}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={3}>

            <Field
              name="builder"
              type="text"
              component={FormField.Input}
              label="Builder"
              validate={[required]}
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="fieldSupervisor"
              type="text"
              component={FormField.Input}
              label="Field Supervisor"
              validate={[required]}
              disabled
            />

          </Col>
        </Row>

        <Field
          name="comments"
          type="text"
          component={FormField.Textarea}
          label="Comments"
          rows={5}
        />

        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Reschedule Inspection
          </Button>
        </div>

      </form>
    </div>
  );
};

const formName = 'reschedule-inspection';
const form = reduxForm({
  form: formName
})(FormRescheduleInspection);

const selector = formValueSelector(formName);

const mapState = state => {
  return {};
};

export default connect(mapState)(form);
