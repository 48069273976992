import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import Camera from '../../ui/Camera';

const required = value => value ? undefined : 'Required';

const FormResolveReportIssue = props => {
  const { handleSubmit, files, valid } = props;
  return (
    <form onSubmit={handleSubmit}>

      <Field
        name="comments"
        type="text"
        component={FormField.Textarea}
        label="Comments"
        rows={5}
        validate={[required]}
      />

      <Camera
        files={files}
        required
        hideView
      />
    </form>
  );
};

const formName = 'resolve-inspection-issue';

const form = reduxForm({
  form: formName
})(FormResolveReportIssue);

const selector = formValueSelector(formName);

const mapState = state => {
  const files = selector(state, 'files');
  return {
    files
  };
};

export default connect(mapState)(form);
