import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../state/fetch-dux';

import SecureRoute from './ui/SecureRoute';
import Homepage from './routes/homepage/Homepage';
import Addresses from './routes/addresses/Addresses';
import MaterialPickups from './routes/material-pickups/MaterialPickups';
import QualityAssurance from './routes/quality-assurance/QualityAssurance';
import Admin from './routes/admin/Admin';
import Login from './routes/login/Login';

class App extends Component {
  componentDidMount() {
    this.props.validateLogin();
  }
  render() {
    return (
      <Router>
        <LastLocationProvider>
          <Switch>
            <SecureRoute path="/" component={Homepage} exact />
            <SecureRoute path="/addresses" component={Addresses} />
            <SecureRoute path="/material-pickups" component={MaterialPickups} />
            <SecureRoute
              path="/quality-assurance"
              component={QualityAssurance}
            />
            <SecureRoute path="/admin" component={Admin} isAdminRoute />
            <Route path="/login" component={Login} exact />
          </Switch>
        </LastLocationProvider>
      </Router>
    );
  }
}

const mapState = state => ({});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      validateLogin: fetchDux.validateLogin.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(App);
