import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';

import Icon from '../../../ui/Icon';

import ModalEditUserGroup from './ModalEditUserGroup';
import ModalDeleteUserGroup from './ModalDeleteUserGroup';

export const UserGroupsDetail = ({ showModal }) => {
  return (
    <Layout route="user-groups-detail">

      <div className="layout-header">
        <div>
          <h1>User Group <span>name</span></h1>
          <Link to="/admin/user-groups">
            <Icon name="angle-left" /> Back
          </Link>
        </div>
        <div>
          <Button
            className="btn btn-primary btn-icon"
            onClick={() => showModal('EditUserGroupModal')}
          >
            Edit User Group <Icon name="wrench" />
          </Button>
          <Button
            className="btn btn-danger btn-icon"
            onClick={() => showModal('DeleteUserGroupModal')}
          >
            Delete User Group <Icon name="trash" />
          </Button>
        </div>
      </div>

      <Row>
        <Col md={6} xl={5}>
          <div className="box">
            <div className="box-header">
              <h3>Details</h3>
            </div>
            <div className="box-body">
              <table className="table data-table">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td />
                  </tr>
                  <tr>
                    <th>Privileges</th>
                    <td />
                  </tr>
                  <tr>
                    <th>Users</th>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>

      <ModalDeleteUserGroup name="DeleteUserGroupModal" />
      <ModalEditUserGroup name="EditUserGroupModal" />

    </Layout>
  );
};

const mapState = state => {};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(UserGroupsDetail);
