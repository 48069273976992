import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Notification from '../../../ui/Notification';
import Table from '../../../ui/Table';
import InspectionStatus from '../../quality-assurance/InspectionStatus';

import ModalEditBuilder from './ModalEditBuilder';
import ModalDeleteBuilder from './ModalDeleteBuilder';
import fetchDux from '../../../../state/fetch-dux';

const columns = [
  {
    Header: 'Work Order No.',
    accessor: 'workOrderNo',
    show: false
  },
  {
    Header: 'Address',
    accessor: 'address'
  },
  {
    Header: 'City',
    accessor: 'city'
  },
  {
    Header: 'ZIP',
    accessor: 'zip',
    show: false
  },
  {
    Header: 'State',
    accessor: 'state',
    show: false
  },
  {
    Header: 'Project Name',
    accessor: 'name',
    Cell: ({ value, original: row }) => (
      <Link to={`/addresses/${row._id}`}>{value}</Link>
    )
  },
  {
    Header: 'Home Plan Number',
    accessor: 'homePlanNo'
  },
  {
    Header: 'Field Supervisor',
    accessor: 'fieldSupervisor.lastName',
    Cell: ({ original: row }) => {
      const { fieldSupervisor } = row || {};
      if (!fieldSupervisor) return '';
      const {
        firstName,
        lastName
      } = fieldSupervisor || {};
      return `${firstName} ${lastName}`;
    }
  },
  {
    Header: 'Days Materials on Site',
    accessor: 'MPDays'
  },
  {
    Header: 'Material Pickup',
    accessor: 'materialPickupStatus',
    Cell: ({ value }) => <InspectionStatus status={value} />
  },
  {
    Header: 'Rough Inpsection',
    accessor: 'roughInspectionStatus',
    Cell: ({ value }) => <InspectionStatus status={value} />
  },
  {
    Header: 'Final Inspection',
    accessor: 'finalInspectionStatus',
    Cell: ({ value }) => <InspectionStatus status={value} />
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
    show: false,
    Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
  }
];

class BuildersDetail extends Component {
  componentDidMount() {
    const {
      getBuilderById,
      match: { params: { id } }
    } = this.props;

    getBuilderById(id);
  }
  componentWillUnmount() {
    const {
      clearPutBuilder,
      clearDeleteBuilder
    } = this.props || {};

    clearPutBuilder();
    clearDeleteBuilder();
  }
  render() {
    const {
      showModal,
      builder,
      addresses,
      putBuilder,
      clearPutBuilder,
      putBuilderError,
      putBuilderInProgress,
      deleteBuilder,
      clearDeleteBuilder,
      deleteBuilderError,
      deleteBuilderInProgress,
      getBuilderById,
      history,
      inProgress
    } = this.props;
    const {
      address,
      city,
      name,
      state,
      zip,
      _id
    } = builder || {};

    const handleBuilderSubmit = values => {
      const {
        address,
        city,
        name,
        state,
        zip
      } = values || {};
      const payload = {
        address,
        city,
        name,
        state,
        zip
      };

      putBuilder({ id: _id, payload }, 'edit-Builder', null, data => {
        getBuilderById(_id);
      });
    };

    const handleDeleteBuilderSubmit = () => {
      deleteBuilder(_id, null, null, data => {
        history.push('/admin/builders');
      });
    };

    return (
      <Layout route="builders-detail">

        <div className="layout-header">
          <div>
            <h1>Builder <span>{name}</span></h1>
            <Link to="/admin/builders">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
          <div>
            <Button
              className="btn btn-primary btn-icon"
              onClick={() => showModal('EditBuilderModal')}
            >
              Edit Builder <Icon name="wrench" />
            </Button>
            <Button
              className="btn btn-danger btn-icon"
              onClick={() => showModal('DeleteBuilderModal')}
            >
              Delete Builder <Icon name="trash" />
            </Button>
          </div>
        </div>

        <Row>
          <Col md={6} xl={5}>

            <div className="box">
              <div className="box-header">
                <h3>Details</h3>
              </div>
              <div className="box-body">
                <table className="table data-table">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{name}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{address}</td>
                    </tr>
                    <tr>
                      <th>City</th>
                      <td>{city}</td>
                    </tr>
                    <tr>
                      <th>ZIP</th>
                      <td>{zip}</td>
                    </tr>
                    <tr>
                      <th>State</th>
                      <td>{state}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </Col>
          {/*
          <Col md={12}>

            <h5>Addresses</h5>
            <div className="box box-smart-table">
              <Table
                showColumnsSelector
                showSearch
                showExport
                data={addresses || []}
                columns={columns}
                noDataText={
                  inProgress ? 'Loading Addresses...' : 'No Addresses Found'
                }
              />
            </div>

          </Col>
          */}
        </Row>

        <ModalDeleteBuilder
          name="DeleteBuilderModal"
          handleSubmit={handleDeleteBuilderSubmit}
          submitting={deleteBuilderInProgress}
          builderName={name}
        />
        <ModalEditBuilder
          name="EditBuilderModal"
          handleSubmit={handleBuilderSubmit}
          builder={builder}
          submitting={putBuilderInProgress}
        />

        {putBuilderError &&
          <Notification
            key="put-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPutBuilder}
          >
            Error updating builder profile.
          </Notification>}

        {deleteBuilderError &&
          <Notification
            key="put-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearDeleteBuilder}
          >
            Error deleting builder.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    inProgress,
    builder,
    addresses
  } = state.getBuilderById.toJS();
  const {
    error: putBuilderError,
    inProgress: putBuilderInProgress
  } = state.putBuilder.toJS();
  const {
    error: deleteBuilderError,
    inProgress: deleteBuilderInProgress
  } = state.deleteBuilder.toJS();

  return {
    builder,
    putBuilderError,
    putBuilderInProgress,
    deleteBuilderError,
    deleteBuilderInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getBuilderById: fetchDux.getBuilderById.createAction,
      putBuilder: fetchDux.putBuilder.createAction,
      clearPutBuilder: fetchDux.putBuilder.clearAction,
      deleteBuilder: fetchDux.deleteBuilder.createAction,
      clearDeleteBuilder: fetchDux.deleteBuilder.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(BuildersDetail);
