import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalMissingPhotos = props => {
    const { closeModal, openModal } = props;
    return (
        <Modal
            show={openModal === 'PhotosModal'}
            onHide={closeModal}
            className="modal-red"
        >
            <Modal.Header>
                <Modal.Title>
                    <span class="fa fa-exclamation-triangle" /> Missing Photos
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    You must upload at least
                    {' '}
                    <strong>1 photo of roof jacks</strong>
                    {' '}
                    provided with the address and
                    {' '}
                    <strong>1 photo of the mechanical room</strong>
                    .
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={closeModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalMissingPhotos;
