import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { CELL_HEIGHT } from './constants';

const getStyle = ({ height }) => ({
  height: `${height}`
});

const Cell = (
  {
    dayOfWeek,
    date,
    disabled,
    totalHeight,
    showOverflow,
    handleDrop,
    handleCellClick,
    cellHeight
  }
) => {
  const isToday = moment().isSame(date, 'day');

  const height = showOverflow ? totalHeight + 40 : cellHeight || CELL_HEIGHT;

  return (
    <div
      onDragOver={e => e.preventDefault()}
      onDrop={e => {
        const str = e.dataTransfer.getData('action');
        const json = JSON.parse(str);
        const { type, data } = json || {};
        if (handleDrop) handleDrop({ type, data, date });
      }}
      style={getStyle({
        height
      })}
      onDoubleClick={e => {
        if (handleCellClick) handleCellClick(date);
      }}
      className={classNames('react-calendar-cell', {
        'is-today': isToday
      })}
    >
      <div>
        <span className="date-label">
          {date.date()}
        </span>
        {disabled &&
          <div
            style={{
              width: '100%',
              height: `${height}px`,
              backgroundColor: 'rgb(255, 255, 255, .5)',
              // backgroundColor: 'rgba(204, 204, 204, 0.15)',
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '100',
              pointerEvents: 'none'
            }}
          />}
      </div>
      {/* <div style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ccc' }}>
        {getDayLabel({ dayOfWeek })}
      </div> */}
    </div>
  );
};

export default Cell;
