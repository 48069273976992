import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Notification from '../../ui/Notification';

import Icon from '../../ui/Icon';
import FormNewAddress from './FormNewAddress';

class AddressNew extends Component {
  componentDidMount() {
    const {
      getLookups
    } = this.props;
    getLookups();
  }
  componentWillUnmount() {
    const {
      clearPostProject,
      clearGetLookups
    } = this.props;

    clearPostProject();
    clearGetLookups();
  }
  render() {
    const {
      postProject,
      lookups,
      history,
      errorPostProject,
      errorLookups,
      clearPostProject,
      clearGetLookups
    } = this.props;

    const handleSubmit = values =>
      postProject(values, 'new-address', null, data => {
        const { insertedId } = data || {};
        history.push(`/addresses/${insertedId}`);
      });
    return (
      <Layout route="addresses-new">

        <div className="layout-header">
          <div>
            <h1>Create New Address</h1>
            <Link to="/addresses">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        <Row>
          <Col md={6} xl={6}>
            <FormNewAddress
              onSubmit={handleSubmit}
              lookups={lookups}
              initialValues={{
                roughInspectionDays: 5
              }}
            />
          </Col>
        </Row>

        {errorPostProject &&
          <Notification
            key="put-projects-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPostProject}
          >
            Error creating new address.
          </Notification>}

        {errorLookups &&
          <Notification
            key="lookups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetLookups}
          >
            Error loading selection options.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    error: errorPostProject
  } = state.postProject.toJS();
  const {
    result: lookups,
    error: errorLookups
  } = state.getLookups.toJS();
  return {
    result,
    lookups,
    errorLookups,
    errorPostProject
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postProject: fetchDux.postProject.createAction,
      clearPostProject: fetchDux.postProject.clearAction,
      getLookups: fetchDux.getLookups.createAction,
      clearGetLookups: fetchDux.getLookups.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(AddressNew);
