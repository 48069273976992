import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Table from '../../../ui/Table';
import Icon from '../../../ui/Icon';

import AdminSubNav from '../AdminSubNav';

const columns = [
  // {
  //   Header: 'Builder Id',
  //   accessor: 'id',
  //   width: 150,
  //   Cell: ({ value }) => <Link to={`/builders/${value}`}>{value}</Link>
  // },
  {
    Header: 'Builder Name',
    accessor: 'name',
    Cell: ({ value, original }) => (
      <Link to={`/admin/builders/${original._id}`}>{value}</Link>
    )
  },
  {
    Header: 'Address',
    accessor: 'address'
  },
  {
    Header: 'City',
    accessor: 'city'
  },
  {
    Header: 'ZIP',
    accessor: 'zip'
  },
  {
    Header: 'State',
    accessor: 'state'
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
  }
];

class BuildersSearch extends Component {
  componentDidMount() {
    const { getBuilders } = this.props;
    getBuilders();
  }
  render() {
    const { builders } = this.props;
    return (
      <Layout route="builders-search">

        <div className="layout-header">
          <div>
            <h1>Builders</h1>
            <AdminSubNav active="builders" />
          </div>
          <div>
            <Link to="/admin/builders/new" className="btn btn-success btn-icon">
              New Builder <Icon name="plus" />
            </Link>
          </div>
        </div>

        <div className="box box-smart-table">
          <Table
            showColumnsSelector
            showSearch
            showExport
            data={builders || []}
            columns={columns}
            noDataText="No Builders Found"
          />
        </div>

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: builders,
    error
  } = state.getBuilders.toJS();
  return {
    builders,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getBuilders: fetchDux.getBuilders.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(BuildersSearch);
