import React, { Component } from 'react';
import Layout from '../../ui/Layout';
import FormUnableMaterialPickup from './FormUnableMaterialPickup';
import Icon from '../../ui/Icon';
import Loading from '../../ui/Loading';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { formValueSelector } from 'redux-form';

class MaterialPickupsUnableToPickup extends Component {
  componentDidMount() {
    const {
      getLookups,
      getMaterialPickupById,
      match: { params: { id } }
    } = this.props;
    getLookups();
    getMaterialPickupById(id);
  }
  render() {
    const {
      result,
      lookups,
      inProgress,
      postDenyMaterialPickup,
      history,
      reasonId
    } = this.props || {};

    const { project, _id } = result || {};

    const {
      name: projectName,
      address,
      city,
      state,
      zip,
      fieldSupervisorName,
      builderName
    } = project || {};

    const initialValues = {
      address: `${address} ${city} ${state} ${zip}`,
      builder: builderName,
      fieldSupervisor: fieldSupervisorName,
      completeDate: new Date()
    };

    const { reasons } = lookups || {};

    const reasonOptions = (reasons || [])
      .filter(({ type: t }) => t === 'materialPickup')
      .map(({ _id, label }) => ({
        value: _id,
        label
      }));

    const currentReason = (reasonOptions || [])
      .find(({ value }) => reasonId === value);

    const {
      label: currentReasonLabel
    } = currentReason || {};

    const handleSubmit = data => {
      const {
        comments,
        completeDate,
        reasonId,
        files,
        otherReason
      } = data || {};

      const formatOtherReason = currentReasonLabel === 'Other' && otherReason
        ? otherReason
        : null;

      const payload = {
        comments,
        completeDate,
        reasonId,
        files,
        otherReason: formatOtherReason
      };
      postDenyMaterialPickup(
        { payload, id: _id },
        'unable-material-pickup',
        null,
        () => history.push('/material-pickups')
      );
    };
    return (
      <Layout route="material-pickups-unable">

        <div className="layout-header">
          <div>
            <h1>{`${projectName}, ${address}, ${city}, ${state}`}</h1>
            <Link to="/material-pickups">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        {inProgress && <Loading fullPage message="Loading, please wait.." />}

        {!inProgress &&
          <div>
            <FormUnableMaterialPickup
              onSubmit={handleSubmit}
              initialValues={initialValues}
              reasonOptions={reasonOptions}
              currentReasonLabel={currentReasonLabel}
            />
          </div>}

      </Layout>
    );
  }
}

const selector = formValueSelector('unable-material-pickup');

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getMaterialPickupById.toJS();
  const {
    result: lookups
  } = state.getLookups.toJS();

  const reasonId = selector(state, 'reasonId');

  return {
    result,
    lookups,
    inProgress,
    reasonId
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getLookups: fetchDux.getLookups.createAction,
      getMaterialPickupById: fetchDux.getMaterialPickupById.createAction,
      postDenyMaterialPickup: fetchDux.postDenyMaterialPickup.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MaterialPickupsUnableToPickup);
