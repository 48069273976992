import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

const ModalDeleteUser = ({ openModal, closeModal, name, handleSubmit, submitting, firstName, lastName }) => (
  <Modal show={openModal === name} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Delete User</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>Are you sure you wish to delete user <br/><strong>{firstName} {lastName}</strong>?</h4>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={() => handleSubmit()} disabled={submitting}>
        Delete
      </Button>
      <Button variant="outline-secondary" onClick={closeModal} disabled={submitting}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalDeleteUser);
