import React, { Component } from 'react';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import InspectionAssignCard from '../../ui/InspectionAssignCard';
import InspectionScheduleCard from '../../ui/InspectionScheduleCard';
import InspectionStatus from './InspectionStatus';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import classNames from 'classnames';
import Table from '../../ui/Table';

const COLUMNS = [
  {
    Header: '',
    accessor: '',
    className: 'rt-td-dropdown',
    width: 55,
    Cell: ({ value, original: row }) => {
      const { projectId, status } = row || {};
      return (
        <Dropdown menualign="right">
          <Dropdown.Toggle variant="success" size="sm" block>
            <Icon name="bars" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link className="dropdown-item" to={`/addresses/${projectId}`}>
              View Address
            </Link>
            <Link
              className="dropdown-item"
              to={`/quality-assurance/inspection/${row._id}`}
            >
              View Inspection
            </Link>
            {(status === 'reschedule' || status === 'denied') &&
              <Link
                className="dropdown-item"
                to={`/quality-assurance/inspection/${row._id}/reschedule`}
              >
                Reschedule Inspection
              </Link>}
            {(status === 'failed' || status === 'criticalFailed') &&
              <Link
                className="dropdown-item"
                to={`/quality-assurance/inspection/${row._id}/resolve`}
              >
                Resolve Inspection Failures
              </Link>}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  },
  {
    Header: 'Address',
    accessor: 'project.address',
    width: 150,
    Cell: ({ value, original: row }) => (
      <Link to={`/quality-assurance/inspection/${row._id}`}>{value}</Link>
    )
  },
  {
    Header: 'City',
    accessor: 'project.city',
    width: 80,
    show: false
  },
  {
    Header: 'ZIP',
    accessor: 'project.zip',
    width: 60,
    show: false
  },
  {
    Header: 'State',
    accessor: 'project.state',
    width: 50,
    show: false
  },
  {
    Header: 'Project',
    accessor: 'project',
    width: 150,
    show: false,
    Cell: ({ value }) => {
      const { name } = value || {};
      return <span>{name || '-'}</span>;
    }
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 130,
    Cell: ({ value }) => {
      return (
        <span>
          {value === 'roughInspection' && <span>Rough Inspection</span>}
          {value === 'finalInspection' && <span>Final Inspection</span>}
        </span>
      );
    }
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value, original: row }) => {
      const { isPausedInspection } = row || {};
      if (isPausedInspection) {
        return <div className="status red">Paused</div>;
      }
      return <InspectionStatus status={value} hideIcon />;
    }
  },
  {
    Header: 'Scheduled Date',
    accessor: 'scheduledDate',
    width: 110,
    Cell: ({ value }) => (
      <span>
        {value && moment(value).isValid()
          ? moment(value).format('MM/DD/YYYY')
          : ''}
      </span>
    )
  },
  {
    Header: 'Builder',
    accessor: 'project.builder.name'
  },
  {
    Header: 'Field Supervisor',
    accessor: 'project.fieldSupervisor',
    Cell: ({ value }) => {
      const {
        _id,
        firstName,
        lastName
      } = value || {};
      if (!value) return '';
      return <Link to={`/admin/users/${_id}`}>{firstName} {lastName}</Link>;
    }
  },
  {
    Header: 'Pickup Technician',
    accessor: 'technician',
    Cell: ({ value }) => {
      const {
        _id,
        firstName,
        lastName
      } = value || {};
      if (!value) return '';
      return <Link to={`/admin/users/${_id}`}>{firstName} {lastName}</Link>;
    }
  }
];

const setSessionFilter = filter => {
  try {
    window.sessionStorage.setItem('inspections-filter', filter);
  } catch (err) {}
};

const getSessionFilter = () => {
  try {
    return window.sessionStorage.getItem('inspections-filter');
  } catch (err) {
    return null;
  }
};

const filterStatus = filter =>
  ({ type }) => {
    if (filter === 'all') return true;
    return type === filter;
  };

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const getActiveColumns = () => {
  return COLUMNS;
  try {
    const str = window.sessionStorage.getItem('qual-columns');
    if (!str) return COLUMNS;
    const activeColumns = JSON.parse(str);
    if (!activeColumns) return COLUMNS;
    return COLUMNS.map(c => {
      if (activeColumns.some(a => a === c.accessor))
        return { ...c, show: true };
      return { ...c, show: false };
    });
  } catch (err) {
    return COLUMNS;
  }
};

const compare = (obj1, obj2) =>
  obj1.page === obj2.page &&
  obj1.sortBy === obj2.sortBy &&
  obj1.desc === obj2.desc &&
  obj1.search === obj2.search;

class QualityAssuranceGrid extends Component {
  constructor(props) {
    super(props);
    const filter = getSessionFilter();
    const columns = getActiveColumns();

    this.state = {
      filter: filter || 'roughInspection',
      columns
    };
    const { history, location: { pathname, search } } = this.props;

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search) || {};

    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || 'scheduledDate',
      desc: getDesc(desc),
      search: qSearch || '',
      status: 'pending,draft,denied,failed,criticalFailed'
    });

    history.push({ pathname, search: qs });
  }
  componentDidMount() {
    const {
      getInspections,
      getInspectionEvents,
      getLookups
    } = this.props;

    const { location: { search } } = this.props;
    const {
      page,
      pageSize,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search);

    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || 'scheduledDate',
      desc: getDesc(),
      search: qSearch || '',
      status: 'pending,draft,denied,failed,criticalFailed'
    });

    getInspections(qs);
    getInspectionEvents(
      'status=pending,draft,denied,failed,criticalFailed&pageSize=1000'
    );
    getLookups();
  }
  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps;
    const { location: { search: search2 } } = this.props;

    const obj1 = queryString.parse(search) || {};
    const obj2 = queryString.parse(search2) || {};

    if (compare(obj1, obj2)) return;

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = obj1 || {};
    const qs = queryString.stringify({
      page: page || '0',
      sortBy: sortBy || 'scheduledDate',
      desc: getDesc(desc),
      search: qSearch || '',
      status: 'pending,draft,denied,failed,criticalFailed'
    });

    nextProps.getInspections(qs);
  }
  componentWillUnmount() {
    const {
      clearGetInspections,
      clearGetInspectionEvents
    } = this.props;

    clearGetInspections();
    clearGetInspectionEvents();
  }
  render() {
    const {
      result,
      lookups,
      postAssignInspection,
      getInspections,
      postAssignInspectionInProgress,
      postScheduleInspection,
      postScheduleInspectionInProgress,
      getInspectionsInProgress,
      location: { search },
      resultInspectionsEvents,
      getInspectionEvents
    } = this.props;

    const {
      filter,
      columns
    } = this.state;

    const {
      numPages,
      inspections
    } = result || {};

    const {
      inspections: inspectionEvents
    } = resultInspectionsEvents || {};

    const {
      page,
      sortBy,
      desc,
      search: qSearch
    } = queryString.parse(search);

    const handleGetInspections = props => {
      const { history, location: { pathname } } = this.props;

      const {
        page,
        sortBy,
        desc,
        search: qSearch
      } = props || {};

      const search = queryString.stringify({
        page: page || '0',
        sortBy: sortBy || 'scheduledDate',
        desc: getDesc(desc),
        search: qSearch || '',
        status: 'pending,draft,denied,failed,criticalFailed'
      });

      history.push({ pathname, search });
    };

    const handlePaginationChange = state => {
      const {
        location: { search }
      } = this.props;

      const {
        search: qSearch
      } = queryString.parse(search);

      const {
        page,
        sorted
      } = state || {};

      const [sort] = sorted || [];

      const {
        id: sortBy,
        desc
      } = sort || {};

      handleGetInspections({
        page: page || '',
        sortBy,
        search: qSearch,
        desc
      });
    };

    const handleSearchChange = e => {
      const { value } = e.target || {};
      const {
        location: { search }
      } = this.props;

      const {
        sortBy,
        desc
      } = queryString.parse(search);

      handleGetInspections({
        page: '0',
        sortBy,
        desc,
        search: value
      });
    };

    const handleAssignGetGridInspections = () => {
      const {
        page,
        sortBy,
        desc,
        search: qSearch
      } = queryString.parse(search);

      const qs = queryString.stringify({
        page: page || '0',
        sortBy: sortBy || '_id',
        desc: getDesc(desc),
        search: qSearch || '',
        status: 'pending,draft,denied,failed,criticalFailed'
      });

      getInspections(qs);
    };

    const { qualityAssuranceTechnicians } = lookups || {};

    const setFilter = value =>
      () => {
        this.setState({ filter: value });
        setSessionFilter(value);
      };

    const assigneeOptions = (qualityAssuranceTechnicians || []).map(({
      _id,
      firstName,
      lastName
    }) => ({
      value: _id,
      label: `${firstName} ${lastName}`
    }));

    const unscheduledInspections = (inspectionEvents || [])
      .filter(({ scheduledDate }) => !scheduledDate)
      .filter(filterStatus(filter));

    const unassignedInspections = (inspectionEvents || [])
      .filter(({ technicianId }) => !technicianId)
      .filter(filterStatus(filter));

    const assignedInspections = (inspectionEvents || [])
      .filter(({ technicianId }) => !!technicianId)
      .filter(filterStatus(filter));

    const handleUnmount = ({ columns, activeColumns }) => {
      window.sessionStorage.setItem(
        'qual-columns',
        JSON.stringify(activeColumns)
      );
    };

    return (
      <Layout route="quality-assurance-grid">
        <div className="layout-header">
          <div>
            <h1>Quality Assurance</h1>
          </div>
          <div>
            <Link
              to="/quality-assurance/new"
              className="btn btn-success btn-icon"
            >
              Request Inspection <Icon name="plus" />
            </Link>
          </div>
        </div>

        <div className="box-nav">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <button
                className={classNames('nav-link', { active: filter === 'all' })}
                onClick={setFilter('all')}
              >
                All
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: filter === 'roughInspection'
                })}
                onClick={setFilter('roughInspection')}
              >
                Rough
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: filter === 'finalInspection'
                })}
                onClick={setFilter('finalInspection')}
              >
                Final
              </button>
            </li>
          </ul>
        </div>

        <Row>
          <Col md={4} xl={4}>

            <div className="box-inspections-list">
              <h5>{unscheduledInspections.length} Unscheduled Inspections</h5>
              <div className="inspections-list-container">

                {(unscheduledInspections || [])
                  .map((p, i) => (
                    <InspectionScheduleCard
                      key={p._id}
                      {...p}
                      postScheduleInspection={postScheduleInspection}
                      getInspections={getInspectionEvents}
                      getGridCalenderInspections={() =>
                        handleAssignGetGridInspections()}
                      postScheduleInspectionInProgress={
                        postScheduleInspectionInProgress
                      }
                    />
                  ))}
                {(unscheduledInspections || []).length === 0 &&
                  <div className="empty-list">
                    No Unscheduled Inspections Found
                  </div>}
              </div>
            </div>

          </Col>
          <Col md={4} xl={4}>

            <div className="box-inspections-list">
              <h5>{unassignedInspections.length} Unassigned Inspections</h5>
              <div className="inspections-list-container">

                {(unassignedInspections || [])
                  .map((p, i) => (
                    <InspectionAssignCard
                      key={p._id}
                      {...p}
                      assigneeOptions={assigneeOptions}
                      postAssignInspection={postAssignInspection}
                      getInspections={getInspectionEvents}
                      getGridCalenderInspections={() =>
                        handleAssignGetGridInspections()}
                      postAssignInspectionInProgress={
                        postAssignInspectionInProgress
                      }
                      reversePopupPlacement={i > 2}
                    />
                  ))}
                {(unassignedInspections || []).length === 0 &&
                  <div className="empty-list">
                    No Unassigned Inspections Found
                  </div>}
              </div>
            </div>

          </Col>
          <Col md={4} xl={4}>

            <div className="box-inspections-list">
              <h5>{assignedInspections.length} Assigned Inspections</h5>
              <div className="inspections-list-container">

                {(assignedInspections || [])
                  .map((p, i) => (
                    <InspectionAssignCard
                      key={p._id}
                      {...p}
                      assigneeOptions={assigneeOptions}
                      postAssignInspection={postAssignInspection}
                      getGridCalenderInspections={() =>
                        handleAssignGetGridInspections()}
                      getInspections={getInspections}
                      postAssignInspectionInProgress={
                        postAssignInspectionInProgress
                      }
                      reversePopupPlacement={i > 2}
                    />
                  ))}
                {(assignedInspections || []).length === 0 &&
                  <div className="empty-list">
                    No Assigned Inspections Found
                  </div>}

              </div>
            </div>

          </Col>
        </Row>

        <div className="box-nav">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <Link className="nav-link" to="/quality-assurance">Calendar</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/quality-assurance/grid">
                Grid
              </Link>
            </li>
          </ul>
        </div>

        <div className="box box-smart-table">
          <Table
            showColumnsSelector
            showSearch
            showExport
            data={inspections || []}
            columns={columns}
            noDataText={
              getInspectionsInProgress
                ? 'Loading Inspections...'
                : 'No Inspections Found'
            }
            manual
            pages={numPages}
            page={parseInt(page)}
            sortBy={sortBy}
            sortDirection={desc === 'true' ? 'desc' : 'asc'}
            onFetchData={handlePaginationChange}
            handleServerSearchChange={handleSearchChange}
            qSearch={qSearch}
            onUnmount={handleUnmount}
            isServerPaging
          />
        </div>

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getInspectionsInProgress
  } = state.getInspections.toJS();

  const {
    result: resultInspectionsEvents,
    inProgress: getInspectionsEventsInProgress
  } = state.getInspectionEvents.toJS();

  const {
    result: lookups
  } = state.getLookups.toJS();

  const {
    inProgress: postAssignInspectionInProgress
  } = state.postAssignInspection.toJS();

  const {
    inProgress: postScheduleInspectionInProgress
  } = state.postScheduleInspection.toJS();

  return {
    result,
    resultInspectionsEvents,
    lookups,
    postAssignInspectionInProgress,
    getInspectionsInProgress,
    postAssignInspectionInProgress,
    postScheduleInspectionInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getInspections: fetchDux.getInspections.createAction,
      clearGetInspections: fetchDux.getInspections.clearAction,
      getInspectionEvents: fetchDux.getInspectionEvents.createAction,
      clearGetInspectionEvents: fetchDux.getInspectionEvents.clearAction,
      getLookups: fetchDux.getLookups.createAction,
      postAssignInspection: fetchDux.postAssignInspection.createAction,
      postAssignInspection: fetchDux.postAssignInspection.createAction,
      postScheduleInspection: fetchDux.postScheduleInspection.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(QualityAssuranceGrid);
