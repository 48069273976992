import React, { Component } from 'react';
import moment from 'moment';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
// import Map from '../../ui/Map';
import Notification from '../../ui/Notification';
import Loading from '../../ui/Loading';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/modals-dux';
import { authenticatedHref } from '../../../api/fetch';
import ModalCancelMaterialPickup from './ModalCancelMaterialPickup';
import ImagePreviewModal from '../../ui/CameraModal';

const getCurrentInspection = (inspections = []) => {
  const [currentInspection] = inspections;
  return currentInspection || {};
};

class MaterialPickupsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: ''
    };
  }
  componentDidMount() {
    const {
      getMaterialPickupById,
      match: { params: { id } }
    } = this.props;
    getMaterialPickupById(id);
  }
  componentWillUnmount() {
    const {
      clearDeleteMaterialPickupById,
      clearGetMaterialPickupById
    } = this.props;

    clearDeleteMaterialPickupById();
    clearGetMaterialPickupById();
  }
  render() {
    const {
      imgUrl
    } = this.state;
    const {
      result,
      inProgress,
      showModal,
      history,
      deleteMaterialPickupById,
      errorDeleteMaterialPickupById,
      clearDeleteMaterialPickupById,
      clearGetMaterialPickupById,
      errorGetMaterialPickupById,
      user
    } = this.props;
    const {
      _id,
      project,
      technician,
      scheduledDate,
      status,
      comments,
      allowEarlyPickup,
      mustPickupDate,
      roughCompletedBy,
      roughDate,
      images,
      inspections
    } = result || {};
    const {
      _id: projectId,
      name: projectName,
      fieldSupervisorName,
      builderName,
      address,
      city,
      state,
      zip,
      lat,
      lng,
      partialMatch
    } = project || {};

    const {
      firstName: technicianFirstName,
      lastName: technicianLastName
    } = technician || {};

    const technicianName = `${technicianFirstName || ''} ${technicianLastName || ''}`;
    const {
      roleId
    } = user || {};
    const isAdmin = roleId === 'administrator';

    const handlePreview = image =>
      () => {
        this.setState({ imgUrl: image });
        showModal('CameraModal');
      };

    const handleCancel = deleteReason => {
      deleteMaterialPickupById(
        { id: _id, payload: { deleteReason } },
        'cancel-material-pickup',
        null,
        () => {
          history.push(`/material-pickups/`);
        }
      );
    };

    const currentInspection = getCurrentInspection(inspections);

    const {
      completeDate,
      comments: inspectionComments,
      transferred,
      reason,
      otherReason
    } = currentInspection || {};

    const { label: reasonMessage } = reason || {};

    return (
      <Layout route="material-pickups-calendar">

        <div className="layout-header">
          <div>
            <h1>
              Material Pickup
              {' '}
              {projectName &&
                <span>{`${projectName}, ${address}, ${city}, ${state}`}</span>}
            </h1>
            <Link to="/material-pickups">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
          <div>
            <Link
              className="btn btn-primary btn-icon"
              to={`/material-pickups/${_id}/edit`}
            >
              Edit <Icon name="wrench" />
            </Link>
            {isAdmin &&
              <Button
                className="btn btn-danger btn-icon"
                onClick={() => showModal('CancelModal')}
              >
                Cancel <Icon name="times" />
              </Button>}

          </div>
        </div>

        {inProgress && <Loading fullPage message="Loading, please wait.." />}

        {!inProgress &&
          <div>

            <Row>
              <Col md={12} xl={8}>

                <Row>
                  <Col md={6}>

                    <div className="box">
                      <div className="box-header">
                        <h3>Detail</h3>
                      </div>
                      <div className="box-body">
                        <table className="table data-table">
                          <tbody>
                            <tr>
                              <th>Result</th>
                              <td>
                                {status === 'pending' &&
                                  <div className="status orange">
                                    <Icon name="clock" /> Pending
                                  </div>}
                                {status === 'late' &&
                                  <div className="status red">
                                    <Icon name="exclamation-triangle" /> Late
                                  </div>}
                                {status === 'completed' &&
                                  <div className="status green">
                                    <Icon name="check" /> Completed
                                  </div>}
                                {status === 'denied' &&
                                  <div className="status red">
                                    <Icon name="exclamation-circle" />
                                    {' '}
                                    Unable To Pickup
                                  </div>}
                              </td>
                            </tr>
                            <tr>
                              <th>Scheduled Pickup Date</th>
                              <td>
                                {moment(scheduledDate).format('MM/DD/YYYY')}
                              </td>
                            </tr>
                            <tr>
                              <th>Must Pickup By</th>
                              <td>
                                {moment(mustPickupDate).format('MM/DD/YYYY')}
                              </td>
                            </tr>
                            <tr>
                              <th>Allow Early Pickup</th>
                              <td>
                                {allowEarlyPickup &&
                                  <Icon
                                    name="check"
                                    className="text-success"
                                  />}
                                {!allowEarlyPickup &&
                                  <Icon name="times" className="text-danger" />}
                              </td>
                            </tr>
                            <tr>
                              <th>Pickup Technician</th>
                              <td>{technicianName}</td>
                            </tr>
                            <tr>
                              <th>Rough Completed Date</th>
                              <td>{moment(roughDate).format('MM/DD/YYYY')}</td>
                            </tr>
                            <tr>
                              <th>Rough Completed By</th>
                              <td>{roughCompletedBy}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </Col>
                  <Col md={6}>

                    {currentInspection &&
                      status === 'completed' &&
                      <div className="box">
                        <div className="box-header">
                          <h3>Pickup Result</h3>
                        </div>
                        <div className="box-body">
                          <table className="table data-table">
                            <tbody>
                              <tr>
                                <th>Completed Date</th>
                                <td>
                                  {completeDate &&
                                    moment(completeDate).isValid() &&
                                    moment(completeDate).format('MM/DD/YYYY')}
                                </td>
                              </tr>
                              <tr>
                                <th>Transferred to another location</th>
                                <td>
                                  {transferred &&
                                    <Icon
                                      name="check"
                                      className="text-success"
                                    />}
                                  {!transferred &&
                                    <Icon
                                      name="times"
                                      className="text-danger"
                                    />}
                                </td>
                              </tr>
                              <tr>
                                <th>Comments</th>
                                <td>{inspectionComments}</td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>}
                    {currentInspection &&
                      status === 'denied' &&
                      <div className="box">
                        <div className="box-header">
                          <h3>Pickup Result</h3>
                        </div>
                        <div className="box-body">
                          <table className="table data-table">
                            <tbody>
                              <tr>
                                <th>Date Denied</th>
                                <td>
                                  {completeDate &&
                                    moment(completeDate).isValid() &&
                                    moment(completeDate).format('MM/DD/YYYY')}
                                </td>
                              </tr>
                              <tr>
                                <th>Reason</th>
                                <td>{reasonMessage}</td>
                              </tr>
                              {reasonMessage === 'Other' &&
                                <tr>
                                  <th>Alternate Reason</th>
                                  <td>{otherReason}</td>
                                </tr>}
                              <tr>
                                <th>Comments</th>
                                <td>{inspectionComments}</td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>}

                    <div className="box">
                      <div className="box-header">
                        <h3>Comments</h3>
                      </div>
                      {comments &&
                        <div className="box-body">
                          {comments}
                        </div>}
                      {!comments &&
                        <div className="box-body box-empty">
                          <em>No Comments Found</em>
                        </div>}
                    </div>
                  </Col>
                  {(images || []).length > 0 &&
                    <Col md={12}>
                      <h5>Gallery</h5>
                      <div className="box-photos">
                        {(images || []).map(({ _id }, i) => {
                          const href = authenticatedHref(`files/${_id}`);
                          return (
                            <div className="photo" key={i}>
                              <div
                                className="image"
                                onClick={handlePreview(href)}
                                style={{ backgroundImage: `url(${href})` }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </Col>}
                </Row>

              </Col>
              <Col md={6} xl={4}>

                <div className="box">
                  <div className="box-header">
                    <h3>
                      Project
                      {/* {partialMatch &&
                        <span style={{ color: 'red' }}>
                          {' '}(Address Partial Match)
                        </span>} */}
                    </h3>
                  </div>
                  <div className="box-body">
                    <table className="table data-table">
                      <tbody>
                        <tr>
                          <th>Project</th>
                          <td>{projectName}</td>
                        </tr>
                        <tr>
                          <th>Builder</th>
                          <td>{builderName}</td>
                        </tr>
                        <tr>
                          <th>Address</th>
                          <td>
                            <Link to={`/addresses/${projectId}`}>
                              {address},<br />
                              {city} {zip}, {state}
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th>Field Supervisor</th>
                          <td>{fieldSupervisorName}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <Map geocodes={lat && lng ? [{ lat, lng }] : []} squareSize /> */}

              </Col>

            </Row>

          </div>}

        <ImagePreviewModal imgUrl={imgUrl} />
        <ModalCancelMaterialPickup
          handleCancel={handleCancel}
          address={address}
          city={city}
          state={state}
          zip={zip}
          name="CancelModal"
        />

        {errorGetMaterialPickupById &&
          <Notification
            key="get-pickup-by-id-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetMaterialPickupById}
          >
            Error loading material pickup details.
          </Notification>}
        {errorDeleteMaterialPickupById &&
          <Notification
            key="delete-pickup-by-id-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearDeleteMaterialPickupById}
          >
            Error canceling material pickup.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress,
    error: errorGetMaterialPickupById
  } = state.getMaterialPickupById.toJS();
  const {
    error: errorDeleteMaterialPickupById
  } = state.deleteMaterialPickupById.toJS();
  return {
    result,
    inProgress,
    errorGetMaterialPickupById,
    errorDeleteMaterialPickupById,
    user: state.login.toJS().result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getMaterialPickupById: fetchDux.getMaterialPickupById.createAction,
      clearGetMaterialPickupById: fetchDux.getMaterialPickupById.clearAction,
      deleteMaterialPickupById: fetchDux.deleteMaterialPickupById.createAction,
      clearDeleteMaterialPickupById: fetchDux.deleteMaterialPickupById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MaterialPickupsDetail);
