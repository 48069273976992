import React, { Component } from 'react';
import Layout from '../../ui/Layout';
import FormRescheduleMaterialPickup from './FormRescheduleMaterialPickup';
import MaterialPickupsCalendar from '../../ui/MaterialPickupsCalendarSmall';
import Icon from '../../ui/Icon';
import Loading from '../../ui/Loading';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';

class MaterialPickupsReschedule extends Component {
  componentWillMount() {
    const {
      getLookups,
      getMaterialPickupById,
      match: { params: { id } }
    } = this.props;
    getMaterialPickupById(id);
    getLookups();
  }
  render() {
    const {
      inProgress,
      result,
      lookups,
      patchMaterialPickupById,
      history
    } = this.props;

    const {
      _id,
      project,
      scheduledDate,
      technicianId,
      allowEarlyPickup,
      mustPickupDate,
      comments: supervisorComments
    } = result || {};

    const {
      address,
      city,
      state,
      zip,
      builder,
      fieldSupervisorName
    } = project || {};

    const { projectName } = project || {};

    const { name: builderName } = builder || {};

    const initialValues = {
      address: `${address} ${city} ${state} ${zip}`,
      builder: builderName,
      fieldSupervisor: fieldSupervisorName,
      comments: supervisorComments,
      technicianId,
      scheduledDate,
      allowEarlyPickup,
      mustPickupDate
    };

    const {
      materialPickupTechnicians
    } = lookups || {};

    const materialPickupTechnicianOptions = (materialPickupTechnicians || [])
      .map(({ _id, firstName, lastName }) => ({
        value: _id,
        label: `${firstName} ${lastName}`
      }));

    const handleSubmit = data => {
      const {
        technicianId,
        scheduledDate,
        mustPickupDate,
        allowEarlyPickup,
        comments
      } = data || {};

      const payload = {
        technicianId,
        scheduledDate,
        mustPickupDate,
        allowEarlyPickup,
        comments
      };

      patchMaterialPickupById(
        { id: _id, payload },
        'reschedule-material-pickup',
        null,
        () => history.push('/material-pickups')
      );
    };

    return (
      <Layout route="material-pickups-reschedule">

        <div className="layout-header">
          <div>
            <h1>{`${projectName}, ${address}, ${city}, ${state}`}</h1>
            <Link to="/material-pickups">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        {!inProgress &&
          <FormRescheduleMaterialPickup
            onSubmit={handleSubmit}
            initialValues={initialValues}
            options={materialPickupTechnicianOptions}
          />}

        <MaterialPickupsCalendar />

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getMaterialPickupById.toJS();
  const {
    result: lookups
  } = state.getLookups.toJS();
  return {
    result,
    inProgress,
    lookups
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getMaterialPickupById: fetchDux.getMaterialPickupById.createAction,
      patchMaterialPickupById: fetchDux.patchMaterialPickupById.createAction,
      getLookups: fetchDux.getLookups.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MaterialPickupsReschedule);
