import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Camera from '../../ui/Camera';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const FormUnableMaterialPickup = props => {
  const {
    handleSubmit,
    submitting,
    files,
    reasonOptions,
    currentReasonLabel
  } = props;

  return (
    <div className="box box-form">
      <h5
        style={{
          margin: '0 0 1rem',
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }}
      >
        Unable To Pickup
      </h5>
      <form onSubmit={handleSubmit}>

        <Row>
          <Col md={6} lg={3}>

            <Field
              name="completeDate"
              type="text"
              component={FormField.Datepicker}
              label="Date Denied"
              validate={[required]}
            />

          </Col>
          <Col md={6} lg={3}>
            <Field
              required
              name="reasonId"
              type="text"
              component={FormField.SelectReact}
              label="Reason"
              options={reasonOptions}
              validate={[required]}
            />
          </Col>
          {currentReasonLabel === 'Other' &&
            <Col md={12} lg={6}>
              <Field
                required
                name="otherReason"
                type="text"
                component={FormField.Input}
                label="Alternate Reason"
                validate={[required]}
                autoCapitalize="none"
                disabled={currentReasonLabel !== 'Other'}
              />
            </Col>}
        </Row>

        <Row>
          <Col md={12} lg={6}>

            <Field
              name="address"
              type="text"
              component={FormField.Input}
              label="Address"
              validate={[required]}
              autoCapitalize="none"
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="builder"
              type="text"
              component={FormField.Input}
              label="Builder"
              validate={[required]}
              autoCapitalize="none"
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="fieldSupervisor"
              type="text"
              component={FormField.Input}
              label="Field Supervisor"
              validate={[required]}
              autoCapitalize="none"
              disabled
            />

          </Col>
        </Row>

        <Field
          name="comments"
          type="text"
          component={FormField.Textarea}
          label="Comments"
          autoCapitalize="none"
          rows={5}
        />

        <Camera files={files} />

        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Submit
          </Button>
          {' '}
          <Link to="/material-pickups" className="btn btn-outline-secondary">
            Cancel
          </Link>
        </div>

      </form>
    </div>
  );
};

const formName = 'unable-material-pickup';

const selector = formValueSelector(formName);

const form = reduxForm({
  form: 'unable-material-pickup'
})(FormUnableMaterialPickup);

const mapState = state => {
  const files = selector(state, 'files') || [];

  return {
    files
  };
};

export default connect(mapState)(form);
