import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
// import Map from '../../ui/Map';
import Layout from '../../ui/Layout';
import InspectionStatus from './InspectionStatus';
import InspectionReportGroup from './InspectionReportGroup';
import ResolveModal from './InspectionReportResolveModal';
import fetchDux from '../../../state/fetch-dux';
import { authenticatedHref } from '../../../api/fetch';
import ImagePreviewModal from '../../ui/CameraModal';

const getChecklist = checklist => {
  const { values } = checklist || {};
  return values;
};

const getCurrent = p => {
  const [current] = p || [];
  return current;
};

const data = {
  id: 1,
  builder: 'Bob Builder',
  address: '1200 Humboldt St',
  city: 'Denver',
  state: 'CO',
  zip: '80218',
  position: {
    lat: 39.735447,
    lng: -104.969525
  },
  projectName: 'Sudden Valley',
  scheduledDate: moment().format(),
  type: 'rough',
  status: 'passed',
  fieldSupervisor: {
    id: 1,
    name: 'Becky Smith',
    phone: '123 456 789',
    email: 'joe.doe@example.com'
  },
  technician: {
    id: 2,
    name: 'Fred Flinstone',
    phone: '123 456 789',
    email: 'joe.doe@example.com'
  }
};

const checkPassed = (group, values) => {
  const { fields } = group || {};

  const { other, disabled, fields: formFields = {} } = values || {};

  if (disabled)
    return {
      passed: true,
      failed: false,
      criticalFailed: false
    };

  const otherPassed = (other || [])
    .every(({ value }) => value === 'passed' || value === 'na');
  const otherFailed = (other || []).some(({ value }) => value === 'failed');

  const passed = (fields || []).every(({ name }) => {
    const { value } = formFields[name] || {};
    return value === 'passed' || value === 'na';
  });
  const failed = (fields || []).some(({ name }) => {
    const { value } = formFields[name] || {};
    return value === 'failed';
  });
  const criticalFailed = (fields || [])
    .filter(({ criticalFail }) => criticalFail)
    .some(({ name }) => {
      const { value } = formFields[name] || {};
      return value === 'failed';
    });

  return {
    passed: passed && otherPassed,
    failed: failed || otherFailed,
    criticalFailed
  };
};

class InspectionDetailSupervisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOnlyFailed: true,
      resolveName: null,
      imgUrl: null
    };
  }
  componentDidMount() {
    const { getInspectionById, match: { params: { id } } } = this.props;
    getInspectionById(id);
  }
  componentDidUpdate() {
    const {
      result,
      history,
      match: { params: { id } }
    } = this.props;
    const { status } = result || {};
    if (!status) return;
    const isFailed = ['failed', 'criticalFailed'].some(s => s === status);
    if (isFailed) return;
    history.push(`/quality-assurance/inspection/${id}`);
  }
  render() {
    const {
      showModal,
      result,
      postResolveInspectionItem,
      getInspectionById
    } = this.props;
    const {
      showOnlyFailed,
      resolveName,
      imgUrl
    } = this.state;
    const toggleReports = () =>
      this.setState({ showOnlyFailed: !showOnlyFailed });

    const openResolveModal = () => showModal('ResolveModal');

    const setResolveName = value => this.setState({ resolveName: value });

    const {
      _id,
      project,
      type,
      status,
      scheduledDate,
      technician,
      inspections,
      images,
      checklist: checklistValue,
      comments
    } = result || {};

    const {
      _id: projectId,
      name: projectName,
      builderName,
      fieldSupervisorName,
      address,
      city,
      state,
      zip
    } = project || {};

    const checklist = getChecklist(checklistValue);

    const current = getCurrent(inspections);

    const {
      _id: resultId,
      completeDate,
      comments: inspectionComments,
      reason,
      noPower,
      inspections: currentInspection
    } = current || {};

    const { firstName, lastName } = technician || {};

    const technicianName = technician
      ? `${firstName} ${lastName}`
      : 'Unassigned';

    const handlePostResolve = data => {
      const { files } = data;
      if ((files || []).length === 0) return;
      postResolveInspectionItem(
        {
          id: _id,
          resultId,
          payload: { ...data, ...resolveName }
        },
        'resolve-inspection-issue',
        null,
        () => {
          getInspectionById(_id);
        }
      );
    }


    const handlePreview = image =>
      () => {
        this.setState({ imgUrl: image });
        showModal('CameraModal');
      };

    return (
      <Layout route="inspection-detail">

        <div className="layout-header">
          <div>
            <h1>
              <span className="type blue">Rough</span>
              {' '}
              Inspection
              {' '}
              <span>{projectName}</span>
            </h1>
          </div>
        </div>

        <Row>
          <Col md={12} xl={8}>

            <Row>
              <Col md={6}>

                <div className="box">
                  <div className="box-header">
                    <h3>Inspection</h3>
                  </div>
                  <div className="box-body">
                    <table className="table data-table">
                      <tbody>
                        <tr>
                          <th>Result</th>
                          <td>
                            <InspectionStatus status={status} />
                          </td>
                        </tr>
                        <tr>
                          <th>Type</th>
                          <td>
                            {type === 'roughInspection' &&
                              <span>Rough Inspection</span>}
                            {type === 'finalInspection' &&
                              <span>Final Inspection</span>}
                          </td>
                        </tr>
                        <tr>
                          <th>Date</th>
                          <td>
                            {moment(scheduledDate).format('MM/DD/YYYY')}
                          </td>
                        </tr>
                        <tr>
                          <th>Inspected By</th>
                          <td>{technicianName}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </Col>
              <Col md={6}>

                <div className="box">
                  <div className="box-header">
                    <h3>Project</h3>
                  </div>
                  <div className="box-body">
                    <table className="table data-table">
                      <tbody>
                        <tr>
                          <th>Project</th>
                          <td>{projectName}</td>
                        </tr>
                        <tr>
                          <th>Builder</th>
                          <td>{builderName}</td>
                        </tr>
                        <tr>
                          <th>Address</th>
                          <td>
                            {address}, {city} {zip}, {state}
                          </td>
                        </tr>
                        <tr>
                          <th>Field Supervisor</th>
                          <td>{fieldSupervisorName}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </Col>
            </Row>

            <div className="box">
              <div className="box-header">
                <h3>Comments</h3>
              </div>
              {comments &&
                <div className="box-body">
                  {comments}
                </div>}
              {!comments &&
                <div className="box-body box-empty">
                  <em>No Comments Found</em>
                </div>}
            </div>

          </Col>
          <Col md={6} xl={4}>

            {/* <Map geocodes={[data.position]} squareSize /> */}

          </Col>

        </Row>

        <h5 className="h5-inline-blocks">
          <span>Inspection Report</span>
          <div className="toggle-group sm">
            <label className="toggle">
              <input
                type="checkbox"
                checked={showOnlyFailed}
                onClick={() => toggleReports()}
              />
              <div className="toggle-slider" />
            </label>
            <div className="toggle-label">Show only failed reports</div>
          </div>
        </h5>

        <Row>
          {(checklist || []).map((group, i) => {
            const { name } = group || {};
            const values = currentInspection[name] || {};

            const {
              failed,
              criticalFailed
            } = checkPassed(group, values);
            if (showOnlyFailed && !failed && !criticalFailed) return false;
            const handleOpenResolveModal = ({ isOther, itemName }) => {
              setResolveName({
                groupName: name,
                isOther,
                itemName
              });
              openResolveModal();
            };
            return (
              <Col md={6} lg={4} key={i}>
                <InspectionReportGroup
                  values={values}
                  checklist={group}
                  showResolve
                  openResolveModal={handleOpenResolveModal}
                />
              </Col>
            );
          })}
        </Row>
        <div className="box">
          <div className="box-header">
            <h3>Inspection Comments</h3>
          </div>
          {inspectionComments &&
            <div className="box-body">
              {inspectionComments}
            </div>}
          {!inspectionComments &&
            <div className="box-body box-empty">
              <em>No Comments Found</em>
            </div>}
        </div>
        {(images || []).length > 0 &&
          <div>
            <h5>Gallery</h5>

            <div className="box-photos">
              {(images || []).map(({ _id }, i) => {
                const href = authenticatedHref(`files/${_id}`);
                return (
                  <div className="photo" key={i}>
                    <div
                      className="image"
                      onClick={handlePreview(href)}
                      style={{ backgroundImage: `url(${href})` }}
                    />
                  </div>
                );
              })}
            </div>
          </div>}

        <ResolveModal handlePostResolve={handlePostResolve} />
        <ImagePreviewModal imgUrl={imgUrl} />

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getInspectionById.toJS();

  return {
    result,
    inProgress
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getInspectionById: fetchDux.getInspectionById.createAction,
      postResolveInspectionItem: fetchDux.postResolveInspectionItem.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(InspectionDetailSupervisor);
