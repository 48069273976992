import React, { useState } from 'react';
import Cell from './Cell';
import moment from 'moment';
import Event from './Event';
import Icon from '../Icon';

import {
  CELL_HEIGHT,
  EVENT_HEIGHT,
  EVENT_PADDING,
  EVENT_MARGIN_TOP
} from './constants';

const getTrueStartDay = (trueStartDate, includeWeekends) => {
  return trueStartDate.day();
};

const getTrueStartDate = ({ startDate, firstDay, includeWeekends }) => {
  return firstDay.isSameOrAfter(startDate) ? firstDay : moment(startDate);
};

const formatEvents = ({ events: e, days }) => {
  const layout = new EventLayout();
  const firstDay = days[0].date;
  const lastDay = days[6].date;
  const events = (e || [])
    .sort(({ startDate: a1, endDate: a2 }, { startDate: b1, endDate: b2 }) => {
      return (a1 || a2) - (b1 || b2);
    })
    .map(({ startDate, endDate, includeWeekends, ...props }) => {
      const trueStartDate = getTrueStartDate({
        startDate,
        firstDay,
        includeWeekends
      });
      const trueEndDate = lastDay.isSameOrBefore(endDate)
        ? lastDay
        : moment(endDate);

      const trueStartDay = getTrueStartDay(trueStartDate, includeWeekends);
      const trueEndDay = trueEndDate.day() + 1;

      const startOffset = 0;
      const endOffset = 0;
      // const startOffset = !includeWeekends && trueStartDay === 0 ? 1 : 0;
      // const endOffset = !includeWeekends && trueEndDay - 1=== 6 ? 1 : 0;

      const offset = trueStartDate.diff(firstDay, 'days') + startOffset;
      const duration = trueEndDay - trueStartDay - startOffset - endOffset;
      const width = duration * 100 / 7;

      const row = layout.getRow({ trueStartDay, duration });
      const isStart = moment(startDate).isSameOrAfter(firstDay);
      const isEnd = moment(endDate).isSameOrBefore(lastDay);

      const borderRadius = `${isStart ? '4px' : '0'} ${isEnd ? '4px' : '0'} ${isEnd ? '4px' : '0'} ${isStart ? '4px' : '0'}`;

      return {
        props: {
          startDate,
          endDate,
          ...props
        },
        width,
        offset,
        duration,
        row,
        borderRadius,
        isStart,
        isEnd
      };
    });
  const numRows = layout.getNumRows();

  return { events, numRows };
};

class EventLayout {
  constructor() {
    this.grid = {};
  }
  getRow({ trueStartDay, duration }) {
    this.grid[trueStartDay] = this.grid[trueStartDay] || {};
    const day = this.grid[trueStartDay];
    const row = this.checkRows(day);
    this.setRow({ trueStartDay, duration, row });
    return row;
  }
  setRow({ trueStartDay, duration, row }) {
    for (var i = trueStartDay; i < trueStartDay + duration; i++) {
      this.grid[i] = this.grid[i] || {};
      this.grid[i][row] = true;
    }
  }
  getGrid() {
    return this.grid;
  }
  checkRows(day) {
    const keys = Object.keys(day || {});
    if (!keys.length) return 0;
    const max = keys.reduce(
      (prev, next) => prev >= parseInt(next) ? prev : parseInt(next),
      0
    );
    return max + 1;
  }
  getNumRows() {
    return Object.keys(this.grid).reduce(
      (prev, key) => {
        const max = Object.keys(this.grid[key]).reduce(
          (p, next) => Number(next) > p ? Number(next) : p,
          0
        );
        return max > prev ? max : prev;
      },
      0
    );
  }
}

const CalendarRow = (
  {
    days,
    events,
    handleDrop,
    handleCellClick,
    renderEvent,
    cellHeight,
    eventHeight,
    eventPadding,
    eventMargin,
    eventMarginTop
  }
) => {
  const [overflow, toggleOverflow] = useState(false);

  const onToggleOverflow = () => toggleOverflow(!showOverflow);

  const { events: formattedEvents, numRows } = formatEvents({ events, days });
  const totalHeight = (numRows + 1) * (eventHeight || EVENT_HEIGHT) +
    (eventPadding || EVENT_PADDING) * (numRows + 1) +
    (eventHeight || EVENT_HEIGHT);

  const requiresOverflow = !(totalHeight < (cellHeight || CELL_HEIGHT));
  const showOverflow = requiresOverflow && overflow;

  return (
    <div className="react-calendar-rows">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          top: 0,
          bottom: 0,
          marginTop: eventMarginTop || EVENT_MARGIN_TOP,
          //    height: `${showOverflow ? totalHeight + 40 - (eventMarginTop || EVENT_MARGIN_TOP) : (cellHeight || CELL_HEIGHT) - (eventMarginTop || EVENT_MARGIN_TOP)}px`,
          overflowY: 'hidden',
          pointerEvents: 'none'
        }}
      >
        {(formattedEvents || [])
          .map((e, i) => (
            <Event
              key={i}
              renderEvent={renderEvent}
              event={e}
              eventHeight={eventHeight}
              eventPadding={eventPadding}
              eventMargin={eventMargin}
            />
          ))}
        <Overflow
          cellHeight={cellHeight}
          numRows={numRows}
          onToggleOverflow={onToggleOverflow}
          totalHeight={totalHeight}
          showOverflow={showOverflow}
        />
      </div>
      <div style={{ display: 'flex' }}>
        {(days || [])
          .map((props, i) => (
            <Cell
              {...props}
              key={i}
              cellHeight={cellHeight}
              handleDrop={handleDrop}
              handleCellClick={handleCellClick}
              totalHeight={totalHeight}
              showOverflow={showOverflow}
            />
          ))}
      </div>

    </div>
  );
};

const iconStyle = {
  fontSize: '14px',
  fontWeight: '400',
  transform: 'scale(1.25)'
};

const Overflow = (
  { onToggleOverflow, totalHeight, showOverflow, cellHeight }
) => {
  if (totalHeight < (cellHeight || CELL_HEIGHT)) return false;

  return (
    <div
      onClick={onToggleOverflow}
      style={{
        position: 'absolute',
        bottom: '0',
        width: '100%',
        textAlign: 'center',
        backgroundColor: 'rgba(102, 45, 145, .75)',
        color: '#FFF',
        boxShadow: `rgba(102, 45, 145, 0.25) 0px -2px 2px`,
        cursor: 'pointer',
        pointerEvents: 'all',
        // zIndex: 8,
        fontFamily: 'Rubik',
        zIndex: '101',
        fontWeight: 'bold',
        fontSize: '12px'
      }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, textAlign: 'left', padding: '0 8px' }}>
          {showOverflow && <Icon name="angle-up" style={iconStyle} />}
          {!showOverflow && <Icon name="angle-down" style={iconStyle} />}
        </div>
        <div style={{ flex: 1, textAlign: 'right', padding: '0 8px' }}>
          {showOverflow && <Icon name="angle-up" style={iconStyle} />}
          {!showOverflow && <Icon name="angle-down" style={iconStyle} />}
        </div>

      </div>
      {/* {showOverflow ? 'LESS' : 'MORE'} */}
    </div>
  );
};

export default CalendarRow;
