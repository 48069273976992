import React from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import FormEditUser from './FormEditUser';

const ModalEditUser = ({ openModal, closeModal, name, user, handleSubmit, submitting }) => (
  <Modal show={openModal === name} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Edit User</Modal.Title>
    </Modal.Header>
      <FormEditUser
        initialValues={user}
        onSubmit={handleSubmit}
        closeModal={closeModal}
        submitting={submitting}
      />

  </Modal>
  
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalEditUser);
