import React from 'react';

import {
  EVENT_HEIGHT,
  EVENT_MARGIN,
  EVENT_PADDING
} from './constants';

const Event = (
  { event, renderEvent, eventHeight, eventPadding, eventMargin }
) => {
  const {
    width,
    offset,
    row,
    props,
    borderRadius
  } = event || {};
  const top = row * (eventHeight || EVENT_HEIGHT) +
    (eventPadding || EVENT_PADDING) * row;

  const trueWidth = width - (eventMargin || EVENT_MARGIN) * 2;

  const style = {
    position: 'absolute',
    top: `${top}px`,
    width: `${trueWidth}%`,
    margin: `0 ${eventMargin || EVENT_MARGIN}%`,
    left: `${offset * 100 / 7}%`,
    justifyContent: 'stretch',
    borderRadius,
    height: `${eventHeight || EVENT_HEIGHT}px`,
    cursor: 'pointer',
    pointerEvents: 'all',
    zIndex: 7 - offset
  };

  return (
    <div style={style}>
      {renderEvent({
        event: props,
        style: { borderRadius, height: `${eventHeight || EVENT_HEIGHT}px` }
      })}
    </div>
  );
};

export default Event;
