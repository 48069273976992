import React, { Component, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import { useDropzone } from 'react-dropzone';
import FormError from './FormError';
import Toggle from '../ui/Toggle';
import Icon from '../ui/Icon';
import classNames from 'classnames';
import moment from 'moment';

const getValidationState = (meta, fileInput) => {
  if (meta.touched) {
    if (meta.valid === false) {
      return 'has-error';
    }
    if (meta.warning) {
      return 'has-warning';
    }
    if (meta.valid === true) {
      // return 'success';
    }
  }

  if (fileInput && meta.valid === false)
    return 'has-error'

  return null;
};

const Input = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} type={type} {...props} />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const SelectHTML = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} as="select" {...props} />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

class SelectReact extends Component {
  constructor(props) {
    super(props);
    this.value = null;
  }
  render() {
    const {
      input,
      label,
      type,
      meta,
      help,
      afterChange,
      grouped,
      menuPlacement,
      ...props
    } = this.props;
    return (
      <Form.Group
        controlId={input.name}
        className={classNames('form-group-select', getValidationState(meta))}
      >
        {label && <Form.Label>{label}</Form.Label>}
        <div className="form-container">
          <ReactSelect
            {...props}
            classNamePrefix="react-select"
            value={(props.options || []).find(o => o.value === input.value)}
            onChange={value => {
              this.value = (value || {}).value;
              input.onChange(this.value);
              if (!afterChange) return;
              afterChange(value);
            }}
            menuPlacement={menuPlacement ? menuPlacement : 'auto'}
            onBlur={e => input.onBlur(this.value)}
          />
          {help && <Form.Text>{help}</Form.Text>}
          {meta.touched &&
            (meta.error || meta.warning) &&
            <FormError message={meta.error || meta.warning} />}
        </div>
      </Form.Group>
    );
  }
}

const SelectReactAsync = (
  {
    input,
    label,
    type,
    meta,
    help,
    errorMessage,
    ...props
  }
) => {
  return (
    <Form.Group controlId={input.name} className={getValidationState(meta)}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <AsyncSelect
          {...props}
          value={input.value}
          onChange={value => input.onChange(value)}
          onBlur={() => {
            if (!input.value) return;
            input.onBlur(input.value);
          }}
        />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={errorMessage} />}
      </div>
    </Form.Group>
  );
};

const Creatable = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <ReactSelect.Creatable
        {...props}
        value={input.value}
        onChange={input.onChange}
        onBlur={() => input.onBlur(input.value)}
      />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const InputGroup = (
  {
    input,
    label,
    type,
    meta,
    help,
    children,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      {children}
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const formatFiles = value => {
  if (!value) return [];
  const keys = Object.keys(value);
  return (keys || []).map(key => value[key]);
};

const InputFileUpload = (
  {
    input,
    label,
    type,
    meta,
    help,
    multiple,
    ...props
  }
) => {
  const handleFileChange = e => {
    e.preventDefault();
    const { files: inputFiles } = e.target;
    if (!multiple) return input.onChange([...inputFiles]);
    input.onChange([...input.value, ...inputFiles]);
  };
  return (
    <Form.Group controlId={input.name} className={getValidationState(meta, true)}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <div className="file-upload">
          <div className="btn-file-upload btn btn-secondary">
            Choose Files
            <Form.Control
              value=""
              type="file"
              name={input.name}
              onChange={handleFileChange}
              multiple={multiple || false}
              {...props || {}}
            />
          </div>
        </div>
        {help && <Form.Text>{help}</Form.Text>}
        {meta.invalid &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const InputFileDropzone = (
  {
    input,
    label,
    type,
    meta,
    help,
    multiple,
    disabled,
    ...props
  }
) => {
  const onDrop = useCallback(
    files => {
      if (!multiple) {
        input.onChange(files[0]);
        return;
      }
      input.onChange(files);
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Form.Group
      controlId={input.name}
      className={getValidationState(
        Object.assign({}, meta, { touched: meta.touched || meta.dirty })
      )}
    >
      <div className="file-import" {...getRootProps()}>
        {label && <Form.Label>{label}</Form.Label>}
        <div className="form-container">
          <div className="file-upload">
            <Form.Control
              value=""
              type="file"
              name={input.name}
              {...getInputProps({
                disabled: disabled || false,
                multiple: multiple || false
              })}
            />
            {isDragActive
              ? <p>Drop the files here ...</p>
              : <p>Drag 'n' drop some files here, or click to select files</p>}
          </div>
        </div>
      </div>
      {help && <Form.Text>{help}</Form.Text>}
      {(meta.touched || meta.dirty) &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </Form.Group>
  );
};

const InputToggle = (
  {
    input,
    label,
    meta,
    required,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    <div className="form-container">
      {props.type === 'radio' &&
        <Toggle.Radio {...input} {...props} label={label} />}
      {props.type === 'checkbox' &&
        <Toggle.Checkbox {...input} {...props} label={label} />}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const Textarea = (
  {
    input,
    label,
    type,
    meta,
    required,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} type={type} {...props} as="textarea" />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const Datepicker = (
  {
    input,
    label,
    meta,
    help,
    required,
    disableWeekends,
    extraProps,
    maxDate,
    ...props
  }
) => {
  return (
    <Form.Group
      controlId={input.name}
      className={classNames('form-group-datepicker', getValidationState(meta))}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <div
        className={
          disableWeekends ? 'form-container disable-weekends' : 'form-container'
        }
      >
        <DatePicker
          className="form-control"
          onChange={value => input.onChange(value)}
          onFocus={input.onFocus}
          onBlur={e => input.onBlur()}
          dateFormat="MM/dd/yyyy"
          maxDate={maxDate || null}
          selected={
            input.value && moment(input.value).isValid()
              ? moment(input.value).toDate()
              : null
          }
          {...extraProps}
        />
        <Icon name="calendar" />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

export default {
  Input,
  InputGroup,
  InputFileUpload,
  InputFileDropzone,
  InputToggle,
  Textarea,
  SelectHTML,
  SelectReact,
  SelectReactAsync,
  Creatable,
  Datepicker
};
