import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Modal, Button } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const passwordMatch = (...props) =>{
  const {password, confirmPassword} = props[1] || {};

  return password !== confirmPassword ? 'Passwords do not match' : undefined;
}

const passwordLength = (...props) =>{
  const {password} = props[1] || {};
  return (password || []).length < 8 ? 'Password must be at least 8 characters long' : undefined;
}

const FormEditUser = props => {
  const { handleSubmit, submitting, closeModal } = props;
  return (
    <Modal.Body>

      <form onSubmit={handleSubmit}>

        <Field
          name="password"
          type="password"
          component={FormField.Input}
          label="Password"
          validate={[required, passwordLength]}
        />

        <Field
          name="confirmPassword"
          type="password"
          component={FormField.Input}
          label="Confirm Password"
          validate={[required, passwordMatch, passwordLength]}
        />

      <Modal.Footer>
        <Button variant="success" type='submit' disabled={submitting}>
          Update
        </Button>
        <Button variant="outline-secondary" onClick={closeModal} disabled={submitting}>
          Cancel
        </Button>
      </Modal.Footer>

      </form>
    </Modal.Body>
  );
};

export default reduxForm({
  form: 'edit-password'
})(FormEditUser);
