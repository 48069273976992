import React, { Component } from 'react';
import moment from 'moment';
import Calendar from './calendar/Calendar';
import CalendarHeader from './CalendarHeader';

const defaultRenderEvent = ({ event, style }) => {
  return <div style={style}>EVENT</div>;
};

const today = moment();

const defaultValues = {
  month: today.month(),
  year: today.year(),
  week: today.week()
};

class CalendarBox extends Component {
  constructor(props) {
    super(props);
    this.state = { current: defaultValues };
  }
  componentDidMount() {
    const { loadEvents } = this.props;
    const { current } = this.state;
    if (!loadEvents) return;
    loadEvents(current);
  }
  render() {
    const {
      view,
      events,
      loadEvents,
      calendarProps,
      renderEvent
    } = this.props;

    const { current } = this.state;

    const setDate = value => {
      this.setState({ current: value });
      if (!loadEvents) return;
      loadEvents(value);
    };

    const {
      week,
      month,
      year
    } = current || {};

    return (
      <div className="box-calendar">
        <div className="react-calendar">
          <CalendarHeader
            header
            navigation
            view={view}
            week={week}
            month={month}
            year={year}
            setDate={setDate}
          />
          <Calendar
            view={view}
            week={week}
            month={month}
            year={year}
            events={events}
            eventHeight={55}
            renderEvent={renderEvent || defaultRenderEvent}
            {...calendarProps || {}}
          />
        </div>
      </div>
    );
  }
}

export default CalendarBox;
