import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import { formValueSelector } from 'redux-form';

import FormCancelInspection from './FormCancelInspection';

const ModalCancelInspection = ({
  openModal,
  closeModal,
  name,
  handleCancel,
  deleteReason,
  address,
  city,
  state,
  zip,
}) => {

  return (
    <Modal show={openModal === name} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Inspection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you wish to cancel inspection at the address below?</p>
        <label className="form-label">
          Address
      </label>
        <p>
          {address || ''},<br />
          {city || ''} {zip || ''}, {state || ''}
        </p>
        <FormCancelInspection />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handleCancel(deleteReason)}>
          Cancel Inspection
      </Button>
        <Button variant="outline-secondary" onClick={closeModal}>
          Cancel
      </Button>
      </Modal.Footer>
    </Modal>
  )
};

const selector = formValueSelector('cancel-inspection');

const mapState = state => {
  const deleteReason = selector(state, 'reason');
  return {
    openModal: state.modals,
    deleteReason,
  }
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalCancelInspection);
