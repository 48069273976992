import React from 'react';
import { Button } from 'react-bootstrap';
import Icon from './Icon';
import moment from 'moment';

const getHeaderLabel = (
  {
    view,
    month,
    week,
    year
  }
) => {
  if (view === 'week') {
    const date = moment().year(year).week(week);
    const startDate = moment(date).startOf('week');
    const endDate = moment(date).endOf('week');
    return (
      <h2>
        {startDate.format('MMMM Do')}
        {' - '}
        {endDate.format('MMMM Do, YYYY')}
      </h2>
    );
  }

  const date = moment().year(year).month(month);
  const startDate = moment(date).startOf('month');
  const endDate = moment(date).endOf('month');

  return (
    <h2>
      {startDate.format('MMMM')}
      {' - '}
      {endDate.format('MMMM, YYYY')}
    </h2>
  );
};

const CalendarHeader = (
  {
    header,
    navigation,
    view,
    month,
    year,
    week,
    setDate
  }
) => {
  const headerLabel = getHeaderLabel({ view, month, year, week });
  const handlePrev = () => {
    const numWeeks = moment().year(year).weeksInYear();
    if (week === 1) {
      return setDate({ year: year - 1, week: numWeeks });
    }
    setDate({ year: year, week: week - 1 });
  };
  const handleNext = () => {
    const numWeeks = moment().year(year).weeksInYear();
    if (week + 1 === numWeeks) {
      return setDate({ year: year + 1, week: 1 });
    }
    setDate({ year: year, week: week + 1 });
  };
  const setToday = () => {
    const today = moment();

    const defaultValues = {
      month: today.month(),
      year: today.year(),
      week: today.week()
    };

    setDate(defaultValues);
  };
  return (
    <div className="react-calendar-header">
      {(header || navigation) &&
        <div className="react-calendar-header-body">
          {header &&
            <div className="react-calendar-header-label">{headerLabel}</div>}
          {navigation &&
            <div className="react-calendar-header-actions">
              <Button variant="outline-secondary" onClick={handlePrev}>
                <Icon name="angle-left" />
              </Button>
              <Button variant="outline-secondary" onClick={setToday}>
                Today
              </Button>
              <Button variant="outline-secondary" onClick={handleNext}>
                <Icon name="angle-right" />
              </Button>
            </div>}
        </div>}
    </div>
  );
};

export default CalendarHeader;
