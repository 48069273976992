import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Camera from '../../ui/Camera';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const FormCompleteMaterialPickup = props => {
  const { handleSubmit, submitting, files, images, refreshImages } = props;
  return (
    <div className="box box-form">
      <h5
        style={{
          margin: '0 0 1rem',
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }}
      >
        Complete Material Pickup
      </h5>
      <form onSubmit={handleSubmit}>

        <Row>
          <Col md={6} lg={3}>

            <Field
              name="completeDate"
              type="text"
              component={FormField.Datepicker}
              label="Date Completed"
              validate={[required]}
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="technician"
              type="text"
              component={FormField.Input}
              label="Pickup Technician"
              validate={[required]}
              autoCapitalize="none"
              disabled
            />

          </Col>
          <Col md={6} lg={6}>

            <div className="form-group-spacing">
              <Field
                name="transferred"
                type="checkbox"
                component={FormField.InputToggle}
                label="Transferred to another location"
              />
            </div>

          </Col>
        </Row>

        <Row>
          <Col md={12} lg={6}>

            <Field
              name="address"
              type="text"
              component={FormField.Input}
              label="Address"
              validate={[required]}
              autoCapitalize="none"
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="builder"
              type="text"
              component={FormField.Input}
              label="Builder"
              validate={[required]}
              autoCapitalize="none"
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="fieldSupervisor"
              type="text"
              component={FormField.Input}
              label="Field Supervisor"
              validate={[required]}
              autoCapitalize="none"
              disabled
            />

          </Col>
        </Row>

        <Field
          name="comments"
          type="text"
          component={FormField.Textarea}
          label="Comments"
          autoCapitalize="none"
          rows={5}
        />

        <Camera files={files} images={images} refreshImages={refreshImages} />

        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Complete Material Pickup
          </Button>
          {' '}
          <Link to="/material-pickups" className="btn btn-outline-secondary">
            Cancel
          </Link>
        </div>

      </form>
    </div>
  );
};

const form = reduxForm({
  form: 'complete-material-pickup'
})(FormCompleteMaterialPickup);

const selector = formValueSelector('complete-material-pickup');

const mapState = state => {
  const files = selector(state, 'files');
  return {
    files
  };
};

export default connect(mapState)(form);
