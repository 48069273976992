import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';

import Icon from '../../ui/Icon';
import FormRescheduleInspection from './FormRescheduleInspection';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import InspectionsCalendar from '../../ui/InspectionsCalendar';

class InspectionReschedule extends Component {
  componentDidMount() {
    const {
      getLookups,
      getInspectionById,
      match: { params: { id } }
    } = this.props;
    getLookups();
    getInspectionById(id);
  }
  componentDidUpdate() {
    const {
      result,
      history,
      match: { params: { id } }
    } = this.props;
    const { status } = result || {};
    if (!status) return;
    const isRescheduled = ['reschedule', 'denied'].some(s => s === status);
    if (isRescheduled) return;
    history.push(`/quality-assurance/inspection/${id}`);
  }
  render() {
    const {
      lookups,
      postRescheduleInspection,
      history,
      result,
      inProgress
    } = this.props;

    const {
      _id,
      project,
      comments,
      technicianId
    } = result || {};

    const {
      address,
      city,
      state,
      zip,
      builder,
      fieldSupervisorName
    } = project || {};

    const { name: builderName } = builder || {};

    const { qualityAssuranceTechnicians } = lookups || {};

    const assigneeOptions = (qualityAssuranceTechnicians || []).map(({
      _id,
      firstName,
      lastName
    }) => ({
      value: _id,
      label: `${firstName} ${lastName}`
    }));

    const initialValues = {
      address: `${address} ${city} ${state} ${zip}`,
      builder: builderName,
      fieldSupervisor: fieldSupervisorName,
      technicianId,
      comments
    };

    const handleSubmit = data => {
      const {
        technicianId,
        scheduledDate,
        comments
      } = data || {};
      const payload = {
        technicianId,
        scheduledDate,
        comments
      };
      postRescheduleInspection(
        { id: _id, payload },
        'reschedule-inspection',
        null,
        data => {
          history.push(`/quality-assurance/inspection/${_id}`);
        }
      );
    };

    return (
      <Layout route="quality-assurance-new">

        <div className="layout-header">
          <div>
            <h1>Reschedule Inspection</h1>
            <Link to="/quality-assurance">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        {!inProgress &&
          <FormRescheduleInspection
            onSubmit={handleSubmit}
            assigneeOptions={assigneeOptions}
            initialValues={initialValues}
            project={project}
          />}
        <InspectionsCalendar hideNav />
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: lookups
  } = state.getLookups.toJS();
  const {
    result,
    inProgress
  } = state.getInspectionById.toJS();
  return {
    lookups,
    result,
    inProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getLookups: fetchDux.getLookups.createAction,
      getInspectionById: fetchDux.getInspectionById.createAction,
      postRescheduleInspection: fetchDux.postRescheduleInspection.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(InspectionReschedule);
