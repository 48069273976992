import React, { Component } from 'react';
import Layout from '../../ui/Layout';
import FormCompleteMaterialPickup from './FormCompleteMaterialPickup';
import Icon from '../../ui/Icon';
import Loading from '../../ui/Loading';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';

const getCurrentInspection = p => {
  const [current] = p || [];
  const { status } = current || {};
  if (status === 'denied') return {};
  return current || {};
};

class MaterialPickupsComplete extends Component {
  componentDidMount() {
    const { match, getMaterialPickupById } = this.props;
    const { params } = match || {};
    const { id } = params || {};

    getMaterialPickupById(id);
  }
  render() {
    const {
      result,
      inProgress,
      postCompleteMaterialPickup,
      getMaterialPickupById,
      history
    } = this.props;

    const {
      _id,
      project,
      technician,
      inspections,
      comments: supervisorComments
    } = result || {};

    const {
      name: projectName,
      address,
      city,
      state,
      zip,
      builder,
      fieldSupervisorName
    } = project || {};

    const currentInspection = getCurrentInspection(inspections);

    const {
      transferred,
      comments,
      completeDate,
      images
    } = currentInspection || {};

    const { name: builderName } = builder || {};
    const { firstName, lastName } = technician || {};

    const technicianName = `${firstName} ${lastName}`;

    const handleSubmit = data => {
      const {
        completeDate,
        comments = '',
        transferred,
        files
      } = data || {};

      const payload = {
        completeDate,
        comments,
        transferred,
        files
      };

      postCompleteMaterialPickup(
        { id: _id, payload },
        'complete-material-pickup',
        null,
        () => history.push('/material-pickups')
      );
    };

    const refreshImages = () => getMaterialPickupById(_id, null, true);

    const initialValues = {
      address: `${address} ${city} ${state} ${zip}`,
      builder: builderName,
      fieldSupervisor: fieldSupervisorName,
      technician: technicianName,
      completeDate: completeDate ? new Date(completeDate) : new Date(),
      transferred,
      comments: comments || ''
    };

    return (
      <Layout route="material-pickups-complete">

        <div className="layout-header">
          <div>
            <h1>{`${projectName}, ${address}, ${city}, ${state}`}</h1>
            <Link to="/material-pickups">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        {supervisorComments &&
          <div className="box">
            <div className="box-header">
              <h3>Supervisor Comments</h3>
            </div>
            <div className="box-body">
              {supervisorComments}
            </div>
          </div>}

        {!inProgress &&
          <FormCompleteMaterialPickup
            onSubmit={handleSubmit}
            initialValues={initialValues}
            images={images}
            refreshImages={refreshImages}
          />}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getMaterialPickupById.toJS();

  return {
    result,
    inProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getMaterialPickupById: fetchDux.getMaterialPickupById.createAction,
      postCompleteMaterialPickup: fetchDux.postCompleteMaterialPickup.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MaterialPickupsComplete);
