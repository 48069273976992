import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const FormImportAddresses = props => {
  const { handleSubmit, submitting, file } = props;
  const { name } = file || {};
  return (
    <div className="box box-form">
      <form onSubmit={handleSubmit}>

        <div style={{ display: 'inline-block' }}>
          <Field
            name="files"
            type="text"
            component={FormField.InputFileUpload}
            label="Addresses CSV file"
            validate={[required]}
            accept=".csv"
          />
        </div>
        <span style={{ marginLeft: '10px' }}>
          {name}
        </span>
        <br />
        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Import Addresses
          </Button>
        </div>

      </form>
    </div>
  );
};

const formName = 'import-addresses';

const selector = formValueSelector(formName);

const mapState = state => {
  const files = selector(state, 'files') || [];
  const file = files[0] || {};
  return {
    file
  };
};

const form = reduxForm({
  form: formName
})(FormImportAddresses);

export default connect(mapState)(form);
