import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';

import Icon from '../../ui/Icon';
import FormEditInspection from './FormEditInspection';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import InspectionsCalendar from '../../ui/InspectionsCalendar';
import Notification from '../../ui/Notification';

class QualityAssuranceEdit extends Component {
  componentWillMount() {
    const { 
      getLookups, 
      getInspectionById,
      match: { params: { id } }
    } = this.props;
    getInspectionById(id)
    getLookups();
  }
  componentWillUnmount() {
    const { 
      clearGetInspectionById,
      clearGetLookups,
      clearPutInspection
    } = this.props;

    clearGetInspectionById();
    clearGetLookups();
    clearPutInspection();
  }
  render() {
    const {
      lookups,
      lookupsError,
      clearGetLookups,
      history,
      result,
      getInspectionByIdError,
      getInspectionByIdInProgress,
      clearGetInspectionById,
      putInspection,
      putInspectionError,
      putInspectionInProgress,
      clearPutInspection
    } = this.props;

    const {
      _id,
      project,
      type,
      scheduledDate,
      technician,
      comments,
    } = result || {};

    const{
      _id:technicianId
    } = technician || {};

    const {
      address,
      city,
      state,
      zip,
    } = project || {};

    const projectAddress = `${address} ${city} ${state} ${zip}`;

    const initialValues = {
      projectAddress,
      type,
      technicianId,
      scheduledDate,
      project,
      comments
    };

    const { qualityAssuranceTechnicians } = lookups || {};

    const assigneeOptions = (qualityAssuranceTechnicians || []).map(({
      _id,
      firstName,
      lastName
    }) => ({
      value: _id,
      label: `${firstName} ${lastName}`
    }));

    const handleSubmit = payload => {
      putInspection({ id:_id, payload }, 'edit-rough-inspection', null, data => {
        history.push(`/quality-assurance/inspection/${_id}`);
      });
    };

    return (
      <Layout route="quality-assurance-new">

        <div className="layout-header">
          <div>
            <h1>Edit Inspection</h1>
            <Link to={`/quality-assurance/inspection/${_id}`}>
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        <FormEditInspection
          onSubmit={handleSubmit}
          assigneeOptions={assigneeOptions}
          initialValues={initialValues}
        />

        <InspectionsCalendar
          hideNav
        />

        {lookupsError &&
          <Notification
            key="lookups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetLookups}
          >
            Error loading Q/A technicians.
          </Notification>}
        {getInspectionByIdError &&
          <Notification
            key="get-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetInspectionById}
          >
            Error loading inspection details.
          </Notification>}
        {putInspectionError &&
          <Notification
            key="get-pickup-by-id-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPutInspection}
          >
            Error updating inspection.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: lookups,
    error:lookupsError
  } = state.getLookups.toJS();
  const {
    result,
    error:getInspectionByIdError,
    inProgress:getInspectionByIdInProgress
  } = state.getInspectionById.toJS();
  const {
    error:putInspectionError,
    inProgress:putInspectionInProgress
  } = state.putInspection.toJS();
  return {
    lookups,
    result,
    getInspectionByIdError,
    getInspectionByIdInProgress,
    putInspectionError,
    putInspectionInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getLookups: fetchDux.getLookups.createAction,
      clearGetLookups: fetchDux.getLookups.clearAction,
      putInspection: fetchDux.putInspection.createAction,
      clearPutInspection: fetchDux.putInspection.clearAction,
      getInspectionById: fetchDux.getInspectionById.createAction,
      clearGetInspectionById: fetchDux.getInspectionById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(QualityAssuranceEdit);
