import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../state/modals-dux';

const CameraModal = ({ openModal, closeModal, imgUrl }) => {
  return (
    <Modal show={openModal === 'CameraModal'} onHide={closeModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Photo Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {imgUrl && <img style={{ width: '100%' }} src={imgUrl} alt="Preview" />}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(CameraModal);
