import {
  getJson,
  postJson,
  putJson,
  patchJson,
  delJson,
  del,
  postFile,
  getFile
} from './fetch';

import localForage from 'localforage';
import download from 'downloadjs';

const apiBase = process.env.REACT_APP_API_BASE;

export const postLogin = payload =>
  postJson({
    path: `${apiBase}/identity/login`,
    payload
  }).then(result => {
    localForage.setItem('identity', result);
    return result;
  });

export const validateLogin = () =>
  getJson({
    path: `${apiBase}/identity/validate`
  });

export const getLookups = () =>
  getJson({
    path: `${apiBase}/lookups`
  });

// Projects
export const postProject = payload =>
  postJson({
    path: `${apiBase}/projects`,
    payload
  });

export const putProject = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/projects/${id}`,
    payload
  });

export const getProjects = qs =>
  getJson({
    path: `${apiBase}/projects?${qs || ''}`
  });

export const getProjectById = id =>
  getJson({
    path: `${apiBase}/projects/${id}`
  });

export const deleteProjectById = ({ id, payload }) =>
  delJson({
    path: `${apiBase}/projects/${id}`,
    payload
  });

export const getProjectLogsById = id =>
  getJson({
    path: `${apiBase}/projects/${id}/logs`
  });

export const postImportProjects = ({ payload }) =>
  postFile({
    path: `${apiBase}/projects/import`,
    payload
  });

export const getProjectImport = id =>
  getJson({
    path: `${apiBase}/projects/import/${id}`
  });

// Addresses
export const downloadAddressesCSV = ({ payload = {}, Filename }) =>
  getFile({
    path: `${apiBase}/projects/export`,
    payload
  })
    .then(function(response) {
      return response.blob();
    })
    .then(function(blob) {
      download(blob, Filename);
    });

// Material Pickup
export const postMaterialPickup = payload =>
  postFile({
    path: `${apiBase}/material-pickups`,
    payload
  });

export const getMaterialPickupById = id =>
  getJson({
    path: `${apiBase}/material-pickups/${id}`
  });

export const getMaterialPickups = qs =>
  getJson({
    path: `${apiBase}/material-pickups?${qs || ''}`
  });

export const putMaterialPickup = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/material-pickups/${id}`,
    payload
  });

export const deleteMaterialPickupById = ({ id, payload }) =>
  delJson({
    path: `${apiBase}/material-pickups/${id}`,
    payload
  });

export const postCompleteMaterialPickup = ({ id, payload }) =>
  postFile({
    path: `${apiBase}/material-pickups/${id}/complete`,
    payload
  });

export const postDenyMaterialPickup = ({ id, payload }) =>
  postFile({
    path: `${apiBase}/material-pickups/${id}/deny`,
    payload
  });

export const patchMaterialPickupById = ({ id, payload }) =>
  patchJson({
    path: `${apiBase}/material-pickups/${id}`,
    payload
  });

// Quality Assurance
export const getInspections = qs =>
  getJson({
    path: `${apiBase}/inspections?${qs || ''}`
  });

export const postAssignInspection = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/inspections/${id}/assign`,
    payload
  });

export const postScheduleInspection = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/inspections/${id}/schedule`,
    payload
  });

export const getInspectionEvents = qs =>
  getJson({
    path: `${apiBase}/inspections?${qs || ''}`
  });

export const getInspectionById = id =>
  getJson({
    path: `${apiBase}/inspections/${id}`
  });

export const deleteFileById = id =>
  del({
    path: `${apiBase}/files/${id}`
  });

export const postResolveInspectionItem = ({ id, resultId, payload }) =>
  postFile({
    path: `${apiBase}/inspections/${id}/result/${resultId}/resolve`,
    payload
  });

export const postInspection = ({ payload }) =>
  postJson({
    path: `${apiBase}/inspections`,
    payload
  });

export const putInspection = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/inspections/${id}`,
    payload
  });

export const postRescheduleInspection = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/inspections/${id}/reschedule`,
    payload
  });

export const deleteInspectionById = ({ id, payload }) =>
  delJson({
    path: `${apiBase}/inspections/${id}`,
    payload
  });

export const pauseInspectionById = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/inspections/${id}/pause`,
    payload
  });

export const resumeInspectionById = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/inspections/${id}/resume`,
    payload
  });

// Builders
export const postBuilder = payload =>
  postJson({
    path: `${apiBase}/admin/builders`,
    payload
  });

export const putBuilder = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/admin/builders/${id}`,
    payload
  });

export const deleteBuilder = id =>
  del({
    path: `${apiBase}/admin/builders/${id}`
  });

export const getBuilders = () =>
  getJson({
    path: `${apiBase}/admin/builders`
  });

export const getBuilderById = id =>
  getJson({
    path: `${apiBase}/admin/builders/${id}`
  });

// User
export const getUsers = () =>
  getJson({
    path: `${apiBase}/admin/users`
  });

export const postUser = payload =>
  postJson({
    path: `${apiBase}/admin/users`,
    payload
  });

export const putUser = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/admin/users/${id}`,
    payload
  });

export const patchPassword = ({ id, payload }) =>
  patchJson({
    path: `${apiBase}/admin/users/${id}/password`,
    payload
  });

export const getUserById = id =>
  getJson({
    path: `${apiBase}/admin/users/${id}`
  });

export const deleteUserById = id =>
  del({
    path: `${apiBase}/admin/users/${id}`
  });

export const checkUsername = username =>
  getJson({
    path: `${apiBase}/admin/users/check/${username}`
  });

export const getDashboardStats = () =>
  getJson({
    path: `${apiBase}/dashboard/stats`
  });
