import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import BuildersSearch from './BuildersSearch.js';
import BuildersNew from './BuildersNew.js';
import BuildersDetail from './BuildersDetail.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={BuildersSearch} />
    <Route exact path={`${match.url}/new`} component={BuildersNew} />
    <Route exact path={`${match.url}/:id`} component={BuildersDetail} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
