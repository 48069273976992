import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import { formValueSelector } from 'redux-form';

import FormDeleteAddress from './FormDeleteAddress';

const ModalDeleteAddress = (
  { openModal, closeModal, name, projectName, handleDelete, deleteReason }
) => (
  <Modal show={openModal === name} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Delete Project</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Are you sure you wish to delete address <b>{projectName}</b>?</p>
      <FormDeleteAddress />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={() => handleDelete(deleteReason)}>
        Delete Project
      </Button>
      <Button variant="outline-secondary" onClick={closeModal}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>
);

const selector = formValueSelector('delete-address');

const mapState = state => {
  const deleteReason = selector(state, 'reason');
  return {
    openModal: state.modals,
    deleteReason
  };
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalDeleteAddress);
