import React, { Component } from 'react';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import InspectionAssignCard from '../../ui/InspectionAssignCard';
import InspectionScheduleCard from '../../ui/InspectionScheduleCard';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import InspectionsCalendar from '../../ui/InspectionsCalendar';
import { filter } from 'lodash';
import classNames from 'classnames';

const setSessionFilter = filter => {
  try {
    window.sessionStorage.setItem('inspections-filter', filter);
  } catch (err) {}
};

const getSessionFilter = () => {
  try {
    return window.sessionStorage.getItem('inspections-filter');
  } catch (err) {
    return null;
  }
};

const filterStatus = filter =>
  ({ type }) => {
    if (filter === 'all') return true;
    return type === filter;
  };

class QualityAssuranceCalendar extends Component {
  constructor(props) {
    super(props);
    const filter = getSessionFilter();
    this.state = {
      filter: filter || 'roughInspection'
    };
  }
  componentWillMount() {
    const {
      getInspections,
      getLookups
    } = this.props;

    getInspections(
      'status=pending,draft,denied,failed,criticalFailed,reschedule&pageSize=-1'
    );
    getLookups();
  }
  render() {
    const {
      result,
      lookups,
      postAssignInspection,
      getInspections,
      postAssignInspectionInProgress,
      postScheduleInspection,
      postScheduleInspectionInProgress,
      getInspectionEvents
    } = this.props;

    const {
      filter
    } = this.state;

    const {
      inspections
    } = result || {};

    const { qualityAssuranceTechnicians } = lookups || {};

    const setFilter = value =>
      () => {
        this.setState({ filter: value });
        setSessionFilter(value);
      };

    const assigneeOptions = (qualityAssuranceTechnicians || []).map(({
      _id,
      firstName,
      lastName
    }) => ({
      value: _id,
      label: `${firstName} ${lastName}`
    }));

    const unscheduledInspections = (inspections || [])
      .filter(({ scheduledDate }) => !scheduledDate)
      .filter(filterStatus(filter));

    const unassignedInspections = (inspections || [])
      .filter(
        ({ technicianId, scheduledDate }) => !technicianId && !!scheduledDate
      )
      .filter(filterStatus(filter));

    const assignedInspections = (inspections || [])
      .filter(
        ({ technicianId, scheduledDate }) => !!technicianId && !!scheduledDate
      )
      .filter(filterStatus(filter));

    return (
      <Layout route="quality-assurance-calendar">

        <div className="layout-header">
          <div>
            <h1>Quality Assurance</h1>
          </div>
          <div>
            <Link
              to="/quality-assurance/new"
              className="btn btn-success btn-icon"
            >
              Request Inspection <Icon name="plus" />
            </Link>
          </div>
        </div>

        <div className="box-nav">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <button
                className={classNames('nav-link', { active: filter === 'all' })}
                onClick={setFilter('all')}
              >
                All
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: filter === 'roughInspection'
                })}
                onClick={setFilter('roughInspection')}
              >
                Rough
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: filter === 'finalInspection'
                })}
                onClick={setFilter('finalInspection')}
              >
                Final
              </button>
            </li>
          </ul>
        </div>

        <Row>
          <Col md={4} xl={4}>

            <div className="box-inspections-list">
              <h5>{unscheduledInspections.length} Unscheduled Inspections</h5>
              <div className="inspections-list-container">

                {(unscheduledInspections || [])
                  .map((p, i) => (
                    <InspectionScheduleCard
                      key={p._id}
                      {...p}
                      postScheduleInspection={postScheduleInspection}
                      postScheduleInspectionInProgress={
                        postScheduleInspectionInProgress
                      }
                      getInspections={getInspections}
                    />
                  ))}
                {(unscheduledInspections || []).length === 0 &&
                  <div className="empty-list">
                    No Unscheduled Inspections Found
                  </div>}
              </div>
            </div>

          </Col>
          <Col md={4} xl={4}>

            <div className="box-inspections-list">
              <h5>{unassignedInspections.length} Unassigned Inspections</h5>
              <div className="inspections-list-container">

                {(unassignedInspections || [])
                  .map((p, i) => (
                    <InspectionAssignCard
                      key={p._id}
                      {...p}
                      assigneeOptions={assigneeOptions}
                      postAssignInspection={postAssignInspection}
                      getInspections={getInspections}
                      postAssignInspectionInProgress={
                        postAssignInspectionInProgress
                      }
                      reversePopupPlacement={i > 2}
                    />
                  ))}
                {(unassignedInspections || []).length === 0 &&
                  <div className="empty-list">
                    No Unassigned Inspections Found
                  </div>}
              </div>
            </div>

          </Col>
          <Col md={4} xl={4}>

            <div className="box-inspections-list">
              <h5>{assignedInspections.length} Assigned Inspections</h5>
              <div className="inspections-list-container">

                {(assignedInspections || [])
                  .map((p, i) => (
                    <InspectionAssignCard
                      key={p._id}
                      {...p}
                      assigneeOptions={assigneeOptions}
                      postAssignInspection={postAssignInspection}
                      getInspections={getInspections}
                      postAssignInspectionInProgress={
                        postAssignInspectionInProgress
                      }
                      reversePopupPlacement={i > 2}
                    />
                  ))}
                {(assignedInspections || []).length === 0 &&
                  <div className="empty-list">
                    No Assigned Inspections Found
                  </div>}

              </div>
            </div>

          </Col>
        </Row>
        {!postScheduleInspectionInProgress && <InspectionsCalendar isQuality />}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result
  } = state.getInspections.toJS();

  const {
    result: lookups
  } = state.getLookups.toJS();

  const {
    inProgress: postAssignInspectionInProgress
  } = state.postAssignInspection.toJS();

  const {
    inProgress: postScheduleInspectionInProgress
  } = state.postScheduleInspection.toJS();

  return {
    result,
    lookups,
    postAssignInspectionInProgress,
    postScheduleInspectionInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getInspections: fetchDux.getInspections.createAction,
      getLookups: fetchDux.getLookups.createAction,
      postAssignInspection: fetchDux.postAssignInspection.createAction,
      postScheduleInspection: fetchDux.postScheduleInspection.createAction,
      getInspectionEvents: fetchDux.getInspectionEvents.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(QualityAssuranceCalendar);
