import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Button, Alert } from 'react-bootstrap';
// import Map from '../../ui/Map';
import Layout from '../../ui/Layout';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/modals-dux';
import { authenticatedHref } from '../../../api/fetch';

import InspectionReportGroup from './InspectionReportGroup';
import ImagePreviewModal from '../../ui/CameraModal';
import Icon from '../../ui/Icon';
import Notification from '../../ui/Notification';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InspectionStatus from './InspectionStatus';
import ModalCancelInspection from './ModalCancelInspection';
import ModalPauseInspection from './ModalPauseInspection';
import ModalResumeInspection from './ModalResumeInspection';
import Loading from '../../ui/Loading';

const getChecklist = checklist => {
  const { values } = checklist || {};
  return values;
};

const getCurrent = p => {
  const [current] = p || [];
  return current;
};

const getPauseReason = r => {
  switch (r) {
    case 'stolenParts':
      return 'Stolen Parts';
      break;
    case 'builder':
      return 'Builder';
      break;
    case 'fieldSupervisor':
      return 'Field Supervisor';
      break;
    case 'other':
      return 'Other';
      break;
  }
};

class InspectionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: ''
    };
  }
  // componentDidMount() {
  //   const { getInspectionById, match: { params: { id } } } = this.props;
  //   getInspectionById(id);
  // }

  static getDerivedStateFromProps(nextProps, currentState) {
    const { getInspectionById, match: { params: { id } } } = nextProps;
    if (currentState.id !== id) {
      getInspectionById(id);
      return {
        id: id
      };
    }
    return null;
  }

  render() {
    const {
      result,
      showModal,
      clearGetInspectionById,
      getInspectionByIdInProgress,
      getInspectionByIdError,
      deleteInspectionById,
      clearDeleteInspectionById,
      deleteInspectionByIdInProgress,
      deleteInspectionByIdError,
      pauseInspectionById,
      pauseInspectionByIdError,
      clearPauseInspectionById,
      resumeInspectionById,
      resumeInspectionByIdError,
      clearResumeInspectionById,
      history,
      user,
      getInspectionById
    } = this.props;

    const {
      roleId
    } = user || {};
    const isAdmin = roleId === 'administrator';

    const {
      imgUrl
    } = this.state;
    const {
      _id,
      project,
      type,
      status,
      scheduledDate,
      technician,
      inspections,
      images,
      comments,
      checklist: checklistValue,
      isPausedInspection,
      pauseReason,
      pauseComments
    } = result || {};

    const {
      _id: projectId,
      name: projectName,
      builderName,
      fieldSupervisorName,
      address,
      city,
      state,
      zip,
      lat,
      lng,
      partialMatch
    } = project || {};

    const checklist = getChecklist(checklistValue);

    const { firstName, lastName } = technician || {};

    const technicianName = technician
      ? `${firstName} ${lastName}`
      : 'Unassigned';

    const current = getCurrent(inspections);

    const {
      completeDate,
      comments: inspectionComments,
      reason,
      noPower,
      inspections: currentInspection,
      otherReason
    } = current || {};

    const { label: reasonMessage } = reason || {};

    const handlePreview = image =>
      () => {
        this.setState({ imgUrl: image });
        showModal('CameraModal');
      };

    const handleCancel = deleteReason => {
      deleteInspectionById(
        { id: _id, payload: { deleteReason } },
        'cancel-inspection',
        null,
        () => {
          history.push(`/quality-assurance/`);
        }
      );
    };

    const handlePause = (pauseReason, comments) => {
      pauseInspectionById(
        { id: _id, payload: { pauseReason, comments } },
        'pause-inspection',
        null,
        () => getInspectionById(_id)
      );
    };

    const handleResume = (scheduledDate, comments) => {
      resumeInspectionById(
        { id: _id, payload: { scheduledDate, comments } },
        'resume-inspection',
        null,
        () => getInspectionById(_id)
      );
    };

    const showInspectionReport = !['denied', 'pending'].some(s => s === status);
    const showDenied = status === 'denied';
    const printInspection = () => window.print();

    if (getInspectionByIdInProgress) {
      return (
        <Layout route="inspection-detail">
          <Loading />
        </Layout>
      );
    }

    return (
      <Layout route="inspection-detail">

        <div className="layout-header">
          <div>
            <h1 style={{ whiteSpace: 'nowrap' }}>
              <span className="type blue">
                {type === 'roughInspection' ? 'Rough' : 'Final'}
              </span>
              {' '}
              Inspection
              {' '}
              <span>{projectName}</span>
            </h1>
            <Link to="/quality-assurance">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
          <div style={{ whiteSpace: 'nowrap' }}>
            <Button
              className="btn btn-secondary btn-icon"
              onClick={printInspection}
            >
              Print <Icon name="print" />
            </Button>
            {status !== 'passed' &&
              !isPausedInspection &&
              <Button
                className="btn btn-danger btn-icon"
                onClick={() => showModal('PauseInspectionModal')}
              >
                Pause Inspection
              </Button>}
            {(status === 'reschedule' || status === 'denied') &&
              <Link
                className="btn btn-primary"
                to={`/quality-assurance/inspection/${_id}/reschedule`}
              >
                Reschedule
              </Link>}
            {(status === 'failed' || status === 'criticalFailed') &&
              <Link
                className="btn btn-primary"
                to={`/quality-assurance/inspection/${_id}/resolve`}
              >
                Resolve Inspection Failures
              </Link>}
            <Link
              className="btn btn-primary btn-icon"
              to={`/quality-assurance/inspection/${_id}/edit`}
            >
              Edit <Icon name="wrench" />
            </Link>
            {isAdmin &&
              status !== 'passed' &&
              <Button
                className="btn btn-danger btn-icon"
                onClick={() => showModal('CancelInspectionModal')}
              >
                Cancel <Icon name="times" />
              </Button>}

          </div>
        </div>

        {!getInspectionByIdInProgress &&
          isPausedInspection &&
          <Alert variant="primary">
            <div>
              <Icon name="info-circle" />
              {' '}
              This Inspection is
              {' '}
              <strong>paused</strong>
              <Button
                size="sm"
                variant="primary"
                style={{ marginLeft: '20px' }}
                onClick={() => showModal('ResumeInspectionModal')}
              >
                Resume Inspection
              </Button>
            </div>
            <div>
              <br /><strong>Reason:</strong> {getPauseReason(pauseReason)}
            </div>
            {pauseComments &&
              <div><strong>Comments:</strong> {pauseComments}</div>}
          </Alert>}

        <Row>
          <Col md={12} xl={8}>

            <Row>
              <Col md={6}>

                <div className="box">
                  <div className="box-header">
                    <h3>Inspection</h3>
                  </div>
                  <div className="box-body">
                    <table className="table data-table">
                      <tbody>
                        <tr>
                          <th>Result</th>
                          <td>
                            <InspectionStatus status={status} />
                          </td>
                        </tr>
                        <tr>
                          <th>Type</th>
                          <td>
                            {type === 'roughInspection' &&
                              <span>Rough Inspection</span>}
                            {type === 'finalInspection' &&
                              <span>Final Inspection</span>}
                          </td>
                        </tr>
                        <tr>
                          <th>Scheduled Inpsection Date</th>
                          <td>
                            {scheduledDate &&
                              moment(scheduledDate).isValid() &&
                              moment(scheduledDate).format('MM/DD/YYYY')}
                          </td>
                        </tr>
                        <tr>
                          <th>QA Technician</th>
                          <td>{technicianName}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="box">
                  <div className="box-header">
                    <h3>
                      Project
                      {/* {partialMatch &&
                        <span style={{ color: 'red' }}>
                          {' '}(Address Partial Match)
                        </span>} */}
                    </h3>
                  </div>
                  <div className="box-body">
                    <table className="table data-table">
                      <tbody>
                        <tr>
                          <th>Project</th>
                          <td>{projectName}</td>
                        </tr>
                        <tr>
                          <th>Builder</th>
                          <td>{builderName}</td>
                        </tr>
                        <tr>
                          <th>Address</th>
                          <td>
                            <Link to={`/addresses/${projectId}`}>
                              {address}, {city} {zip}, {state}
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th>Field Supervisor</th>
                          <td>{fieldSupervisorName}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </Col>
            </Row>

            <div className="box">
              <div className="box-header">
                <h3>Comments</h3>
              </div>
              {comments &&
                <div className="box-body">
                  {comments}
                </div>}
              {!comments &&
                <div className="box-body box-empty">
                  <em>No Comments Found</em>
                </div>}
            </div>
            {showDenied &&
              <Row>
                <Col md={6}>
                  <div className="box">
                    <div className="box-header">
                      <h3>Inspection Result</h3>
                    </div>
                    <div className="box-body">
                      <table className="table data-table">
                        <tbody>
                          <tr>
                            <th>Date Denied</th>
                            <td>
                              {completeDate &&
                                moment(completeDate).isValid() &&
                                moment(completeDate).format('MM/DD/YYYY')}
                            </td>
                          </tr>
                          <tr>
                            <th>Reason</th>
                            <td>{reasonMessage}</td>
                          </tr>
                          {reasonMessage === 'Other' &&
                            <tr>
                              <th>Alternate Reason</th>
                              <td>{otherReason}</td>
                            </tr>}
                          <tr>
                            <th>Comments</th>
                            <td>{inspectionComments}</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>}

          </Col>
          <Col md={6} xl={4}>

            {/* <Map geocodes={lat && lng ? [{ lat, lng }] : []} squareSize /> */}

          </Col>

        </Row>

        {showInspectionReport &&
          <div className="report">
            <h5 className="h5-inline-blocks">
              <span>Inspection Report</span>
              {noPower &&
                <div className="status red" style={{ marginLeft: '15px' }}>
                  <Icon name="exclamation-triangle" /> No Power
                </div>}
            </h5>
            <Row>
              {(checklist || []).map((group, i) => {
                const { name } = group || {};
                const values = currentInspection[name] || {};
                return (
                  <Col md={6} lg={4} key={i}>
                    <InspectionReportGroup checklist={group} values={values} />
                  </Col>
                );
              })}
            </Row>
            <div className="box">
              <div className="box-header">
                <h3>Inspection Comments</h3>
              </div>
              {inspectionComments &&
                <div className="box-body">
                  {inspectionComments}
                </div>}
              {!inspectionComments &&
                <div className="box-body box-empty">
                  <em>No Comments Found</em>
                </div>}
            </div>
          </div>}

        {(images || []).length > 0 &&
          <div>
            <h5 className="gallery-header">Gallery</h5>

            <div className="box-photos">
              {(images || []).map(({ _id }, i) => {
                const href = authenticatedHref(`files/${_id}`);
                return (
                  <div className="photo" key={i}>
                    <div
                      className="image"
                      onClick={handlePreview(href)}
                      style={{ backgroundImage: `url(${href})` }}
                    />
                  </div>
                );
              })}
            </div>
          </div>}

        <ImagePreviewModal imgUrl={imgUrl} />
        <ModalCancelInspection
          name="CancelInspectionModal"
          handleCancel={handleCancel}
          address={address}
          city={city}
          state={state}
          zip={zip}
        />
        <ModalPauseInspection
          name="PauseInspectionModal"
          handlePause={handlePause}
          address={address}
          city={city}
          state={state}
          zip={zip}
        />
        <ModalResumeInspection
          name="ResumeInspectionModal"
          handleResume={handleResume}
          address={address}
          city={city}
          state={state}
          zip={zip}
        />

        {getInspectionByIdError &&
          <Notification
            key="get-inspection-by-id-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetInspectionById}
          >
            Error loading inspection details.
          </Notification>}
        {deleteInspectionByIdError &&
          <Notification
            key="delete-inspection-by-id-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearDeleteInspectionById}
          >
            Error canceling inspection.
          </Notification>}
        {pauseInspectionByIdError &&
          <Notification
            key="pause-inspection-by-id-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPauseInspectionById}
          >
            Error pausing inspection.
          </Notification>}

        {resumeInspectionByIdError &&
          <Notification
            key="pause-inspection-by-id-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPauseInspectionById}
          >
            Error resuming inspection.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getInspectionByIdInProgress,
    error: getInspectionByIdError
  } = state.getInspectionById.toJS();

  const {
    inProgress: deleteInspectionByIdInProgress,
    error: deleteInspectionByIdError
  } = state.deleteInspectionById.toJS();

  const {
    inProgress: pauseInspectionByIdInProgress,
    error: pauseInspectionByIdError
  } = state.pauseInspectionById.toJS();

  const {
    inProgress: resumeInspectionByIdInProgress,
    error: resumeInspectionByIdError
  } = state.resumeInspectionById.toJS();

  return {
    result,
    getInspectionByIdInProgress,
    getInspectionByIdError,
    deleteInspectionByIdInProgress,
    deleteInspectionByIdError,
    pauseInspectionByIdInProgress,
    pauseInspectionByIdError,
    resumeInspectionByIdInProgress,
    resumeInspectionByIdError,
    user: state.login.toJS().result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getInspectionById: fetchDux.getInspectionById.createAction,
      clearGetInspectionById: fetchDux.getInspectionById.clearAction,
      deleteInspectionById: fetchDux.deleteInspectionById.createAction,
      clearDeleteInspectionById: fetchDux.deleteInspectionById.clearAction,
      pauseInspectionById: fetchDux.pauseInspectionById.createAction,
      clearPauseInspectionById: fetchDux.pauseInspectionById.clearAction,
      resumeInspectionById: fetchDux.resumeInspectionById.createAction,
      clearResumeInspectionById: fetchDux.resumeInspectionById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(InspectionDetail);
