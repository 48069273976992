import React from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import FormEditBuilder from './FormEditBuilder';

const ModalEditUser = ({ openModal, closeModal, name, builder, handleSubmit, submitting }) => (
  <Modal show={openModal === name} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Edit Builder</Modal.Title>
    </Modal.Header>
    <FormEditBuilder
      initialValues={builder}
      onSubmit={handleSubmit}
      closeModal={closeModal}
      submitting={submitting}
    />
  </Modal>
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalEditUser);
