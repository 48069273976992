import React from 'react';
import { Link } from 'react-router-dom';

export const AdminSubNav = ({ active }) => {
  return (
    <ul className="nav nav-pills">
      <li className="nav-item">
        <Link
          className={`nav-link ${active === 'users' ? 'active' : ''}`}
          to="/admin/users"
        >
          Users
        </Link>
      </li>
      {/*
        <li className="nav-item">
          <Link
            className={`nav-link ${active === 'user-groups' ? 'active' : ''}`}
            to="/admin/user-groups"
          >
            User Groups
          </Link>
        </li>
      */}
      <li className="nav-item">
        <Link
          className={`nav-link ${active === 'builders' ? 'active' : ''}`}
          to="/admin/builders"
        >
          Builders
        </Link>
      </li>
      {/*
        <li className="nav-item">
          <Link
            className={`nav-link ${active === 'projects' ? 'active' : ''}`}
            to="/admin/projects"
          >
            Projects
          </Link>
        </li>
      */}
    </ul>
  );
};

export default AdminSubNav;
