import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Modal, Button, ModalBody } from 'react-bootstrap';
import FormField from '../../../forms/FormField';
import { Row, Col } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const FormEditBuilder = props => {
  const { handleSubmit, submitting, closeModal } = props;
  return (
    <Modal.Body>

      <form onSubmit={handleSubmit}>

        <Field
          name="name"
          type="text"
          component={FormField.Input}
          label="Builder Name"
          validate={[required]}
        />
        <Field
          name="phone"
          type="text"
          component={FormField.Input}
          label="Builder Phone"
        />
        <Field
          name="address"
          type="text"
          component={FormField.Input}
          label="Address"
        />

        <Row>
          <Col md={8}>
            <Field
              name="city"
              type="text"
              component={FormField.Input}
              label="City"
            />
          </Col>
          <Col md={4}>
            <Field
              name="zip"
              type="text"
              component={FormField.Input}
              label="ZIP"
            />
          </Col>
        </Row>

        <Field
          name="state"
          type="text"
          component={FormField.Input}
          label="State"
        />

        <Modal.Footer>
          <Button variant="success" type="submit" disabled={submitting}>
            Update
          </Button>
          <Button
            variant="outline-secondary"
            onClick={closeModal}
            disabled={submitting}
          >
            Cancel
          </Button>
        </Modal.Footer>

      </form>

    </Modal.Body>
  );
};

export default reduxForm({
  form: 'edit-builder'
})(FormEditBuilder);
