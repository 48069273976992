import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

const required = value => value ? undefined : 'Required';

const FormRescheduleMaterialPickup = props => {
  const { handleSubmit, submitting, change, options, initialValues } = props;

  const {
    mustPickupDate
  } = initialValues || {};

  const formattedMustByDate = moment(mustPickupDate).format('M/D/Y');

  return (
    <div className="box box-form">
      <h5
        style={{
          margin: '0 0 1rem',
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }}
      >
        Reschedule Material Pickup
      </h5>
      <Alert variant="danger">
        This location must be picked up by
        {' '}
        <strong>{formattedMustByDate}</strong>
        .
      </Alert>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} lg={3}>

            <Field
              name="technicianId"
              type="text"
              component={FormField.SelectReact}
              label="Material Pickup Technician"
              validate={[required]}
              options={options || []}
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="scheduledDate"
              type="text"
              component={FormField.Datepicker}
              label="Pickup Date"
              validate={[required]}
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="mustPickupDate"
              type="text"
              component={FormField.Datepicker}
              label="Must Pickup By"
              validate={[required]}
            />

          </Col>
          <Col md={6} lg={3}>

            <div className="form-group-spacing">
              <Field
                name="allowEarlyPickup"
                type="checkbox"
                component={FormField.InputToggle}
                label="Allow early pickup"
              />
            </div>

          </Col>
        </Row>

        <Row>
          <Col md={12} lg={6}>

            <Field
              name="address"
              type="text"
              component={FormField.Input}
              label="Address"
              validate={[required]}
              autoCapitalize="none"
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="builder"
              type="text"
              component={FormField.Input}
              label="Builder"
              validate={[required]}
              autoCapitalize="none"
              disabled
            />

          </Col>
          <Col md={6} lg={3}>

            <Field
              name="fieldSupervisor"
              type="text"
              component={FormField.Input}
              label="Field Supervisor"
              validate={[required]}
              autoCapitalize="none"
              disabled
            />

          </Col>
        </Row>

        <Field
          name="comments"
          type="text"
          component={FormField.Textarea}
          label="Comments"
          autoCapitalize="none"
          rows={5}
        />

        <br />

        <div className="form-actions">
          <Button variant="success" type="submit" disabled={submitting}>
            Reschedule Material Pickup
          </Button>
          {' '}
          <Link to="/material-pickups" className="btn btn-outline-secondary">
            Cancel
          </Link>
        </div>

      </form>
    </div>
  );
};

export default reduxForm({
  form: 'reschedule-material-pickup'
})(FormRescheduleMaterialPickup);
