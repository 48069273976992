import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Table from '../../../ui/Table';
import Icon from '../../../ui/Icon';

import AdminSubNav from '../AdminSubNav';

const ROLE_OPTIONS = [
  {
    label: 'Administrator',
    value: 'administrator'
  },
  {
    label: 'Field Supervisor',
    value: 'fieldSupervisor'
  },
  {
    label: 'Material Pickup Technician',
    value: 'materialPickupTechnician'
  },
  {
    label: 'Quality Assurance Technician',
    value: 'qualityAssuranceTechnician'
  }
];

const getRoleLabel = value => {
  const role = ROLE_OPTIONS.find(({ value: r }) => r === value) || {};
  return role.label;
};

const columns = [
  {
    Header: 'Id',
    accessor: '_id',
    width: 150,
    Cell: ({ value }) => <Link to={`/admin/users/${value}`}>{value}</Link>
  },
  {
    Header: 'First Name',
    accessor: 'firstName'
  },
  {
    Header: 'Last Name',
    accessor: 'lastName'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Phone',
    accessor: 'phone'
  },
  {
    Header: 'Role',
    accessor: 'roleId',
    Cell: ({ value }) => {
      const label = getRoleLabel(value);
      return label;
    }
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
  }
];

class UsersSearch extends Component {
  componentDidMount() {
    const {
      getUsers
    } = this.props;
    getUsers();
  }
  render() {
    const { users } = this.props;
    return (
      <Layout route="users-search">

        <div className="layout-header">
          <div>
            <h1>Users</h1>
            <AdminSubNav active="users" />
          </div>
          <div>
            <Link to="/admin/users/new" className="btn btn-success btn-icon">
              New User <Icon name="plus" />
            </Link>
          </div>
        </div>

        <div className="box box-smart-table">
          <Table
            showColumnsSelector
            showSearch
            showExport
            data={users || []}
            columns={columns}
            noDataText="No Users Found"
          />
        </div>

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: users,
    error
  } = state.getUsers.toJS();
  return {
    users,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getUsers: fetchDux.getUsers.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(UsersSearch);
