import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';

const FormCancelMaterialPickup = props => {
  const {
    handleSubmit
  } = props;

  return (
    <form onSubmit={handleSubmit}>

      <Field
        name="reason"
        type="text"
        component={FormField.Textarea}
        label="Reason"
        rows={5}
      />

    </form>
  );
};

export default reduxForm({
  form: 'cancel-inspection'
})(FormCancelMaterialPickup);
