import duxFactory from './fetch-dux-factory';
import * as api from '../api/api';

export default {
  login: duxFactory({
    apiFn: api.postLogin,
    actionPrefix: 'POST_LOGIN'
  }),
  validateLogin: duxFactory({
    apiFn: api.validateLogin,
    actionPrefix: 'POST_VALIDATE_LOGIN',
    requestListeners: ['POST_LOGIN'],
    successListeners: ['POST_LOGIN']
  }),
  getLookups: duxFactory({
    apiFn: api.getLookups,
    actionPrefix: 'GET_LOOKUPS'
  }),
  // Projects
  postProject: duxFactory({
    apiFn: api.postProject,
    actionPrefix: 'POST_PROJECT'
  }),
  putProject: duxFactory({
    apiFn: api.putProject,
    actionPrefix: 'PUT_PROJECT'
  }),
  getProjects: duxFactory({
    apiFn: api.getProjects,
    actionPrefix: 'GET_PROJECTS'
  }),
  getProjectById: duxFactory({
    apiFn: api.getProjectById,
    actionPrefix: 'GET_PROJECT_BY_ID'
  }),
  deleteProjectById: duxFactory({
    apiFn: api.deleteProjectById,
    actionPrefix: 'DELETE_PROJECT_BY_ID',
    successEmit: ['CLOSE_MODAL']
  }),
  getProjectLogsById: duxFactory({
    apiFn: api.getProjectLogsById,
    actionPrefix: 'GET_PROJECT_LOGS_BY_ID'
  }),
  postImportProjects: duxFactory({
    apiFn: api.postImportProjects,
    actionPrefix: 'POST_IMPORT_PROJECTS'
  }),
  getProjectImport: duxFactory({
    apiFn: api.getProjectImport,
    actionPrefix: 'GET_PROJECT_IMPORT'
  }),
  // Addresses
  downloadAddressesCSV: duxFactory({
    apiFn: api.downloadAddressesCSV,
    actionPrefix: 'DOWNLOAD_ADDRESSES_FILE'
  }),
  // Builders
  postBuilder: duxFactory({
    apiFn: api.postBuilder,
    actionPrefix: 'POST_BUILDER'
  }),
  putBuilder: duxFactory({
    apiFn: api.putBuilder,
    actionPrefix: 'PUT_BUILDER',
    successEmit: ['CLOSE_MODAL']
  }),
  deleteBuilder: duxFactory({
    apiFn: api.deleteBuilder,
    actionPrefix: 'DELETE_BUILDER',
    successEmit: ['CLOSE_MODAL']
  }),
  getBuilders: duxFactory({
    apiFn: api.getBuilders,
    actionPrefix: 'GET_BUILDERS'
  }),
  getBuilderById: duxFactory({
    apiFn: api.getBuilderById,
    actionPrefix: 'GET_BUILDER_BY_ID'
  }),

  // Material Pickup
  postMaterialPickup: duxFactory({
    apiFn: api.postMaterialPickup,
    actionPrefix: 'POST_MATERIAL_PICKUP'
  }),
  getMaterialPickupById: duxFactory({
    apiFn: api.getMaterialPickupById,
    actionPrefix: 'GET_MATERIAL_PICKUP_BY_ID'
  }),
  getMaterialPickups: duxFactory({
    apiFn: api.getMaterialPickups,
    actionPrefix: 'GET_MATERIAL_PICKUPS'
  }),
  getMapMaterialPickups: duxFactory({
    apiFn: api.getMaterialPickups,
    actionPrefix: 'GET_MAP_MATERIAL_PICKUPS'
  }),
  putMaterialPickup: duxFactory({
    apiFn: api.putMaterialPickup,
    actionPrefix: 'PUT_MATERIAL_PICKUPS_BY_ID'
  }),
  deleteMaterialPickupById: duxFactory({
    apiFn: api.deleteMaterialPickupById,
    actionPrefix: 'DELETE_MATERIAL_PICKUPS_BY_ID',
    successEmit: ['CLOSE_MODAL']
  }),
  postCompleteMaterialPickup: duxFactory({
    apiFn: api.postCompleteMaterialPickup,
    actionPrefix: 'POST_COMPLETE_MATERIAL_PICKUP'
  }),
  postDenyMaterialPickup: duxFactory({
    apiFn: api.postDenyMaterialPickup,
    actionPrefix: 'POST_DENY_MATERIAL_PICKUP'
  }),
  patchMaterialPickupById: duxFactory({
    apiFn: api.patchMaterialPickupById,
    actionPrefix: 'PUT_RESCHEDULE_MATERIAL_PICKUP'
  }),

  // Quality Assurance
  getInspections: duxFactory({
    apiFn: api.getInspections,
    actionPrefix: 'GET_INSPECTIONS'
  }),
  postAssignInspection: duxFactory({
    apiFn: api.postAssignInspection,
    actionPrefix: 'POST_ASSIGN_INSPECTION'
  }),
  postScheduleInspection: duxFactory({
    apiFn: api.postScheduleInspection,
    actionPrefix: 'POST_SCHEDULE_INSPECTION'
  }),
  getInspectionEvents: duxFactory({
    apiFn: api.getInspectionEvents,
    actionPrefix: 'GET_INSPECTION_EVENTS'
  }),
  getInspectionById: duxFactory({
    apiFn: api.getInspectionById,
    actionPrefix: 'GET_INSPECTION_BY_ID'
  }),
  deleteFileById: duxFactory({
    apiFn: api.deleteFileById,
    actionPrefix: 'DELETE_FILE_BY_ID'
  }),
  postResolveInspectionItem: duxFactory({
    apiFn: api.postResolveInspectionItem,
    actionPrefix: 'POST_RESOLVE_INSPECTION_ITEM',
    successEmit: ['CLOSE_MODAL']
  }),
  postInspection: duxFactory({
    apiFn: api.postInspection,
    actionPrefix: 'POST_INSPECTION'
  }),
  putInspection: duxFactory({
    apiFn: api.putInspection,
    actionPrefix: 'PUT_INSPECTION'
  }),
  postRescheduleInspection: duxFactory({
    apiFn: api.postRescheduleInspection,
    actionPrefix: 'POST_RESCHEDULE_INSPECTION'
  }),
  deleteInspectionById: duxFactory({
    apiFn: api.deleteInspectionById,
    actionPrefix: 'DELETE_INSPECTION',
    successEmit: ['CLOSE_MODAL']
  }),
  pauseInspectionById: duxFactory({
    apiFn: api.pauseInspectionById,
    actionPrefix: 'PAUSE_INSPECTION',
    successEmit: ['CLOSE_MODAL']
  }),
  resumeInspectionById: duxFactory({
    apiFn: api.resumeInspectionById,
    actionPrefix: 'RESUME_INSPECTION',
    successEmit: ['CLOSE_MODAL']
  }),
  getSidNavInspections: duxFactory({
    apiFn: api.getInspections,
    actionPrefix: 'GET_SIDE_NAV_INSPECTIONS'
  }),

  // Users
  getUsers: duxFactory({
    apiFn: api.getUsers,
    actionPrefix: 'GET_USERS'
  }),
  postUser: duxFactory({
    apiFn: api.postUser,
    actionPrefix: 'POST_USER'
  }),
  putUser: duxFactory({
    apiFn: api.putUser,
    actionPrefix: 'PUT_USER',
    successEmit: ['CLOSE_MODAL']
  }),
  patchPassword: duxFactory({
    apiFn: api.patchPassword,
    actionPrefix: 'PATCH_USER',
    successEmit: ['CLOSE_MODAL']
  }),
  getUserById: duxFactory({
    apiFn: api.getUserById,
    actionPrefix: 'GET_USER_BY_ID'
  }),
  deleteUserById: duxFactory({
    apiFn: api.deleteUserById,
    actionPrefix: 'DELETE_USER_BY_ID'
  }),

  // Dashboard
  getDashboardStats: duxFactory({
    apiFn: api.getDashboardStats,
    actionPrefix: 'GET_DASHBOARD_STATS'
  })
};
