import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Layout from '../../ui/Layout';
import MaterialPickupsCalendar from '../../ui/MaterialPickupsCalendar';
import Notification from '../../ui/Notification';
import Icon from '../../ui/Icon';
import FormEditMaterialPickup from './FormEditMaterialPickup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';

class MaterialPickupsEdit extends Component {
  componentWillMount() {
    const {
      getLookups,
      getMaterialPickupById,
      match: { params: { id } }
    } = this.props;
    getMaterialPickupById(id);
    getLookups();
  }
  componentWillUnmount() {
    const {
      clearGetLookups,
      clearGetMaterialPickupById,
      clearPutMaterialPickup
    } = this.props;

    clearGetLookups();
    clearGetMaterialPickupById();
    clearPutMaterialPickup();
  }

  render() {
    const {
      lookups,
      putMaterialPickup,
      inProgress,
      history,
      result,
      errorGetMaterialPickUps,
      clearGetMaterialPickups,
      errorLookup,
      clearGetLookups,
      errorGetMaterialPickupById,
      clearGetMaterialPickupById,
      errorPutMaterialPickupById,
      clearPutMaterialPickup,
      calendarResults,
      getMaterialPickups
    } = this.props;
    const {
      _id
    } = result || {};
    const handleSubmit = values => {
      const {
        comments,
        allowEarlyPickup,
        mustPickupDate,
        scheduledDate,
        roughDate,
        roughCompletedBy,
        technicianId
      } = values || {};

      const payload = {
        comments,
        allowEarlyPickup,
        mustPickupDate,
        scheduledDate,
        roughDate,
        roughCompletedBy,
        technicianId
      };

      putMaterialPickup(
        { id: _id, payload },
        'edit-material-pickup',
        null,
        data => {
          history.push(`/material-pickups/${_id}`);
        }
      );
    };

    const{
      inspections
    } = calendarResults || {}

    const handleLoadEvents = values => {
      const { week, year } = values || {};
      const d = moment().year(year).week(week);
      const startDate = d.clone().startOf('week').startOf('day').toDate();
      const endDate = d.clone().endOf('week').endOf('day').toDate();
      getMaterialPickups(`startDate=${startDate}&endDate=${endDate}&pageSize=1000`);
    };

    const {
      project,
      technicianId,
      scheduledDate,
      mustPickupDate,
      allowEarlyPickup,
      roughDate,
      roughCompletedBy,
      comments
    } = result || {};

    const {
      address,
      city,
      state,
      zip,
      builderName,
      fieldSupervisorName,
      name: projectName
    } = project || {};

    const projectAddress = `${address} ${city} ${state} ${zip}`;

    const initialValues = {
      projectAddress,
      technicianId,
      scheduledDate,
      mustPickupDate,
      allowEarlyPickup,
      roughDate,
      roughCompletedBy,
      projectName,
      builderName,
      fieldSupervisorName,
      comments
    };
    return (
      <Layout route="projects-new">

        <div className="layout-header">
          <div>
            <h1>Edit Material Pickup</h1>
            <Link to={`/material-pickups/${_id}`}>
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        {!inProgress &&
          <FormEditMaterialPickup
            lookups={lookups}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          />
        }

        <MaterialPickupsCalendar 
          hideNav
          handleLoadEvents={handleLoadEvents}
          result={inspections}
        />

        {errorLookup &&
          <Notification
            key="lookups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetLookups}
          >
            Error loading material pickup technicians.
          </Notification>}
        {errorGetMaterialPickUps &&
          <Notification
            key="get-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetMaterialPickups}
          >
            Error loading calender events.
          </Notification>}
        {errorGetMaterialPickupById &&
          <Notification
            key="get-pickup-by-id-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetMaterialPickupById}
          >
            Error loading material pickup details.
          </Notification>}
        {errorPutMaterialPickupById &&
          <Notification
            key="put-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPutMaterialPickup}
          >
            Error updating material pickup.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: lookups,
    error: errorLookup
  } = state.getLookups.toJS();
  const {
    result,
    inProgress,
    error: errorGetMaterialPickupById
  } = state.getMaterialPickupById.toJS();
  const {
    result: calendarResults,
    error: errorGetMaterialPickUps
  } = state.getMaterialPickups.toJS();

  const {
    error: errorPutMaterialPickupById
  } = state.putMaterialPickup.toJS();

  return {
    result,
    inProgress,
    lookups,
    calendarResults,
    errorLookup,
    errorGetMaterialPickUps,
    errorGetMaterialPickupById,
    errorPutMaterialPickupById
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getLookups: fetchDux.getLookups.createAction,
      clearGetLookups: fetchDux.getLookups.clearAction,
      putMaterialPickup: fetchDux.putMaterialPickup.createAction,
      clearPutMaterialPickup: fetchDux.putMaterialPickup.clearAction,
      getMaterialPickupById: fetchDux.getMaterialPickupById.createAction,
      clearGetMaterialPickupById: fetchDux.getMaterialPickupById.clearAction,
      getMaterialPickups: fetchDux.getMaterialPickups.createAction,
      clearGetMaterialPickups:fetchDux.getMaterialPickups.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MaterialPickupsEdit);
