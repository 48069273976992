import React from 'react';
import Icon from '../../ui/Icon';

const InspectionStatus = ({ status, hideIcon }) => {
  return (
    <div>
      {status === 'pending' &&
        <div className="status orange">
          {!hideIcon && <Icon name="clock" />} Pending
        </div>}
      {status === 'draft' &&
        <div className="status gray">
          {!hideIcon && <Icon name="clipboard" />} Draft
        </div>}
      {status === 'passed' &&
        <div className="status green">
          {!hideIcon && <Icon name="check" />} Passed
        </div>}
      {status === 'failed' &&
        <div className="status red">
          {!hideIcon && <Icon name="times" />} Failed
        </div>}
      {status === 'criticalFailed' &&
        <div className="status red">
          {!hideIcon && <Icon name="exclamation-triangle" />} Critical Fail
        </div>}
      {status === 'denied' &&
        <div className="status red">
          {!hideIcon && <Icon name="exclamation-circle" />} Unable To Inspect
        </div>}
      {status === 'late' &&
        <div className="status red">
          {!hideIcon && <Icon name="exclamation-circle" />} Late
        </div>}
      {status === 'reschedule' &&
        <div className="status red">
          {!hideIcon && <Icon name="clock" />} Reschedule
        </div>}
    </div>
  );
};

export default InspectionStatus;
