import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import MaterialPickupsCalendar from '../../ui/MaterialPickupsCalendar';

import Icon from '../../ui/Icon';
import FormNewMaterialPickup from './FormNewMaterialPickup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import Notification from '../../ui/Notification';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { actionCreators } from '../../../state/modals-dux';
import ModalMissingPhotos from './ModalMissingPhotos';
import ModalPickUpCompletion from './ModalPickUpCompletion';

class MaterialPickupsNew extends Component {
  componentDidMount() {
    const {
      getLookups
    } = this.props;
    getLookups();
  }
  componentWillUnmount() {
    const {
      clearGetLookups,
      clearPostMaterialPickup
    } = this.props;

    clearGetLookups();
    clearPostMaterialPickup();
  }
  render() {
    const {
      lookups,
      clearGetLookups,
      postMaterialPickup,
      clearPostMaterialPickup,
      history,
      errorLookup,
      errorPostMaterialPickup,
      getMaterialPickups,
      calendarResults,
      files,
      showModal,
      closeModal,
      openModal,
      postMaterialPickUpLoading
    } = this.props;

    const {
      inspections
    } = calendarResults || {};

    const handleSubmit = values => {
      if ((files || []).length < 2) {
        showModal('PhotosModal');
        return;
      }
      showModal('ModalPickUpCompletion');
    }

    const handleSubmitWithAck = values => {
      postMaterialPickup(values, 'new-material-pickup', null, data => {
        const { insertedId } = data || {};
        history.push(`/material-pickups/${insertedId}`);
        closeModal('ModalPickUpCompletion')
      });
    }



    const handleLoadEvents = values => {
      const { week, year } = values || {};
      const d = moment().year(year).week(week);
      const startDate = d.clone().startOf('week').startOf('day').toDate();
      const endDate = d.clone().endOf('week').endOf('day').toDate();
      getMaterialPickups(`startDate=${startDate}&endDate=${endDate}`);
    };

    return (
      <Layout route="projects-new">

        <div className="layout-header">
          <div>
            <h1>Request Material Pickup</h1>
            <Link to="/material-pickups">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
        </div>

        <FormNewMaterialPickup lookups={lookups} onSubmit={handleSubmit} />

        <MaterialPickupsCalendar
          hideNav
          handleLoadEvents={value => handleLoadEvents(value)}
          result={inspections}
        />

        {errorLookup &&
          <Notification
            key="lookups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetLookups}
          >
            Error loading material pickup technicians.
          </Notification>}

        <ModalMissingPhotos closeModal={closeModal} openModal={openModal} />

        <ModalPickUpCompletion
          closeModal={closeModal}
          openModal={openModal}
          handleSubmitWithAck={handleSubmitWithAck}
          postMaterialPickUpLoading={postMaterialPickUpLoading} />

        {errorPostMaterialPickup &&
          <Notification
            key="post-material-pickup-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPostMaterialPickup}
          >
            Error creating new material pickup.
          </Notification>}

      </Layout>
    );
  }
}

const selector = formValueSelector('new-material-pickup');
const mapState = state => {
  const {
    result: lookups,
    error: errorLookup
  } = state.getLookups.toJS();

  const {
    result: calendarResults
  } = state.getMaterialPickups.toJS();

  const {
    error: errorPostMaterialPickup,
    inProgress: postMaterialPickUpLoading
  } = state.postMaterialPickup.toJS();

  const files = selector(state, 'files');

  return {
    lookups,
    errorLookup,
    errorPostMaterialPickup,
    calendarResults,
    files,
    openModal: state.modals,
    postMaterialPickUpLoading
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getLookups: fetchDux.getLookups.createAction,
      clearGetLookups: fetchDux.getLookups.clearAction,
      postMaterialPickup: fetchDux.postMaterialPickup.createAction,
      clearPostMaterialPickup: fetchDux.postMaterialPickup.clearAction,
      getMaterialPickups: fetchDux.getMaterialPickups.createAction,
      clearGetMaterialPickups: fetchDux.getMaterialPickups.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MaterialPickupsNew);
