import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Notification from '../../../ui/Notification';

import ModalEditUser from './ModalEditUser';
import ModalEditUserPassword from './ModalEditUserPassword';
import ModalDeleteUser from './ModalDeleteUser';
import fetchDux from '../../../../state/fetch-dux';

const ROLE_OPTIONS = [
  {
    label: 'Administrator',
    value: 'administrator'
  },
  {
    label: 'Field Supervisor',
    value: 'fieldSupervisor'
  },
  {
    label: 'Material Pickup Technician',
    value: 'materialPickupTechnician'
  },
  {
    label: 'Quality Assurance Technician',
    value: 'qualityAssuranceTechnician'
  }
];

const getRoleLabel = value => {
  const role = ROLE_OPTIONS.find(({ value: r }) => r === value) || {};
  return role.label;
};

class UsersDetail extends Component {
  componentDidMount() {
    const {
      getUserById,
      match: { params: { id } }
    } = this.props;

    getUserById(id);
  }
  componentWillUnmount() {
    const {
      clearPutUser,
      clearPatchPassword,
      clearDeleteUser
    } = this.props

    clearPutUser();
    clearPatchPassword();
    clearDeleteUser();
  }
  render() {
    const {
      showModal,
      closeModal,
      user,
      getUserById,
      putUser,
      putUserError,
      putUserInProgress,
      clearPutUser,
      patchPassword,
      clearPatchPassword,
      patchPasswordError,
      patchPasswordInProgress,
      deleteUser,
      clearDeleteUser,
      deleteUserError,
      deleteUserInProgress,
      history
    } = this.props;
    const {
      firstName,
      lastName,
      username,
      email,
      phone,
      roleId,
      _id
    } = user || {};

    const handleUserSubmit = values => {
      const {
        firstName,
        lastName,
        username,
        email,
        phone,
        roleId
      } = values || {};
      const payload = {
        firstName,
        lastName,
        username,
        email,
        phone,
        roleId,
      };

      putUser(
        { id: _id, payload },
        'edit-user',
        null,
        data => {
          getUserById(_id);
        }
      );
    };

    const handlePasswordSubmit = values => {
      const {
        password
      } = values || {};
      const payload = {
        password
      };

      patchPassword(
        { id: _id, payload },
        'edit-password',
        null,
        data => {
          getUserById(_id);
        }
      );
    };

    const handleDeleteUserSubmit = () => {
      deleteUser(
        _id,
        null,
        null,
        data => {
          history.push('/admin/users')
        }
      );
    };

    return (
      <Layout route="users-detail">

        <div className="layout-header">
          <div>
            <h1>User <span>{firstName} {lastName}</span></h1>
            <Link to="/admin/users">
              <Icon name="angle-left" /> Back
            </Link>
          </div>
          <div>
            <Button
              className="btn btn-primary btn-icon"
              onClick={() => showModal('EditUserModal')}
            >
              Edit User <Icon name="wrench" />
            </Button>
            <Button
              className="btn btn-primary btn-icon"
              onClick={() => showModal('EditUserPassword')}
            >
              Edit User Password <Icon name="lock" />
            </Button>
            <Button
              className="btn btn-danger btn-icon"
              onClick={() => showModal('DeleteUserModal')}
            >
              Delete User <Icon name="trash" />
            </Button>
          </div>
        </div>

        <Row>
          <Col md={6} xl={5}>
            <div className="box">
              <div className="box-header">
                <h3>Details</h3>
              </div>
              <div className="box-body">
                <table className="table data-table">
                  <tbody>
                    <tr>
                      <th>First Name</th>
                      <td>{firstName}</td>
                    </tr>
                    <tr>
                      <th>Last Name</th>
                      <td>{lastName}</td>
                    </tr>
                    <tr>
                      <th>Username</th>
                      <td>{username}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{email}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>{phone}</td>
                    </tr>
                    <tr>
                      <th>Role</th>
                      <td>{getRoleLabel(roleId)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>

        <ModalDeleteUser 
          name="DeleteUserModal"
          handleSubmit={handleDeleteUserSubmit}
          submitting={deleteUserInProgress}
          firstName={firstName}
          lastName={lastName}
        />
        <ModalEditUser 
          name="EditUserModal"
          user={user}
          handleSubmit={handleUserSubmit}
          submitting={putUserInProgress}
        />
        <ModalEditUserPassword 
          name="EditUserPassword"
          handleSubmit={handlePasswordSubmit}
          submitting={patchPasswordInProgress}
        />

        {putUserError &&
          <Notification
            key="put-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPutUser}
          >
            Error updating user profile.
          </Notification>
        }

        {patchPasswordError &&
          <Notification
            key="put-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPatchPassword}
          >
            Error updating user password.
          </Notification>
        }

        {deleteUserError &&
          <Notification
            key="put-pickups-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearDeleteUser}
          >
            Error deleting user.
          </Notification>
        }

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: user
  } = state.getUserById.toJS();

  const {
    error:putUserError,
    inProgress:putUserInProgress
  } = state.putUser.toJS();
  const {
    error:patchPasswordError,
    inProgress:patchPasswordInProgress
  } = state.patchPassword.toJS();
  const {
    error:deleteUserError,
    inProgress:deleteUserInProgress
  } = state.deleteUserById.toJS();

  return {
    user,
    putUserError,
    putUserInProgress,
    patchPasswordError,
    patchPasswordInProgress,
    deleteUserError,
    deleteUserInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getUserById: fetchDux.getUserById.createAction,
      putUser: fetchDux.putUser.createAction,
      clearPutUser: fetchDux.putUser.clearAction,
      patchPassword: fetchDux.patchPassword.createAction,
      clearPatchPassword: fetchDux.patchPassword.clearAction,
      deleteUser: fetchDux.deleteUserById.createAction,
      clearDeleteUser: fetchDux.deleteUserById.clearAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(UsersDetail);
