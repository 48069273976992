import React from 'react';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import authorized from '../../../authorized';
import { Row, Col, Alert, Container } from 'react-bootstrap';
import { withLastLocation } from 'react-router-last-location';

import LoginForm from './LoginForm';

const getNextPath = ({ lastPath, lastSearch }) => {
  if (!lastPath) return '/';
  if (!lastSearch) return lastPath;
  return `${lastPath}${lastSearch}`;
};

export const Login = ({ error, postLogin, lastLocation, history }) => {
  const {
    pathname: lastPath,
    search: lastSearch
  } = lastLocation || {};

  const handlePostLogin = values =>
    postLogin(values, 'login', null, () => {
      history.push(getNextPath({ lastPath, lastSearch }));
    });

  return (
    <div className="route-login">
      <Container>
        <Row className="justify-content-sm-center align-items-center">
          <Col md={6} lg={4}>
            <div className="brand" />
            {error && <Alert variant="danger">{error.message}</Alert>}
            <LoginForm onSubmit={values => handlePostLogin(values)} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();
  return {
    user,
    error
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postLogin: fetchDux.login.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(withLastLocation(Login));
