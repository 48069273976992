import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Table from '../../../ui/Table';
import Icon from '../../../ui/Icon';

import AdminSubNav from '../AdminSubNav';

const columns = [
  {
    Header: 'Id',
    accessor: 'id',
    width: 150,
    Cell: ({ value }) => <Link to={`/user-groups/${value}`}>{value}</Link>
  },
  {
    Header: 'User Group Name',
    accessor: 'builderName'
  },
  {
    Header: 'No. Of Users',
    accessor: 'builderName'
  },
  {
    Header: 'Privileges',
    accessor: 'privileges'
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
  }
];

export const UserGroupsSearch = ({ groups }) => {
  return (
    <Layout route="user-groups-search">

      <div className="layout-header">
        <div>
          <h1>User Groups</h1>
          <AdminSubNav active="user-groups" />
        </div>
        <div>
          <Link
            to="/admin/user-groups/new"
            className="btn btn-success btn-icon"
          >
            New User Group <Icon name="plus" />
          </Link>
        </div>
      </div>

      <div className="box box-smart-table">
        <Table
          showColumnsSelector
          showSearch
          showExport
          data={groups || []}
          columns={columns}
          noDataText="No User Groups Found"
        />
      </div>

    </Layout>
  );
};

const mapState = state => {
  const {
    result: groups,
    error
  } = state.getProjects.toJS();
  return {
    groups,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProjects: fetchDux.getProjects.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(UserGroupsSearch);
