import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { reduxForm, Field, Form } from 'redux-form';
import FormField from '../forms/FormField';

const ScheduleFormHtml = (
  {
    handleScheduleInspection,
    postScheduleInspectionInProgress,
  }
) => {
  return (
    <Form>
      <Field
        grouped
        name="scheduledDate"
        type="text"
        component={FormField.Datepicker}
        disabled={postScheduleInspectionInProgress}
        onChange={value => {
          handleScheduleInspection({value});
        }}
        extraProps={{
          popperPlacement:'bottom-end'
        }}
      />
    </Form>
  );
};

const ScheduleForm = reduxForm({
  form: 'inspection-schedule-form'
})(ScheduleFormHtml);

const InspectionScheduleCard = (
  {
    _id,
    scheduledDate,
    type,
    project,
    getInspections,
    getGridCalenderInspections,
    postScheduleInspection,
    postScheduleInspectionInProgress
  }
) => {
  const {
    name: projectName,
    address,
    zip,
    city,
    state,
    builder,
  } = project || {};


  const { name: builderName } = builder || {};

  const initialValues = { scheduledDate: scheduledDate || null };

  return (
    <div className={classNames('inspection-assign-card')}>

      <address>
        <div>
          <div style={{ fontWeight: 'bold' }}>{projectName}</div>
          <div>{address}</div>
          <div>{zip} {city}, {state}</div>
          <div className="builder">{builderName}</div>
        </div>
        <div>
          <span
            className={classNames('type', {
              blue: type === 'roughInspection',
              black: type === 'finalInspection'
            })}
          >
            {type === 'roughInspection' && <span>Rough Inspection</span>}
            {type === 'finalInspection' && <span>Final Inspection</span>}
          </span>

          <div className="qat-date">
            <ScheduleForm
              enableReinitialize
              postScheduleInspectionInProgress={postScheduleInspectionInProgress}
              initialValues={initialValues}
              form={`inspection-schedule-form-${_id}`}
              onSubmit={() => {}}
              handleScheduleInspection={props => {
                const { value: scheduledDate } = props || {};
                const payload = { scheduledDate };
                postScheduleInspection({ id: _id, payload }, null, null, () => {
                  getInspections();
                  getGridCalenderInspections();
                });
              }}
            />
          </div>
        </div>
      </address>

    </div>
  );
};

export default InspectionScheduleCard;
