import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { reduxForm, Field, Form } from 'redux-form';
import FormField from '../forms/FormField';

const AssigneeFormHtml = (
  {
    options,
    handleAssignInspection,
    postAssignInspectionInProgress,
    handleAssignGridInspections,
    reversePopupPlacement
  }
) => {
  return (
    <Form>
      <Field
        grouped
        name="technicianId"
        component={FormField.SelectReact}
        placeholder="Select Technician"
        options={options}
        disabled={postAssignInspectionInProgress}
        menuPlacement={reversePopupPlacement ? 'top' : null}
        afterChange={value => {
          handleAssignInspection(value);
        }}
      />
    </Form>
  );
};

const AssigneeForm = reduxForm({
  form: 'inspection-assignee-form'
})(AssigneeFormHtml);

const InspectionAssignCard = (
  {
    _id,
    scheduledDate,
    status = '',
    type,
    project,
    technicianId,
    assigneeOptions,
    getInspections,
    getGridCalenderInspections,
    postAssignInspection,
    postAssignInspectionInProgress,
    reversePopupPlacement
  }
) => {
  const {
    name: projectName,
    address,
    zip,
    city,
    state,
    builder,
    fieldSupervisorId,
    fieldSupervisorName
  } = project || {};

  const groupedOptions = [
    {
      label: 'QA Technicians',
      options: assigneeOptions || []
    },
    {
      label: 'Field Supervisor',
      options: [
        {
          value: fieldSupervisorId,
          label: fieldSupervisorName
        }
      ]
    }
  ];

  const { name: builderName } = builder || {};

  const initialValues = { technicianId: technicianId || null };

  return (
    <div className={classNames('inspection-assign-card')}>

      <address>
        <div>
          <div style={{ fontWeight: 'bold' }}>{projectName}</div>
          <div>{address}</div>
          <div>{zip} {city}, {state}</div>
          <div className="builder">{builderName}</div>
        </div>
        <div>
          <span className="date">
            <div>{moment(scheduledDate).format('MM/DD/YYYY')}</div>
          </span>

          <span
            className={classNames('type', {
              blue: type === 'roughInspection',
              black: type === 'finalInspection'
            })}
          >
            {type === 'roughInspection' && <span>Rough Inspection</span>}
            {type === 'finalInspection' && <span>Final Inspection</span>}
          </span>

          <div className="qat-select">
            <AssigneeForm
              enableReinitialize
              postAssignInspectionInProgress={postAssignInspectionInProgress}
              initialValues={initialValues}
              form={`inspection-assignee-form-${_id}`}
              onSubmit={() => {}}
              options={groupedOptions}
              reversePopupPlacement={reversePopupPlacement}
              handleAssignInspection={props => {
                const { value: technicianId } = props || {};
                const payload = { technicianId };
                postAssignInspection({ id: _id, payload }, null, null, () => {
                  getInspections();
                  getGridCalenderInspections();
                });
              }}
            />
          </div>
        </div>
      </address>

    </div>
  );
};

export default InspectionAssignCard;
