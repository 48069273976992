import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import { formValueSelector } from 'redux-form';

import FormCancelMaterialPickup from './FormCancelMaterialPickup';

const ModalCancelMaterialPickup = ({ 
  openModal, 
  closeModal, 
  name, 
  handleCancel, 
  deleteReason,
  address,
  city,
  state,
  zip
}) => {

  return(
  <Modal show={openModal === name} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Cancel Material Pickup</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Are you sure you wish to cancel material pickup at the address below?</p>
      <label className="form-label">
        Address
      </label>
      <p>
        {address || ''},<br />
        {city || ''} {zip || ''}, {state || ''}
      </p>
      <FormCancelMaterialPickup />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={() => handleCancel(deleteReason)}>
        Cancel Material Pickup
      </Button>
      <Button variant="outline-secondary" onClick={closeModal}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>
)};

const selector = formValueSelector('cancel-material-pickup');

const mapState = state => {
  const deleteReason = selector(state, 'reason');
  return{ 
    openModal: state.modals,
    deleteReason,
  }
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalCancelMaterialPickup);
